//Deklaration der Datenbank:
// https://gdz.bplaced.net/gdz/gdz_db.js
// changes
// 'var' to 'export const' by BP and LG
// Ch Ã¢teau Frontenac to Chateau Frontenac
export const BP =
[
  [0,0,0,0,0,0],
  [1,0,0,0,0,0],
  [2,1,0,0,0,0],
  [3,1,1,1,0,0],
  [4,1,1,1,1,1],
  [5,2,1,1,1,1],
  [6,2,1,1,1,1],
  [7,2,2,1,1,1],
  [8,3,2,1,1,1],
  [9,3,2,2,1,1],
  [10,3,2,2,2,1],
  [11,3,2,2,2,1],
  [12,4,3,2,2,1],
  [13,4,3,2,2,1],
  [14,4,3,2,2,2],
  [15,4,3,2,2,2],
  [16,5,3,3,2,2],
  [17,5,3,3,2,2],
  [18,5,4,3,2,2],
  [19,5,4,3,2,2],
  [20,5,4,3,2,2],
  [21,6,4,3,3,2],
  [22,6,4,3,3,2],
  [23,6,4,3,3,2],
  [24,6,4,3,3,2],
  [25,6,5,4,3,2],
  [26,7,5,4,3,3],
  [27,7,5,4,3,3],
  [28,7,5,4,3,3],
  [29,7,5,4,3,3],
  [30,7,5,4,3,3],
  [31,7,5,4,3,3],
  [32,8,5,4,3,3],
  [33,8,6,4,4,3],
  [34,8,6,4,4,3],
  [35,8,6,5,4,3],
  [36,8,6,5,4,3],
  [37,8,6,5,4,3],
  [38,9,6,5,4,3],
  [39,9,6,5,4,3],
  [40,9,6,5,4,3],
  [41,9,7,5,4,4],
  [42,9,7,5,4,4],
  [43,10,7,5,4,4],
  [44,10,7,5,4,4],
  [45,10,7,5,4,4],
  [46,10,7,6,5,4],
  [47,10,7,6,5,4],
  [48,10,7,6,5,4],
  [49,11,8,6,5,4],
  [50,11,8,6,5,4],
  [51,11,8,6,5,4],
  [52,11,8,6,5,4],
  [53,11,8,6,5,4],
  [54,11,8,6,5,4],
  [55,11,8,6,5,4],
  [56,12,8,6,5,4],
  [57,12,8,7,5,5],
  [58,12,9,7,5,5],
  [59,12,9,7,6,5],
  [60,12,9,7,6,5],
  [61,12,9,7,6,5],
  [62,13,9,7,6,5],
  [63,13,9,7,6,5],
  [64,13,9,7,6,5],
  [65,13,9,7,6,5],
  [66,13,9,7,6,5],
  [67,13,10,7,6,5],
  [68,14,10,8,6,5],
  [69,14,10,8,6,5],
  [70,14,10,8,6,5],
  [71,14,10,8,6,5],
  [72,14,10,8,6,5],
  [73,14,10,8,6,5],
  [74,14,10,8,7,6],
  [75,15,10,8,7,6],
  [76,15,11,8,7,6],
  [77,15,11,8,7,6],
  [78,15,11,8,7,6],
  [79,15,11,8,7,6],
  [80,15,11,9,7,6],
  [81,15,11,9,7,6],
  [82,16,11,9,7,6],
  [83,16,11,9,7,6],
  [84,16,11,9,7,6],
  [85,16,11,9,7,6],
  [86,16,12,9,7,6],
  [87,16,12,9,7,6],
  [88,17,12,9,8,6],
  [89,17,12,9,8,6],
  [90,17,12,9,8,6],
  [91,17,12,9,8,7],
  [92,17,12,10,8,7],
  [93,17,12,10,8,7],
  [94,17,12,10,8,7],
  [95,18,13,10,8,7],
  [96,18,13,10,8,7],
  [97,18,13,10,8,7],
  [98,18,13,10,8,7],
  [99,18,13,10,8,7],
  [100,18,13,10,8,7],
  [101,18,13,10,8,7],
  [102,19,13,10,8,7],
  [103,19,13,10,9,7],
  [104,19,13,10,9,7],
  [105,19,14,11,9,7],
  [106,19,14,11,9,7],
  [107,19,14,11,9,7],
  [108,19,14,11,9,7],
  [109,20,14,11,9,8],
  [110,20,14,11,9,8],
  [111,20,14,11,9,8],
  [112,20,14,11,9,8],
  [113,20,14,11,9,8],
  [114,20,14,11,9,8],
  [115,20,15,11,9,8],
  [116,21,15,11,9,8],
  [117,21,15,11,9,8],
  [118,21,15,12,9,8],
  [119,21,15,12,10,8],
  [120,21,15,12,10,8],
  [121,21,15,12,10,8],
  [122,21,15,12,10,8],
  [123,22,15,12,10,8],
  [124,22,15,12,10,8],
  [125,22,16,12,10,8],
  [126,22,16,12,10,8],
  [127,22,16,12,10,8],
  [128,22,16,12,10,9],
  [129,22,16,12,10,9],
  [130,23,16,13,10,9],
  [131,23,16,13,10,9],
  [132,23,16,13,10,9],
  [133,23,16,13,10,9],
  [134,23,16,13,10,9],
  [135,23,17,13,11,9],
  [136,23,17,13,11,9],
  [137,23,17,13,11,9],
  [138,24,17,13,11,9],
  [139,23,17,13,11,9],
  [140,24,17,13,11,9],
  [141,23,17,13,11,9],
  [142,24,17,13,11,9],
  [143,24,17,13,11,9],
  [144,24,17,14,11,9],
  [145,25,18,14,11,9],
  [146,25,18,14,11,9],
  [147,25,18,14,11,10],
  [148,25,18,14,11,10],
  [149,25,18,14,11,10],
  [150,25,18,14,11,10],
  [151,25,18,14,12,10],
  [152,26,18,14,12,10],
  [153,26,18,14,12,10],
  [154,26,18,14,12,10],
  [155,26,19,14,12,10],
  [156,26,18,14,12,10],
  [157,26,19,15,12,10],
  [158,26,18,14,12,10],
  [159,26,19,15,12,10],
  [160,27,19,15,12,10],
  [161,27,19,15,12,10],
  [162,27,19,15,12,10],
  [163,27,19,15,12,10],
  [164,27,19,15,12,10]
];

// Stufen	FP	FPK	FP_P1	FP_19	FP_Tag	RPK
export const LG =
[
  ["Aachener Dom 000",0,0,0,0,0,1990],
  ["Aachener Dom 001",50,50,5,30,0,2740],
  ["Aachener Dom 002",60,110,10,31,0,3640],
  ["Aachener Dom 003",120,230,15,62,0,5440],
  ["Aachener Dom 004",190,420,20,123,0,8290],
  ["Aachener Dom 005",240,660,25,153,0,11890],
  ["Aachener Dom 006",320,980,35,205,0,16690],
  ["Aachener Dom 007",380,1360,40,256,0,22390],
  ["Aachener Dom 008",460,1820,50,288,0,29290],
  ["Aachener Dom 009",530,2350,55,339,0,37240],
  ["Aachener Dom 010",600,2950,65,380,0,46240],
  ["Aachener Dom 011",615,3565,70,386,0,55465],
  ["Aachener Dom 012",631,4196,80,364,0,64930],
  ["Aachener Dom 013",647,4843,85,360,0,74635],
  ["Aachener Dom 014",663,5506,95,348,0,84580],
  ["Aachener Dom 015",679,6185,100,355,0,94765],
  ["Aachener Dom 016",696,6881,110,334,0,105205],
  ["Aachener Dom 017",714,7595,120,324,0,115915],
  ["Aachener Dom 018",732,8327,130,313,0,126895],
  ["Aachener Dom 019",750,9077,135,302,0,138145],
  ["Aachener Dom 020",769,9846,145,292,0,149680],
  ["Aachener Dom 021",788,10634,155,283,0,161500],
  ["Aachener Dom 022",807,11441,165,255,0,173605],
  ["Aachener Dom 023",828,12269,175,248,0,186025],
  ["Aachener Dom 024",848,13117,180,259,0,198745],
  ["Aachener Dom 025",869,13986,190,251,0,211780],
  ["Aachener Dom 026",891,14877,200,235,0,225145],
  ["Aachener Dom 027",913,15790,210,228,0,238840],
  ["Aachener Dom 028",936,16726,220,223,0,252880],
  ["Aachener Dom 029",960,17686,230,209,0,267280],
  ["Aachener Dom 030",984,18670,240,205,0,282040],
  ["Aachener Dom 031",1008,19678,250,200,0,297160],
  ["Aachener Dom 032",1033,20711,255,196,0,312655],
  ["Aachener Dom 033",1059,21770,265,193,0,328540],
  ["Aachener Dom 034",1086,22856,275,192,0,344830],
  ["Aachener Dom 035",1113,23969,285,161,0,361525],
  ["Aachener Dom 036",1141,25110,295,161,0,378640],
  ["Aachener Dom 037",1169,26279,305,160,0,396175],
  ["Aachener Dom 038",1198,27477,315,151,0,414145],
  ["Aachener Dom 039",1228,28705,325,152,0,432565],
  ["Aachener Dom 040",1259,29964,335,155,0,451450],
  ["Aachener Dom 041",1291,31255,345,149,0,470815],
  ["Aachener Dom 042",1323,32578,360,144,0,490660],
  ["Aachener Dom 043",1356,33934,370,148,0,511000],
  ["Aachener Dom 044",1390,35324,380,144,0,531850],
  ["Aachener Dom 045",1424,36748,390,149,0,553210],
  ["Aachener Dom 046",1460,38208,400,157,0,575110],
  ["Aachener Dom 047",1497,39705,410,147,0,597565],
  ["Aachener Dom 048",1534,41239,420,156,0,620575],
  ["Aachener Dom 049",1572,42811,430,165,0,644155],
  ["Aachener Dom 050",1612,44423,440,167,0,668335],
  ["Aachener Dom 051",1652,46075,450,178,0,693115],
  ["Aachener Dom 052",1693,47768,465,162,0,718510],
  ["Aachener Dom 053",1735,49503,475,176,0,744535],
  ["Aachener Dom 054",1779,51282,485,191,0,771220],
  ["Aachener Dom 055",1823,53105,495,197,0,798565],
  ["Aachener Dom 056",1869,54974,505,214,0,826600],
  ["Aachener Dom 057",1916,56890,515,233,0,855340],
  ["Aachener Dom 058",1963,58853,525,232,0,884785],
  ["Aachener Dom 059",2012,60865,540,244,0,914965],
  ["Aachener Dom 060",2063,62928,550,266,0,945910],
  ["Aachener Dom 061",2114,65042,560,279,0,977620],
  ["Aachener Dom 062",2167,67209,570,303,0,1010125],
  ["Aachener Dom 063",2221,69430,585,300,0,1043440],
  ["Aachener Dom 064",2277,71707,595,328,0,1077595],
  ["Aachener Dom 065",2334,74041,605,356,0,1112605],
  ["Aachener Dom 066",2392,76433,615,376,0,1148485],
  ["Aachener Dom 067",2452,78885,625,407,0,1185265],
  ["Aachener Dom 068",2513,81398,640,431,0,1222960],
  ["Aachener Dom 069",2576,83974,650,447,0,1261600],
  ["Aachener Dom 070",2640,86614,660,483,0,1301200],
  ["Aachener Dom 071",2706,89320,675,491,0,1341790],
  ["Aachener Dom 072",2774,92094,685,530,0,1383400],
  ["Aachener Dom 073",2843,94937,695,571,0,1426045],
  ["Aachener Dom 074",2914,97851,705,604,0,1469755],
  ["Aachener Dom 075",2987,100838,720,640,0,1514560],
  ["Aachener Dom 076",3062,103900,730,686,0,1560490],
  ["Aachener Dom 077",3138,107038,740,724,0,1607560],
  ["Aachener Dom 078",3217,110255,755,755,0,1655815],
  ["Aachener Dom 079",3297,113552,765,787,0,1705270],
  ["Aachener Dom 080",3380,116932,775,842,0,1755970],
  ["Aachener Dom 081",3464,120396,790,888,0,1807930],
  ["Aachener Dom 082",3551,123947,800,937,0,1861195],
  ["Aachener Dom 083",3640,127587,810,997,0,1915795],
  ["Aachener Dom 084",3731,131300,825,1031,0,1971490],
  ["Aachener Dom 085",3824,135124,835,1096,0,2028850],
  ["Aachener Dom 086",3919,139043,850,1153,0,2087635],
  ["Aachener Dom 087",4017,143060,860,1213,0,2147890],
  ["Aachener Dom 088",4118,147178,875,1266,0,2209660],
  ["Aachener Dom 089",4221,151399,885,1313,0,2272975],
  ["Aachener Dom 090",4326,155725,895,1390,0,2337865],
  ["Aachener Dom 091",4434,160159,910,1460,0,2404375],
  ["Aachener Dom 092",4545,164704,920,1533,0,2472550],
  ["Aachener Dom 093",4659,169363,930,1618,0,2542435],
  ["Aachener Dom 094",4775,174138,945,1677,0,2614060],
  ["Aachener Dom 095",4895,179033,955,1769,0,2687485],
  ["Aachener Dom 096",5017,184050,970,1853,0,2762740],
  ["Aachener Dom 097",5142,189192,980,1940,0,2839870],
  ["Aachener Dom 098",5271,194463,995,2021,0,2918935],
  ["Aachener Dom 099",5403,199866,1005,2105,0,2999980],
  ["Aachener Dom 100",5538,205404,1015,2212,0,3083050],
  ["Aachener Dom 101",5676,211080,1030,2312,0,3168190],
  ["Aachener Dom 102",5818,216898,1040,2416,0,3255460],
  ["Aachener Dom 103",5964,222862,1055,2514,0,3344920],
  ["Aachener Dom 104",6113,228975,1065,2625,0,3436615],
  ["Aachener Dom 105",6265,235240,1080,2740,0,3530590],
  ["Aachener Dom 106",6422,241662,1090,2868,0,3626920],
  ["Aachener Dom 107",6583,248245,1105,2971,0,3725665],
  ["Aachener Dom 108",6747,254992,1115,3107,0,3826870],
  ["Aachener Dom 109",6916,261908,1130,3220,0,3930610],
  ["Aachener Dom 110",7089,268997,1140,3365,0,4036945],
  ["Aachener Dom 111",7266,276263,1155,3484,0,4145935],
  ["Aachener Dom 112",7448,283711,1165,3637,0,4257655],
  ["Aachener Dom 113",7634,291345,1180,3786,0,4372165],
  ["Aachener Dom 114",7825,299170,1190,3939,0,4489540],
  ["Aachener Dom 115",8020,307190,1205,4086,0,4609840],
  ["Aachener Dom 116",8221,315411,1215,4249,0,4733155],
  ["Aachener Dom 117",8426,323837,1230,4416,0,4859545],
  ["Aachener Dom 118",8637,332474,1240,4599,0,4989100],
  ["Aachener Dom 119",8853,341327,1255,4748,0,5121895],
  ["Aachener Dom 120",9074,350401,1265,4940,0,5258005],
  ["Aachener Dom 121",9301,359702,1280,5120,0,5397520],
  ["Aachener Dom 122",9533,369235,1290,5323,0,5540515],
  ["Aachener Dom 123",9772,379007,1305,5505,0,5687095],
  ["Aachener Dom 124",10016,389023,1320,5712,0,5837335],
  ["Aachener Dom 125",10266,399289,1330,5933,0,5991325],
  ["Aachener Dom 126",10523,409812,1345,6132,0,6149170],
  ["Aachener Dom 127",10786,420598,1355,6367,0,6310960],
  ["Aachener Dom 128",11056,431654,1370,6581,0,6476800],
  ["Aachener Dom 129",11332,442986,1380,6829,0,6646780],
  ["Aachener Dom 130",11615,454601,1395,7054,0,6821005],
  ["Aachener Dom 131",11906,466507,1405,7316,0,6999595],
  ["Alcatraz 000",0,0,0,0,0,21000],
  ["Alcatraz 001",60,60,10,31,0,21900],
  ["Alcatraz 002",90,150,10,61,0,23250],
  ["Alcatraz 003",170,320,20,103,0,25800],
  ["Alcatraz 004",260,580,30,164,0,29700],
  ["Alcatraz 005",340,920,35,225,0,34800],
  ["Alcatraz 006",440,1360,45,277,0,41400],
  ["Alcatraz 007",530,1890,55,339,0,49350],
  ["Alcatraz 008",630,2520,65,410,0,58800],
  ["Alcatraz 009",740,3260,75,482,0,69900],
  ["Alcatraz 010",830,4090,85,543,0,82350],
  ["Alcatraz 011",851,4941,95,536,0,95115],
  ["Alcatraz 012",873,5814,105,520,0,108210],
  ["Alcatraz 013",894,6708,120,504,0,121620],
  ["Alcatraz 014",917,7625,130,498,0,135375],
  ["Alcatraz 015",940,8565,140,483,0,149475],
  ["Alcatraz 016",963,9528,155,458,0,163920],
  ["Alcatraz 017",987,10515,165,435,0,178725],
  ["Alcatraz 018",1012,11527,175,432,0,193905],
  ["Alcatraz 019",1037,12564,190,419,0,209460],
  ["Alcatraz 020",1063,13627,200,407,0,225405],
  ["Alcatraz 021",1090,14717,215,386,0,241755],
  ["Alcatraz 022",1117,15834,225,375,0,258510],
  ["Alcatraz 023",1145,16979,240,366,0,275685],
  ["Alcatraz 024",1173,18152,250,365,0,293280],
  ["Alcatraz 025",1203,19355,265,337,0,311325],
  ["Alcatraz 026",1233,20588,275,339,0,329820],
  ["Alcatraz 027",1263,21851,290,302,0,348765],
  ["Alcatraz 028",1295,23146,300,306,0,368190],
  ["Alcatraz 029",1327,24473,315,280,0,388095],
  ["Alcatraz 030",1361,25834,330,276,0,408510],
  ["Alcatraz 031",1395,27229,340,282,0,429435],
  ["Alcatraz 032",1429,28658,355,259,0,450870],
  ["Alcatraz 033",1465,30123,370,257,0,472845],
  ["Alcatraz 034",1502,31625,385,236,0,495375],
  ["Alcatraz 035",1539,33164,395,245,0,518460],
  ["Alcatraz 036",1578,34751,410,228,0,542265],
  ["Alcatraz 037",1617,36368,425,219,0,566520],
  ["Alcatraz 038",1658,38026,440,213,0,591390],
  ["Alcatraz 039",1699,39725,450,225,0,616875],
  ["Alcatraz 040",1741,41466,465,210,0,642990],
  ["Alcatraz 041",1785,43251,480,217,0,669765],
  ["Alcatraz 042",1830,45081,495,204,0,697215],
  ["Alcatraz 043",1875,46956,510,211,0,725340],
  ["Alcatraz 044",1922,48878,525,191,0,754170],
  ["Alcatraz 045",1970,50848,535,211,0,783720],
  ["Alcatraz 046",2020,52868,550,223,0,814020],
  ["Alcatraz 047",2070,54938,565,215,0,845070],
  ["Alcatraz 048",2122,57060,580,230,0,876900],
  ["Alcatraz 049",2175,59235,595,226,0,909525],
  ["Alcatraz 050",2229,61464,610,242,0,942960],
  ["Alcatraz 051",2285,63749,625,240,0,977235],
  ["Alcatraz 052",2342,66091,640,260,0,1012365],
  ["Alcatraz 053",2400,68491,655,252,0,1048365],
  ["Alcatraz 054",2460,70951,670,274,0,1085265],
  ["Alcatraz 055",2522,73473,685,278,0,1123095],
  ["Alcatraz 056",2585,76058,700,304,0,1161870],
  ["Alcatraz 057",2650,78708,715,312,0,1201620],
  ["Alcatraz 058",2716,81424,730,340,0,1242360],
  ["Alcatraz 059",2784,84208,745,350,0,1284120],
  ["Alcatraz 060",2853,87061,760,382,0,1326915],
  ["Alcatraz 061",2925,89986,775,387,0,1370790],
  ["Alcatraz 062",2998,92984,790,422,0,1415760],
  ["Alcatraz 063",3073,96057,805,439,0,1461855],
  ["Alcatraz 064",3149,99206,820,478,0,1509090],
  ["Alcatraz 065",3228,102434,835,500,0,1557510],
  ["Alcatraz 066",3309,105743,855,514,0,1607145],
  ["Alcatraz 067",3392,109135,870,559,0,1658025],
  ["Alcatraz 068",3476,112611,885,568,0,1710165],
  ["Alcatraz 069",3563,116174,900,618,0,1763610],
  ["Alcatraz 070",3652,119826,915,649,0,1818390],
  ["Alcatraz 071",3744,123570,930,703,0,1874550],
  ["Alcatraz 072",3837,127407,945,739,0,1932105],
  ["Alcatraz 073",3933,131340,965,778,0,1991100],
  ["Alcatraz 074",4031,135371,980,829,0,2051565],
  ["Alcatraz 075",4132,139503,995,882,0,2113545],
  ["Alcatraz 076",4235,143738,1010,928,0,2177070],
  ["Alcatraz 077",4341,148079,1025,986,0,2242185],
  ["Alcatraz 078",4450,152529,1045,1028,0,2308935],
  ["Alcatraz 079",4561,157090,1060,1102,0,2377350],
  ["Alcatraz 080",4675,161765,1075,1159,0,2447475],
  ["Alcatraz 081",4792,166557,1090,1238,0,2519355],
  ["Alcatraz 082",4912,171469,1110,1291,0,2593035],
  ["Alcatraz 083",5035,176504,1125,1348,0,2668560],
  ["Alcatraz 084",5160,181664,1140,1436,0,2745960],
  ["Alcatraz 085",5289,186953,1160,1498,0,2825295],
  ["Alcatraz 086",5422,192375,1175,1583,0,2906625],
  ["Alcatraz 087",5557,197932,1190,1671,0,2989980],
  ["Alcatraz 088",5696,203628,1205,1762,0,3075420],
  ["Alcatraz 089",5838,209466,1225,1837,0,3162990],
  ["Alcatraz 090",5984,215450,1240,1946,0,3252750],
  ["Alcatraz 091",6134,221584,1255,2029,0,3344760],
  ["Alcatraz 092",6287,227871,1275,2115,0,3439065],
  ["Alcatraz 093",6445,234316,1290,2235,0,3535740],
  ["Alcatraz 094",6606,240922,1305,2339,0,3634830],
  ["Alcatraz 095",6771,247693,1325,2447,0,3736395],
  ["Alcatraz 096",6940,254633,1340,2569,0,3840495],
  ["Alcatraz 097",7114,261747,1355,2695,0,3947205],
  ["Alcatraz 098",7291,269038,1375,2797,0,4056570],
  ["Alcatraz 099",7474,276512,1390,2942,0,4168680],
  ["Alcatraz 100",7660,284172,1410,3061,0,4283580],
  ["Alcatraz 101",7852,292024,1425,3196,0,4401360],
  ["Alcatraz 102",8048,300072,1440,3355,0,4522080],
  ["Alcatraz 103",8249,308321,1460,3489,0,4645815],
  ["Alcatraz 104",8456,316777,1475,3648,0,4772655],
  ["Alcatraz 105",8667,325444,1490,3792,0,4902660],
  ["Alcatraz 106",8884,334328,1510,3952,0,5035920],
  ["Alcatraz 107",9106,343434,1525,4116,0,5172510],
  ["Alcatraz 108",9333,352767,1545,4277,0,5312505],
  ["Alcatraz 109",9567,362334,1560,4474,0,5456010],
  ["Alcatraz 110",9806,372140,1580,4646,0,5603100],
  ["Alcatraz 111",10051,382191,1595,4843,0,5753865],
  ["Alcatraz 112",10302,392493,1615,5019,0,5908395],
  ["Alcatraz 113",10560,403053,1630,5239,0,6066795],
  ["Alcatraz 114",10824,413877,1650,5436,0,6229155],
  ["Alcatraz 115",11094,424971,1665,5649,0,6395565],
  ["Alcatraz 116",11372,436343,1685,5870,0,6566145],
  ["Alcatraz 117",11656,447999,1700,6107,0,6740985],
  ["Alcatraz 118",11947,459946,1715,6350,0,6920190],
  ["Alcatraz 119",12246,472192,1735,6573,0,7103880],
  ["Alcatraz 120",12552,484744,1755,6812,0,7292160],
  ["Alcatraz 121",12866,497610,1770,7088,0,7485150],
  ["Alcatraz 122",13188,510798,1790,7344,0,7682970],
  ["Alcatraz 123",13517,524315,1805,7625,0,7885725],
  ["Alcatraz 124",13855,538170,1825,7896,0,8093550],
  ["Alcatraz 125",14202,552372,1840,8206,0,8306580],
  ["Alcatraz 126",14557,566929,1860,8486,0,8524935],
  ["Alcatraz 127",14921,581850,1875,8792,0,8748750],
  ["Alcatraz 128",15294,597144,1895,9108,0,8978160],
  ["Alcatraz 129",15676,612820,1915,9424,0,9213300],
  ["Alcatraz 130",16068,628888,1930,9778,0,9454320],
  ["Alcatraz 131",16469,645357,1950,10112,0,9701355],
  ["Alcatraz 132",16881,662238,1965,10457,0,9954570],
  ["Alcatraz 133",17303,679541,1985,10822,0,10214115],
  ["Alcatraz 134",17736,697277,2000,11208,0,10480155],
  ["Alcatraz 135",18179,715456,2020,11594,0,10752840],
  ["Alcatraz 136",18634,734090,2040,11983,0,11032350],
  ["Alcatraz 137",19099,753189,2055,12390,0,11318835],
  ["Alcatraz 138",19577,772766,2075,12811,0,11612490],
  ["Alcatraz 139",20066,792832,2095,13216,0,11913480],
  ["Alcatraz 140",20568,813400,2110,13680,0,12222000],
  ["Alcatraz 141",21082,834482,2130,14127,0,12538230],
  ["Alcatraz 142",21609,856091,2145,14597,0,12862365],
  ["Alcatraz 143",22149,878240,2165,15080,0,13194600],
  ["Alcatraz 144",22703,900943,2185,15567,0,13535145],
  ["Alcatraz 145",23271,924214,2200,16098,0,13884210],
  ["Alcatraz 146",23852,948066,2220,16603,0,14241990],
  ["Alcatraz 147",24449,972515,2240,17133,0,14608725],
  ["Alcatraz 148",25060,997575,2255,17696,0,14984625],
  ["Alcatraz 149",25686,1023261,2275,18256,0,15369915],
  ["Alcatraz 150",26329,1049590,2295,18832,0,15764850],
  ["Alcatraz 151",26987,1076577,2310,19452,0,16169655],
  ["Alcatraz 152",27661,1104238,2330,20051,0,16584570],
  ["Alcatraz 153",28353,1132591,2350,20686,0,17009865],
  ["Alcatraz 154",29062,1161653,2365,21337,0,17445795],
  ["Alcatraz 155",29788,1191441,2385,21997,0,17892615],
  ["Alcatraz 156",30533,1221974,2405,22685,0,18350610],
  ["Alcatraz 157",31296,1253270,2420,23401,0,18820050],
  ["Alcatraz 158",32079,1285349,2440,24127,0,19301235],
  ["Alcatraz 159",32881,1318230,2460,24853,0,19794450],
  ["Alcatraz 160",33703,1351933,2480,25608,0,20299995],
  ["Alcatraz 161",34545,1386478,2495,26402,0,20818170],
  ["Alcatraz 162",35409,1421887,2515,27200,0,21349305],
  ["Alcatraz 163",36294,1458181,2535,28018,0,21893715],
  ["Alcatraz 164",37201,1495382,2555,28868,0,22451730],
  ["Alcatraz 165",38131,1533513,2570,29742,0,23023695],
  ["Alcatraz 166",39085,1572598,2590,30639,0,23609970],
  ["Alcatraz 167",40062,1612660,2610,31549,0,24210900],
  ["Arktische Orangerie 000",0,0,0,0,0,162600],
  ["Arktische Orangerie 001",80,80,10,51,2,163800],
  ["Arktische Orangerie 002",120,200,15,62,2,165600],
  ["Arktische Orangerie 003",230,430,25,143,3,169050],
  ["Arktische Orangerie 004",340,770,35,225,4,174150],
  ["Arktische Orangerie 005",450,1220,45,287,5,180900],
  ["Arktische Orangerie 006",580,1800,60,380,6,189600],
  ["Arktische Orangerie 007",710,2510,75,452,6,200250],
  ["Arktische Orangerie 008",850,3360,85,563,7,213000],
  ["Arktische Orangerie 009",980,4340,100,656,8,227700],
  ["Arktische Orangerie 010",1110,5450,115,729,10,244350],
  ["Arktische Orangerie 011",1138,6588,130,719,11,261420],
  ["Arktische Orangerie 012",1167,7755,145,690,12,278925],
  ["Arktische Orangerie 013",1196,8951,160,682,13,296865],
  ["Arktische Orangerie 014",1226,10177,170,665,14,315255],
  ["Arktische Orangerie 015",1256,11433,190,638,15,334095],
  ["Arktische Orangerie 016",1288,12721,205,612,16,353415],
  ["Arktische Orangerie 017",1320,14041,220,607,17,373215],
  ["Arktische Orangerie 018",1353,15394,235,583,18,393510],
  ["Arktische Orangerie 019",1387,16781,250,579,19,414315],
  ["Arktische Orangerie 020",1421,18202,265,555,20,435630],
  ["Arktische Orangerie 021",1457,19659,285,505,21,457485],
  ["Arktische Orangerie 022",1493,21152,300,504,22,479880],
  ["Arktische Orangerie 023",1531,22683,315,484,23,502845],
  ["Arktische Orangerie 024",1569,24252,335,465,24,526380],
  ["Arktische Orangerie 025",1608,25860,350,457,25,550500],
  ["Arktische Orangerie 026",1648,27508,370,440,26,575220],
  ["Arktische Orangerie 027",1689,29197,385,423,27,600555],
  ["Arktische Orangerie 028",1732,30929,400,429,28,626535],
  ["Arktische Orangerie 029",1775,32704,420,397,29,653160],
  ["Arktische Orangerie 030",1819,34523,440,374,30,680445],
  ["Arktische Orangerie 031",1865,36388,455,372,31,708420],
  ["Arktische Orangerie 032",1911,38299,475,352,32,737085],
  ["Arktische Orangerie 033",1959,40258,490,362,33,766470],
  ["Arktische Orangerie 034",2008,42266,510,344,34,796590],
  ["Arktische Orangerie 035",2058,44324,525,327,35,827460],
  ["Arktische Orangerie 036",2110,46434,545,322,36,859110],
  ["Arktische Orangerie 037",2163,48597,565,308,37,891555],
  ["Arktische Orangerie 038",2217,50814,585,296,38,924810],
  ["Arktische Orangerie 039",2272,53086,600,314,39,958890],
  ["Arktische Orangerie 040",2329,55415,620,304,40,993825],
  ["Arktische Orangerie 041",2387,57804,640,305,41,1029660],
  ["Arktische Orangerie 042",2447,60251,660,290,42,1066365],
  ["Arktische Orangerie 043",2508,62759,675,293,43,1103985],
  ["Arktische Orangerie 044",2571,65330,695,299,44,1142550],
  ["Arktische Orangerie 045",2635,67965,715,297,45,1182075],
  ["Arktische Orangerie 046",2701,70666,735,296,46,1222590],
  ["Arktische Orangerie 047",2768,73434,755,306,47,1264110],
  ["Arktische Orangerie 048",2837,76271,775,299,48,1306665],
  ["Arktische Orangerie 049",2908,79179,795,303,49,1350285],
  ["Arktische Orangerie 050",2981,82160,815,319,50,1395000],
  ["Arktische Orangerie 051",3055,85215,830,346,51,1440825],
  ["Arktische Orangerie 052",3132,88347,850,366,52,1487805],
  ["Arktische Orangerie 053",3210,91557,870,377,53,1535955],
  ["Arktische Orangerie 054",3290,94847,895,354,54,1585305],
  ["Arktische Orangerie 055",3373,98220,910,399,55,1635900],
  ["Arktische Orangerie 056",3457,101677,930,416,56,1687755],
  ["Arktische Orangerie 057",3543,105220,950,436,57,1740900],
  ["Arktische Orangerie 058",3632,108852,970,468,58,1795380],
  ["Arktische Orangerie 059",3723,112575,995,473,59,1851225],
  ["Arktische Orangerie 060",3816,116391,1015,490,60,1908465],
  ["Arktische Orangerie 061",3911,120302,1035,518,61,1967130],
  ["Arktische Orangerie 062",4009,124311,1055,559,62,2027265],
  ["Arktische Orangerie 063",4109,128420,1075,593,63,2088900],
  ["Arktische Orangerie 064",4212,132632,1095,629,64,2152080],
  ["Arktische Orangerie 065",4317,136949,1115,677,65,2216835],
  ["Arktische Orangerie 066",4425,141374,1135,710,66,2283210],
  ["Arktische Orangerie 067",4536,145910,1155,754,67,2351250],
  ["Arktische Orangerie 068",4649,150559,1180,801,68,2420985],
  ["Arktische Orangerie 069",4765,155324,1200,851,69,2492460],
  ["Arktische Orangerie 070",4884,160208,1220,903,70,2565720],
  ["Arktische Orangerie 071",5006,165214,1240,968,71,2640810],
  ["Arktische Orangerie 072",5132,170346,1260,1018,72,2717790],
  ["Arktische Orangerie 073",5260,175606,1285,1059,73,2796690],
  ["Arktische Orangerie 074",5391,180997,1305,1124,74,2877555],
  ["Arktische Orangerie 075",5526,186523,1325,1202,75,2960445],
  ["Arktische Orangerie 076",5664,192187,1350,1264,76,3045405],
  ["Arktische Orangerie 077",5806,197993,1370,1331,77,3132495],
  ["Arktische Orangerie 078",5951,203944,1390,1419,78,3221760],
  ["Arktische Orangerie 079",6100,210044,1410,1501,79,3313260],
  ["Arktische Orangerie 080",6252,216296,1435,1568,80,3407040],
  ["Arktische Orangerie 081",6408,222704,1455,1657,81,3503160],
  ["Arktische Orangerie 082",6569,229273,1475,1761,82,3601695],
  ["Arktische Orangerie 083",6733,236006,1500,1830,83,3702690],
  ["Arktische Orangerie 084",6901,242907,1520,1931,84,3806205],
  ["Arktische Orangerie 085",7074,249981,1545,2018,85,3912315],
  ["Arktische Orangerie 086",7250,257231,1565,2137,86,4021065],
  ["Arktische Orangerie 087",7432,264663,1585,2252,87,4132545],
  ["Arktische Orangerie 088",7618,272281,1610,2354,88,4246815],
  ["Arktische Orangerie 089",7808,280089,1630,2487,89,4363935],
  ["Arktische Orangerie 090",8003,288092,1650,2615,90,4483980],
  ["Arktische Orangerie 091",8203,296295,1675,2730,91,4607025],
  ["Arktische Orangerie 092",8408,304703,1695,2868,92,4733145],
  ["Arktische Orangerie 093",8618,313321,1720,3012,93,4862415],
  ["Arktische Orangerie 094",8834,322155,1740,3152,94,4994925],
  ["Arktische Orangerie 095",9055,331210,1765,3286,95,5130750],
  ["Arktische Orangerie 096",9281,340428,1785,3446,96,5269020],
  ["Arktische Orangerie 097",9513,349941,1810,3612,97,5411715],
  ["Arktische Orangerie 098",9751,359692,1830,3783,98,5557980],
  ["Arktische Orangerie 099",9995,369687,1855,3933,99,5707905],
  ["Arktische Orangerie 100",10245,379932,1875,4116,100,5861580],
  ["Arktische Orangerie 101",10501,390433,1900,4306,101,6019095],
  ["Arktische Orangerie 102",10763,401196,1920,4502,102,6180540],
  ["Arktische Orangerie 103",11032,412228,1945,4684,103,6346020],
  ["Arktische Orangerie 104",11308,423536,1965,4884,104,6515640],
  ["Arktische Orangerie 105",11591,435127,1990,5101,105,6689505],
  ["Arktische Orangerie 106",11880,447007,2015,5304,106,6867705],
  ["Arktische Orangerie 107",12177,459184,2035,5535,107,7050360],
  ["Arktische Orangerie 108",12482,471666,2060,5764,108,7237590],
  ["Arktische Orangerie 109",12794,484460,2080,6019,109,7429500],
  ["Arktische Orangerie 110",13114,497574,2105,6235,110,7626210],
  ["Arktische Orangerie 111",13442,511016,2125,6496,111,7827840],
  ["Arktische Orangerie 112",13778,524794,2150,6757,112,8034510],
  ["Arktische Orangerie 113",14122,538916,2175,7015,113,8246340],
  ["Arktische Orangerie 114",14475,553391,2195,7311,114,8463465],
  ["Arktische Orangerie 115",14837,568228,2220,7588,115,8686020],
  ["Arktische Orangerie 116",15208,583436,2245,7872,116,8914140],
  ["Arktische Orangerie 117",15588,599024,2265,8186,117,9147960],
  ["Arktische Orangerie 118",15978,615002,2290,8510,118,9387630],
  ["Arktische Orangerie 119",16377,631379,2315,8823,119,9633285],
  ["Arktische Orangerie 120",16787,648166,2335,9158,120,9885090],
  ["Arktische Orangerie 121",17206,665372,2360,9501,121,10143180],
  ["Arktische Orangerie 122",17636,683008,2385,9845,122,10407720],
  ["Arktische Orangerie 123",18077,701085,2405,10229,123,10678875],
  ["Arktische Orangerie 124",18529,719614,2430,10605,124,10956810],
  ["Arktische Orangerie 125",18992,738606,2455,10973,125,11241690],
  ["Arktische Orangerie 126",19467,758073,2480,11372,126,11533695],
  ["Arktische Orangerie 127",19954,778027,2500,11802,127,11833005],
  ["Arktische Orangerie 128",20453,798480,2525,12215,128,12139800],
  ["Arktische Orangerie 129",20964,819426,2550,12650,129,12453990],
  ["Arktische Orangerie 130",21488,840914,2575,13080,130,12776310],
  ["Arktische Orangerie 131",22025,862939,2595,13550,131,13106685],
  ["Arktische Orangerie 132",22576,885515,2620,14035,132,13445325],
  ["Arktische Orangerie 133",23140,908655,2645,14513,133,13792425],
  ["Arktische Orangerie 134",23719,932374,2670,15016,134,14148210],
  ["Arktische Orangerie 135",24312,956686,2690,15523,135,14512890],
  ["Arktische Orangerie 136",24919,981605,2715,16044,136,14886675],
  ["Arktische Orangerie 137",25542,1007147,2740,16601,137,15269805],
  ["Atlantis Museum 000",0,0,0,0,0,188350],
  ["Atlantis Museum 001",80,80,10,51,0,189550],
  ["Atlantis Museum 002",130,210,15,72,0,191500],
  ["Atlantis Museum 003",240,450,25,153,0,195100],
  ["Atlantis Museum 004",350,800,35,235,0,200350],
  ["Atlantis Museum 005",470,1270,50,298,0,207400],
  ["Atlantis Museum 006",610,1880,65,390,0,216550],
  ["Atlantis Museum 007",740,2620,75,482,0,227650],
  ["Atlantis Museum 008",880,3500,90,584,0,240850],
  ["Atlantis Museum 009",1020,4520,105,667,0,256150],
  ["Atlantis Museum 010",1160,5680,120,770,0,273550],
  ["Atlantis Museum 011",1189,6869,135,741,0,291385],
  ["Atlantis Museum 012",1219,8088,150,733,0,309670],
  ["Atlantis Museum 013",1250,9338,165,698,0,328420],
  ["Atlantis Museum 014",1281,10619,180,692,0,347635],
  ["Atlantis Museum 015",1313,11932,195,666,0,367330],
  ["Atlantis Museum 016",1346,13278,210,661,0,387520],
  ["Atlantis Museum 017",1379,14657,230,628,0,408205],
  ["Atlantis Museum 018",1414,16071,245,615,0,429415],
  ["Atlantis Museum 019",1449,17520,260,603,0,451150],
  ["Atlantis Museum 020",1485,19005,280,582,0,473425],
  ["Atlantis Museum 021",1523,20528,295,543,0,496270],
  ["Atlantis Museum 022",1561,22089,310,543,0,519685],
  ["Atlantis Museum 023",1600,23689,330,515,0,543685],
  ["Atlantis Museum 024",1640,25329,350,489,0,568285],
  ["Atlantis Museum 025",1681,27010,365,482,0,593500],
  ["Atlantis Museum 026",1723,28733,385,457,0,619345],
  ["Atlantis Museum 027",1766,30499,400,463,0,645835],
  ["Atlantis Museum 028",1810,32309,420,432,0,672985],
  ["Atlantis Museum 029",1855,34164,440,410,0,700810],
  ["Atlantis Museum 030",1901,36065,455,408,0,729325],
  ["Atlantis Museum 031",1949,38014,475,390,0,758560],
  ["Atlantis Museum 032",1998,40012,495,372,0,788530],
  ["Atlantis Museum 033",2047,42059,510,383,0,819235],
  ["Atlantis Museum 034",2099,44158,530,359,0,850720],
  ["Atlantis Museum 035",2151,46309,550,354,0,882985],
  ["Atlantis Museum 036",2205,48514,570,341,0,916060],
  ["Atlantis Museum 037",2260,50774,590,330,0,949960],
  ["Atlantis Museum 038",2316,53090,605,338,0,984700],
  ["Atlantis Museum 039",2374,55464,625,329,0,1020310],
  ["Atlantis Museum 040",2434,57898,645,314,0,1056820],
  ["Atlantis Museum 041",2495,60393,665,318,0,1094245],
  ["Atlantis Museum 042",2557,62950,685,313,0,1132600],
  ["Atlantis Museum 043",2621,65571,705,311,0,1171915],
  ["Atlantis Museum 044",2686,68257,725,319,0,1212205],
  ["Atlantis Museum 045",2753,71010,745,319,0,1253500],
  ["Atlantis Museum 046",2822,73832,765,312,0,1295830],
  ["Atlantis Museum 047",2893,76725,785,326,0,1339225],
  ["Atlantis Museum 048",2965,79690,805,331,0,1383700],
  ["Atlantis Museum 049",3039,82729,825,339,0,1429285],
  ["Atlantis Museum 050",3115,85844,845,358,0,1476010],
  ["Atlantis Museum 051",3193,89037,865,369,0,1523905],
  ["Atlantis Museum 052",3273,92310,890,356,0,1573000],
  ["Atlantis Museum 053",3355,95665,910,381,0,1623325],
  ["Atlantis Museum 054",3439,99104,930,398,0,1674910],
  ["Atlantis Museum 055",3524,102628,950,417,0,1727770],
  ["Atlantis Museum 056",3613,106241,970,449,0,1781965],
  ["Atlantis Museum 057",3703,109944,990,472,0,1837510],
  ["Atlantis Museum 058",3795,113739,1015,469,0,1894435],
  ["Atlantis Museum 059",3890,117629,1035,497,0,1952785],
  ["Atlantis Museum 060",3988,121617,1055,538,0,2012605],
  ["Atlantis Museum 061",4087,125704,1075,571,0,2073910],
  ["Atlantis Museum 062",4189,129893,1100,597,0,2136745],
  ["Atlantis Museum 063",4294,134187,1120,645,0,2201155],
  ["Atlantis Museum 064",4401,138597,1140,677,0,2267305],
  ["Atlantis Museum 065",4511,143108,1160,720,0,2334970],
  ["Atlantis Museum 066",4624,147732,1185,747,0,2404330],
  ["Atlantis Museum 067",4740,152472,1205,806,0,2475430],
  ["Atlantis Museum 068",4858,157330,1225,857,0,2548300],
  ["Atlantis Museum 069",4980,162310,1250,894,0,2623000],
  ["Atlantis Museum 070",5104,167414,1270,961,0,2699560],
  ["Atlantis Museum 071",5232,172646,1295,1003,0,2778040],
  ["Atlantis Museum 072",5363,178009,1315,1068,0,2858485],
  ["Atlantis Museum 073",5497,183506,1335,1135,0,2940940],
  ["Atlantis Museum 074",5634,189140,1360,1206,0,3025450],
  ["Atlantis Museum 075",5775,194915,1380,1272,0,3112075],
  ["Atlantis Museum 076",5919,200834,1405,1329,0,3200860],
  ["Atlantis Museum 077",6067,206901,1425,1411,0,3291865],
  ["Atlantis Museum 078",6219,213120,1450,1497,0,3385150],
  ["Atlantis Museum 079",6374,219494,1470,1585,0,3480760],
  ["Atlantis Museum 080",6534,226028,1495,1640,0,3578770],
  ["Atlantis Museum 081",6697,232707,1515,1736,0,3678955],
  ["Atlantis Museum 082",6864,239571,1540,1837,0,3781915],
  ["Atlantis Museum 083",7036,246607,1560,1943,0,3887455],
  ["Atlantis Museum 084",7212,253819,1585,2032,0,3995635],
  ["Atlantis Museum 085",7392,261211,1605,2137,0,4106515],
  ["Atlantis Museum 086",7577,268788,1630,2256,0,4220170],
  ["Atlantis Museum 087",7766,276554,1650,2378,0,4336660],
  ["Atlantis Museum 088",7961,284515,1675,2488,0,4456075],
  ["Atlantis Museum 089",8160,292675,1700,2611,0,4578475],
  ["Atlantis Museum 090",8364,301039,1720,2758,0,4703935],
  ["Atlantis Museum 091",8573,309612,1745,2871,0,4832530],
  ["Atlantis Museum 092",8787,318399,1770,3009,0,4964335],
  ["Atlantis Museum 093",9007,327406,1790,3163,0,5099440],
  ["Atlantis Museum 094",9232,336638,1815,3302,0,5237920],
  ["Atlantis Museum 095",9463,346101,1835,3476,0,5379865],
  ["Atlantis Museum 096",9699,355800,1860,3628,0,5525350],
  ["Atlantis Museum 097",9942,365742,1885,3784,0,5674480],
  ["Atlantis Museum 098",10190,375932,1905,3966,0,5827330],
  ["Atlantis Museum 099",10445,386377,1930,4155,0,5984005],
  ["Atlantis Museum 100",10706,397083,1955,4330,0,6144595],
  ["Atlantis Museum 101",10974,408057,1980,4513,0,6309205],
  ["Atlantis Museum 102",11248,419305,2000,4720,0,6477925],
  ["Atlantis Museum 103",11529,430834,2025,4915,0,6650860],
  ["Atlantis Museum 104",11817,442651,2050,5137,0,6828115],
  ["Atlantis Museum 105",12113,454764,2070,5366,0,7009810],
  ["Atlantis Museum 106",12416,467180,2095,5566,0,7196050],
  ["Atlantis Museum 107",12726,479906,2120,5800,0,7386940],
  ["Atlantis Museum 108",13044,492950,2145,6032,0,7582600],
  ["Atlantis Museum 109",13370,506320,2170,6292,0,7783150],
  ["Atlantis Museum 110",13704,520024,2190,6559,0,7988710],
  ["Atlantis Museum 111",14047,534071,2215,6807,0,8199415],
  ["Atlantis Museum 112",14398,548469,2240,7082,0,8415385],
  ["Atomium 000",0,0,0,0,0,30625],
  ["Atomium 001",60,60,10,31,0,31525],
  ["Atomium 002",100,160,10,71,0,33025],
  ["Atomium 003",180,340,20,113,0,35725],
  ["Atomium 004",270,610,30,174,0,39775],
  ["Atomium 005",360,970,40,236,0,45175],
  ["Atomium 006",460,1430,50,288,0,52075],
  ["Atomium 007",560,1990,60,360,0,60475],
  ["Atomium 008",670,2660,70,441,0,70525],
  ["Atomium 009",770,3430,80,503,0,82075],
  ["Atomium 010",880,4310,90,584,0,95275],
  ["Atomium 011",902,5212,100,578,0,108805],
  ["Atomium 012",925,6137,115,544,0,122680],
  ["Atomium 013",948,7085,125,538,0,136900],
  ["Atomium 014",972,8057,135,524,0,151480],
  ["Atomium 015",996,9053,150,510,0,166420],
  ["Atomium 016",1021,10074,160,507,0,181735],
  ["Atomium 017",1047,11121,175,467,0,197440],
  ["Atomium 018",1073,12194,185,464,0,213535],
  ["Atomium 019",1099,13293,200,443,0,230020],
  ["Atomium 020",1127,14420,210,442,0,246925],
  ["Atomium 021",1155,15575,225,413,0,264250],
  ["Atomium 022",1184,16759,240,405,0,282010],
  ["Atomium 023",1214,17973,250,406,0,300220],
  ["Atomium 024",1244,19217,265,378,0,318880],
  ["Atomium 025",1275,20492,280,372,0,338005],
  ["Atomium 026",1307,21799,290,346,0,357610],
  ["Atomium 027",1340,23139,305,331,0,377710],
  ["Atomium 028",1373,24512,320,317,0,398305],
  ["Atomium 029",1407,25919,335,303,0,419410],
  ["Atomium 030",1442,27361,345,300,0,441040],
  ["Atomium 031",1479,28840,360,300,0,463225],
  ["Atomium 032",1515,30355,375,278,0,485950],
  ["Atomium 033",1553,31908,390,278,0,509245],
  ["Atomium 034",1592,33500,405,251,0,533125],
  ["Atomium 035",1632,35132,420,254,0,557605],
  ["Atomium 036",1673,36805,430,266,0,582700],
  ["Atomium 037",1715,38520,450,241,0,608425],
  ["Atomium 038",1757,40277,460,255,0,634780],
  ["Atomium 039",1801,42078,475,242,0,661795],
  ["Atomium 040",1846,43924,490,249,0,689485],
  ["Atomium 041",1893,45817,505,238,0,717880],
  ["Atomium 042",1940,47757,520,248,0,746980],
  ["Atomium 043",1988,49745,535,229,0,776800],
  ["Atomium 044",2038,51783,550,241,0,807370],
  ["Atomium 045",2089,53872,565,234,0,838705],
  ["Atomium 046",2141,56013,580,249,0,870820],
  ["Atomium 047",2195,58208,600,237,0,903745],
  ["Atomium 048",2250,60458,615,234,0,937495],
  ["Atomium 049",2306,62764,630,252,0,972085],
  ["Atomium 050",2363,65127,645,243,0,1007530],
  ["Atomium 051",2422,67549,660,265,0,1043860],
  ["Atomium 052",2483,70032,675,268,0,1081105],
  ["Atomium 053",2545,72577,690,292,0,1119280],
  ["Atomium 054",2609,75186,705,299,0,1158415],
  ["Atomium 055",2674,77860,725,307,0,1198525],
  ["Atomium 056",2741,80601,740,327,0,1239640],
  ["Atomium 057",2809,83410,755,347,0,1281775],
  ["Atomium 058",2879,86289,770,360,0,1324960],
  ["Atomium 059",2951,89240,785,384,0,1369225],
  ["Atomium 060",3025,92265,800,411,0,1414600],
  ["Atomium 061",3101,95366,820,430,0,1461115],
  ["Atomium 062",3178,98544,835,450,0,1508785],
  ["Atomium 063",3258,101802,850,492,0,1557655],
  ["Atomium 064",3339,105141,870,506,0,1607740],
  ["Atomium 065",3423,108564,885,515,0,1659085],
  ["Atomium 066",3508,112072,900,563,0,1711705],
  ["Atomium 067",3596,115668,915,593,0,1765645],
  ["Atomium 068",3686,119354,935,626,0,1820935],
  ["Atomium 069",3778,123132,950,671,0,1877605],
  ["Atomium 070",3872,127004,965,717,0,1935685],
  ["Atomium 071",3969,130973,985,747,0,1995220],
  ["Atomium 072",4068,135041,1000,809,0,2056240],
  ["Atomium 073",4170,139211,1015,844,0,2118790],
  ["Atomium 074",4274,143485,1035,881,0,2182900],
  ["Atomium 075",4381,147866,1050,950,0,2248615],
  ["Atomium 076",4491,152357,1065,1003,0,2315980],
  ["Atomium 077",4603,156960,1085,1058,0,2385025],
  ["Atomium 078",4718,161678,1100,1126,0,2455795],
  ["Atomium 079",4836,166514,1120,1187,0,2528335],
  ["Atomium 080",4957,171471,1135,1242,0,2602690],
  ["Atomium 081",5081,176552,1150,1328,0,2678905],
  ["Atomium 082",5208,181760,1170,1388,0,2757025],
  ["Atomium 083",5338,187098,1185,1461,0,2837095],
  ["Atomium 084",5471,192569,1205,1537,0,2919160],
  ["Atomium 085",5608,198177,1220,1627,0,3003280],
  ["Atomium 086",5748,203925,1240,1710,0,3089500],
  ["Atomium 087",5892,209817,1255,1787,0,3177880],
  ["Atomium 088",6039,215856,1275,1867,0,3268465],
  ["Atomium 089",6190,222046,1290,1980,0,3361315],
  ["Atomium 090",6345,228391,1310,2069,0,3456490],
  ["Atomium 091",6504,234895,1325,2180,0,3554050],
  ["Atomium 092",6666,241561,1345,2275,0,3654040],
  ["Atomium 093",6833,248394,1360,2405,0,3756535],
  ["Atomium 094",7004,255398,1380,2501,0,3861595],
  ["Atomium 095",7179,262577,1395,2618,0,3969280],
  ["Atomium 096",7358,269935,1415,2740,0,4079650],
  ["Atomium 097",7542,277477,1430,2877,0,4192780],
  ["Atomium 098",7731,285208,1450,3009,0,4308745],
  ["Atomium 099",7924,293132,1470,3135,0,4427605],
  ["Atomium 100",8122,301254,1485,3256,0,4549435],
  ["Atomium 101",8325,309579,1505,3402,0,4674310],
  ["Basilius-Kathedrale 000",0,0,0,0,0,5250],
  ["Basilius-Kathedrale 001",50,50,5,30,0,6000],
  ["Basilius-Kathedrale 002",80,130,10,51,0,7200],
  ["Basilius-Kathedrale 003",140,270,15,82,0,9300],
  ["Basilius-Kathedrale 004",210,480,25,123,0,12450],
  ["Basilius-Kathedrale 005",290,770,30,194,0,16800],
  ["Basilius-Kathedrale 006",360,1130,40,236,0,22200],
  ["Basilius-Kathedrale 007",440,1570,45,277,0,28800],
  ["Basilius-Kathedrale 008",530,2100,55,339,0,36750],
  ["Basilius-Kathedrale 009",610,2710,65,390,0,45900],
  ["Basilius-Kathedrale 010",700,3410,70,471,0,56400],
  ["Basilius-Kathedrale 011",718,4128,80,451,0,67170],
  ["Basilius-Kathedrale 012",736,4864,90,440,0,78210],
  ["Basilius-Kathedrale 013",754,5618,100,430,0,89520],
  ["Basilius-Kathedrale 014",773,6391,110,411,0,101115],
  ["Basilius-Kathedrale 015",792,7183,120,402,0,112995],
  ["Basilius-Kathedrale 016",812,7995,125,402,0,125175],
  ["Basilius-Kathedrale 017",833,8828,140,376,0,137670],
  ["Basilius-Kathedrale 018",853,9681,150,367,0,150465],
  ["Basilius-Kathedrale 019",875,10556,155,370,0,163590],
  ["Basilius-Kathedrale 020",897,11453,170,336,0,177045],
  ["Basilius-Kathedrale 021",919,12372,180,330,0,190830],
  ["Basilius-Kathedrale 022",942,13314,190,324,0,204960],
  ["Basilius-Kathedrale 023",965,14279,200,309,0,219435],
  ["Basilius-Kathedrale 024",990,15269,210,305,0,234285],
  ["Basilius-Kathedrale 025",1014,16283,220,301,0,249495],
  ["Basilius-Kathedrale 026",1040,17323,230,289,0,265095],
  ["Basilius-Kathedrale 027",1066,18389,240,287,0,281085],
  ["Basilius-Kathedrale 028",1092,19481,250,284,0,297465],
  ["Basilius-Kathedrale 029",1120,20601,265,254,0,314265],
  ["Basilius-Kathedrale 030",1148,21749,275,254,0,331485],
  ["Basilius-Kathedrale 031",1176,22925,285,224,0,349125],
  ["Basilius-Kathedrale 032",1206,24131,295,226,0,367215],
  ["Basilius-Kathedrale 033",1236,25367,310,218,0,385755],
  ["Basilius-Kathedrale 034",1267,26634,320,211,0,404760],
  ["Basilius-Kathedrale 035",1298,27932,330,213,0,424230],
  ["Basilius-Kathedrale 036",1331,29263,340,218,0,444195],
  ["Basilius-Kathedrale 037",1364,30627,355,194,0,464655],
  ["Basilius-Kathedrale 038",1398,32025,365,199,0,485625],
  ["Basilius-Kathedrale 039",1433,33458,375,196,0,507120],
  ["Basilius-Kathedrale 040",1469,34927,390,194,0,529155],
  ["Basilius-Kathedrale 041",1506,36433,400,203,0,551745],
  ["Basilius-Kathedrale 042",1543,37976,410,193,0,574890],
  ["Basilius-Kathedrale 043",1582,39558,425,184,0,598620],
  ["Basilius-Kathedrale 044",1621,41179,435,185,0,622935],
  ["Basilius-Kathedrale 045",1662,42841,450,188,0,647865],
  ["Basilius-Kathedrale 046",1703,44544,460,201,0,673410],
  ["Basilius-Kathedrale 047",1746,46290,470,206,0,699600],
  ["Basilius-Kathedrale 048",1789,48079,485,201,0,726435],
  ["Basilius-Kathedrale 049",1834,49913,495,208,0,753945],
  ["Basilius-Kathedrale 050",1880,51793,510,216,0,782145],
  ["Basilius-Kathedrale 051",1927,53720,520,235,0,811050],
  ["Basilius-Kathedrale 052",1975,55695,535,216,0,840675],
  ["Basilius-Kathedrale 053",2025,57720,545,237,0,871050],
  ["Basilius-Kathedrale 054",2075,59795,560,240,0,902175],
  ["Basilius-Kathedrale 055",2127,61922,570,263,0,934080],
  ["Basilius-Kathedrale 056",2180,64102,585,259,0,966780],
  ["Basilius-Kathedrale 057",2235,66337,595,286,0,1000305],
  ["Basilius-Kathedrale 058",2291,68628,610,304,0,1034670],
  ["Basilius-Kathedrale 059",2348,70976,620,323,0,1069890],
  ["Basilius-Kathedrale 060",2406,73382,635,333,0,1105980],
  ["Basilius-Kathedrale 061",2467,75849,645,347,0,1142985],
  ["Basilius-Kathedrale 062",2528,78377,660,371,0,1180905],
  ["Basilius-Kathedrale 063",2591,80968,670,405,0,1219770],
  ["Basilius-Kathedrale 064",2656,83624,685,412,0,1259610],
  ["Basilius-Kathedrale 065",2723,86347,700,442,0,1300455],
  ["Basilius-Kathedrale 066",2791,89138,710,472,0,1342320],
  ["Basilius-Kathedrale 067",2860,91998,725,493,0,1385220],
  ["Basilius-Kathedrale 068",2932,94930,735,527,0,1429200],
  ["Basilius-Kathedrale 069",3005,97935,750,562,0,1474275],
  ["Basilius-Kathedrale 070",3080,101015,765,570,0,1520475],
  ["Basilius-Kathedrale 071",3157,104172,775,619,0,1567830],
  ["Basilius-Kathedrale 072",3236,107408,790,660,0,1616370],
  ["Basilius-Kathedrale 073",3317,110725,805,683,0,1666125],
  ["Basilius-Kathedrale 074",3400,114125,815,738,0,1717125],
  ["Basilius-Kathedrale 075",3485,117610,830,776,0,1769400],
  ["Basilius-Kathedrale 076",3572,121182,845,815,0,1822980],
  ["Basilius-Kathedrale 077",3661,124843,855,866,0,1877895],
  ["Basilius-Kathedrale 078",3753,128596,870,920,0,1934190],
  ["Basilius-Kathedrale 079",3847,132443,885,939,0,1991895],
  ["Basilius-Kathedrale 080",3943,136386,895,1007,0,2051040],
  ["Basilius-Kathedrale 081",4042,140428,910,1068,0,2111670],
  ["Basilius-Kathedrale 082",4143,144571,925,1111,0,2173815],
  ["Basilius-Kathedrale 083",4246,148817,935,1186,0,2237505],
  ["Basilius-Kathedrale 084",4352,153142,950,1245,0,2302380],
  ["Basilius-Kathedrale 085",4461,157603,965,1306,0,2369295],
  ["Basilius-Kathedrale 086",4573,162176,980,1371,0,2437890],
  ["Basilius-Kathedrale 087",4687,166863,990,1456,0,2508195],
  ["Basilius-Kathedrale 088",4804,171667,1005,1506,0,2580255],
  ["Basilius-Kathedrale 089",4924,176591,1020,1589,0,2654115],
  ["Basilius-Kathedrale 090",5047,181638,1035,1654,0,2729820],
  ["Basilius-Kathedrale 091",5173,186811,1045,1751,0,2807415],
  ["Basilius-Kathedrale 092",5303,192114,1060,1844,0,2886960],
  ["Basilius-Kathedrale 093",5435,197549,1075,1919,0,2968485],
  ["Basilius-Kathedrale 094",5571,203120,1090,2017,0,3052050],
  ["Basilius-Kathedrale 095",5710,208830,1105,2098,0,3137700],
  ["Basilius-Kathedrale 096",5853,214683,1115,2213,0,3225495],
  ["Basilius-Kathedrale 097",5999,220682,1130,2303,0,3315480],
  ["Basilius-Kathedrale 098",6149,226831,1145,2405,0,3407715],
  ["Basilius-Kathedrale 099",6303,233134,1160,2512,0,3502260],
  ["Basilius-Kathedrale 100",6461,239595,1175,2622,0,3599175],
  ["Basilius-Kathedrale 101",6622,246217,1185,2745,0,3698505],
  ["Basilius-Kathedrale 102",6788,253005,1200,2874,0,3800325],
  ["Basilius-Kathedrale 103",6957,259962,1215,2985,0,3904680],
  ["Basilius-Kathedrale 104",7131,267093,1230,3121,0,4011645],
  ["Basilius-Kathedrale 105",7310,274403,1245,3233,0,4121295],
  ["Basilius-Kathedrale 106",7492,281895,1260,3378,0,4233675],
  ["Basilius-Kathedrale 107",7680,289575,1275,3508,0,4348875],
  ["Basilius-Kathedrale 108",7872,297447,1285,3671,0,4466955],
  ["Basilius-Kathedrale 109",8068,305515,1300,3830,0,4587975],
  ["Basilius-Kathedrale 110",8270,313785,1315,3975,0,4712025],
  ["Basilius-Kathedrale 111",8477,322262,1330,4144,0,4839180],
  ["Basilius-Kathedrale 112",8689,330951,1345,4298,0,4969515],
  ["Basilius-Kathedrale 113",8906,339857,1360,4478,0,5103105],
  ["Basilius-Kathedrale 114",9129,348986,1375,4635,0,5240040],
  ["Basilius-Kathedrale 115",9357,358343,1390,4825,0,5380395],
  ["Basilius-Kathedrale 116",9591,367934,1405,5001,0,5524260],
  ["Basilius-Kathedrale 117",9830,377764,1415,5212,0,5671710],
  ["Basilius-Kathedrale 118",10076,387840,1430,5411,0,5822850],
  ["Basilius-Kathedrale 119",10328,398168,1445,5615,0,5977770],
  ["Basilius-Kathedrale 120",10586,408754,1460,5826,0,6136560],
  ["Basilius-Kathedrale 121",10851,419605,1475,6043,0,6299325],
  ["Basilius-Kathedrale 122",11122,430727,1490,6247,0,6466155],
  ["Basilius-Kathedrale 123",11400,442127,1505,6477,0,6637155],
  ["Basilius-Kathedrale 124",11685,453812,1520,6715,0,6812430],
  ["Basilius-Kathedrale 125",11977,465789,1535,6959,0,6992085],
  ["Basilius-Kathedrale 126",12277,478066,1550,7212,0,7176240],
  ["Basilius-Kathedrale 127",12584,490650,1565,7471,0,7365000],
  ["Basilius-Kathedrale 128",12898,503548,1580,7738,0,7558470],
  ["Basilius-Kathedrale 129",13221,516769,1595,8013,0,7756785],
  ["Basilius-Kathedrale 130",13551,530320,1610,8287,0,7960050],
  ["Basilius-Kathedrale 131",13890,544210,1625,8578,0,8168400],
  ["Basilius-Kathedrale 132",14237,558447,1640,8878,0,8381955],
  ["Basilius-Kathedrale 133",14593,573040,1655,9186,0,8600850],
  ["Basilius-Kathedrale 134",14958,587998,1670,9504,0,8825220],
  ["Burg Himeji 000",0,0,0,0,0,216850],
  ["Burg Himeji 001",90,90,10,61,0,218200],
  ["Burg Himeji 002",130,220,15,72,0,220150],
  ["Burg Himeji 003",240,460,25,153,0,223750],
  ["Burg Himeji 004",370,830,40,246,0,229300],
  ["Burg Himeji 005",490,1320,50,318,0,236650],
  ["Burg Himeji 006",630,1950,65,410,0,246100],
  ["Burg Himeji 007",770,2720,80,503,0,257650],
  ["Burg Himeji 008",910,3630,95,595,0,271300],
  ["Burg Himeji 009",1060,4690,110,698,0,287200],
  ["Burg Himeji 010",1210,5900,125,800,0,305350],
  ["Burg Himeji 011",1241,7141,140,784,0,323965],
  ["Burg Himeji 012",1272,8413,155,767,0,343045],
  ["Burg Himeji 013",1304,9717,170,743,0,362605],
  ["Burg Himeji 014",1336,11053,185,727,0,382645],
  ["Burg Himeji 015",1370,12423,205,694,0,403195],
  ["Burg Himeji 016",1404,13827,220,691,0,424255],
  ["Burg Himeji 017",1439,15266,235,669,0,445840],
  ["Burg Himeji 018",1475,16741,255,638,0,467965],
  ["Burg Himeji 019",1512,18253,270,637,0,490645],
  ["Burg Himeji 020",1549,19802,290,588,0,513880],
  ["Burg Himeji 021",1588,21390,305,579,0,537700],
  ["Burg Himeji 022",1628,23018,325,552,0,562120],
  ["Burg Himeji 023",1668,24686,345,526,0,587140],
  ["Burg Himeji 024",1710,26396,360,531,0,612790],
  ["Burg Himeji 025",1753,28149,380,507,0,639085],
  ["Burg Himeji 026",1797,29940,400,494,0,665950],
  ["Burg Himeji 027",1842,31782,415,473,0,693580],
  ["Burg Himeji 028",1888,33670,435,452,0,721900],
  ["Burg Himeji 029",1935,35605,455,442,0,750925],
  ["Burg Himeji 030",1983,37588,475,424,0,780670],
  ["Burg Himeji 031",2033,39621,495,407,0,811165],
  ["Burg Himeji 032",2084,41705,510,420,0,842425],
  ["Burg Himeji 033",2136,43841,530,396,0,874465],
  ["Burg Himeji 034",2189,46030,550,392,0,907300],
  ["Burg Himeji 035",2244,48274,570,380,0,940960],
  ["Burg Himeji 036",2300,50574,590,370,0,975460],
  ["Burg Himeji 037",2357,52931,610,370,0,1010815],
  ["Burg Himeji 038",2416,55347,630,362,0,1047055],
  ["Burg Himeji 039",2477,57824,650,348,0,1084210],
  ["Burg Himeji 040",2539,60363,670,353,0,1122295],
  ["Burg Himeji 041",2602,62965,690,349,0,1161325],
  ["Burg Himeji 042",2667,65632,715,329,0,1201330],
  ["Burg Himeji 043",2734,68366,735,329,0,1242340],
  ["Burg Himeji 044",2802,71168,755,340,0,1284370],
  ["Burg Himeji 045",2872,74040,775,334,0,1327450],
  ["Burg Himeji 046",2944,76984,795,339,0,1371610],
  ["Burg Himeji 047",3017,80001,815,355,0,1416865],
  ["Burg Himeji 048",3093,83094,840,356,0,1463260],
  ["Burg Himeji 049",3170,86264,860,366,0,1510810],
  ["Burg Himeji 050",3249,89513,880,388,0,1559545],
  ["Burg Himeji 051",3331,92844,900,386,0,1609510],
  ["Burg Himeji 052",3414,96258,925,382,0,1660720],
  ["Burg Himeji 053",3499,99757,945,401,0,1713205],
  ["Burg Himeji 054",3587,103344,965,432,0,1767010],
  ["Burg Himeji 055",3676,107020,990,445,0,1822150],
  ["Burg Himeji 056",3768,110788,1010,461,0,1878670],
  ["Burg Himeji 057",3862,114650,1030,498,0,1936600],
  ["Burg Himeji 058",3959,118609,1055,509,0,1995985],
  ["Burg Himeji 059",4058,122667,1075,542,0,2056855],
  ["Burg Himeji 060",4159,126826,1095,576,0,2119240],
  ["Burg Himeji 061",4263,131089,1120,614,0,2183185],
  ["Burg Himeji 062",4370,135459,1140,646,0,2248735],
  ["Burg Himeji 063",4479,139938,1165,668,0,2315920],
  ["Burg Himeji 064",4591,144529,1185,714,0,2384785],
  ["Burg Himeji 065",4706,149235,1210,763,0,2455375],
  ["Burg Himeji 066",4824,154059,1230,814,0,2527735],
  ["Burg Himeji 067",4944,159003,1255,839,0,2601895],
  ["Burg Himeji 068",5068,164071,1275,896,0,2677915],
  ["Burg Himeji 069",5194,169265,1300,956,0,2755825],
  ["Burg Himeji 070",5324,174589,1320,1020,0,2835685],
  ["Burg Himeji 071",5457,180046,1345,1066,0,2917540],
  ["Burg Himeji 072",5594,185640,1365,1128,0,3001450],
  ["Burg Himeji 073",5734,191374,1390,1202,0,3087460],
  ["Burg Himeji 074",5877,197251,1415,1259,0,3175615],
  ["Burg Himeji 075",6024,203275,1435,1340,0,3265975],
  ["Burg Himeji 076",6174,209449,1460,1414,0,3358585],
  ["Burg Himeji 077",6329,215778,1485,1463,0,3453520],
  ["Burg Himeji 078",6487,222265,1505,1564,0,3550825],
  ["Burg Himeji 079",6649,228914,1530,1650,0,3650560],
  ["Burg Himeji 080",6815,235729,1555,1731,0,3752785],
  ["Burg Himeji 081",6986,242715,1575,1835,0,3857575],
  ["Burg Himeji 082",7160,249875,1600,1943,0,3964975],
  ["Burg Himeji 083",7339,257214,1625,2027,0,4075060],
  ["Burg Himeji 084",7523,264737,1645,2144,0,4187905],
  ["Burg Himeji 085",7711,272448,1670,2257,0,4303570],
  ["Burg Himeji 086",7904,280352,1695,2364,0,4422130],
  ["Burg Himeji 087",8101,288453,1720,2495,0,4543645],
  ["Burg Himeji 088",8304,296757,1745,2602,0,4668205],
  ["Burg Himeji 089",8511,305268,1765,2742,0,4795870],
  ["Burg Himeji 090",8724,313992,1790,2880,0,4926730],
  ["Burg Himeji 091",8942,322934,1815,3012,0,5060860],
  ["Burg Himeji 092",9166,332100,1840,3170,0,5198350],
  ["Burg Himeji 093",9395,341495,1860,3324,0,5339275],
  ["Burg Himeji 094",9630,351125,1885,3472,0,5483725],
  ["Burg Himeji 095",9870,360995,1910,3637,0,5631775],
  ["Burg Himeji 096",10117,371112,1935,3798,0,5783530],
  ["Burg Himeji 097",10370,381482,1960,3985,0,5939080],
  ["Burg Himeji 098",10629,392111,1985,4148,0,6098515],
  ["Burg Himeji 099",10895,403006,2010,4338,0,6261940],
  ["Burg Himeji 100",11167,414173,2030,4544,0,6429445],
  ["Burg Himeji 101",11447,425620,2055,4738,0,6601150],
  ["Burg Himeji 102",11733,437353,2080,4958,0,6777145],
  ["Burg Himeji 103",12026,449379,2105,5147,0,6957535],
  ["Burg Himeji 104",12327,461706,2130,5372,0,7142440],
  ["Burg Himeji 105",12635,474341,2155,5595,0,7331965],
  ["Burg Himeji 106",12951,487292,2180,5835,0,7526230],
  ["Burg Himeji 107",13274,500566,2205,6062,0,7725340],
  ["Burg Himeji 108",13606,514172,2230,6328,0,7929430],
  ["Burg Himeji 109",13946,528118,2255,6582,0,8138620],
  ["Burg Himeji 110",14295,542413,2280,6856,0,8353045],
  ["Burg Himeji 111",14652,557065,2305,7126,0,8572825],
  ["Burg Himeji 112",15019,572084,2330,7409,0,8798110],
  ["Cape Canaveral 000",0,0,0,0,0,29875],
  ["Cape Canaveral 001",60,60,10,31,2,30775],
  ["Cape Canaveral 002",100,160,10,71,2,32275],
  ["Cape Canaveral 003",180,340,20,113,3,34975],
  ["Cape Canaveral 004",270,610,30,174,4,39025],
  ["Cape Canaveral 005",360,970,40,236,5,44425],
  ["Cape Canaveral 006",460,1430,50,288,6,51325],
  ["Cape Canaveral 007",560,1990,60,360,6,59725],
  ["Cape Canaveral 008",670,2660,75,412,7,69775],
  ["Cape Canaveral 009",770,3430,85,483,8,81325],
  ["Cape Canaveral 010",880,4310,95,565,10,94525],
  ["Cape Canaveral 011",902,5212,110,540,11,108055],
  ["Cape Canaveral 012",925,6137,120,535,12,121930],
  ["Cape Canaveral 013",948,7085,130,529,13,136150],
  ["Cape Canaveral 014",972,8057,145,495,14,150730],
  ["Cape Canaveral 015",996,9053,155,491,15,165670],
  ["Cape Canaveral 016",1021,10074,170,460,16,180985],
  ["Cape Canaveral 017",1047,11121,185,438,17,196690],
  ["Cape Canaveral 018",1073,12194,195,426,18,212785],
  ["Cape Canaveral 019",1099,13293,210,414,19,229270],
  ["Cape Canaveral 020",1127,14420,225,385,20,246175],
  ["Cape Canaveral 021",1155,15575,235,385,21,263500],
  ["Cape Canaveral 022",1184,16759,250,376,22,281260],
  ["Cape Canaveral 023",1214,17973,265,348,23,299470],
  ["Cape Canaveral 024",1244,19217,280,341,24,318130],
  ["Cape Canaveral 025",1275,20492,295,295,25,337255],
  ["Cape Canaveral 026",1307,21799,305,298,26,356860],
  ["Cape Canaveral 027",1340,23139,320,284,27,376960],
  ["Cape Canaveral 028",1373,24512,335,269,28,397555],
  ["Cape Canaveral 029",1407,25919,350,256,29,418660],
  ["Cape Canaveral 030",1442,27361,365,243,30,440290],
  ["Cape Canaveral 031",1479,28840,380,233,31,462475],
  ["Cape Canaveral 032",1515,30355,395,221,32,485200],
  ["Cape Canaveral 033",1553,31908,410,203,33,508495],
  ["Cape Canaveral 034",1592,33500,425,194,34,532375],
  ["Cape Canaveral 035",1632,35132,440,187,35,556855],
  ["Cape Canaveral 036",1673,36805,455,180,36,581950],
  ["Cape Canaveral 037",1715,38520,470,175,37,607675],
  ["Cape Canaveral 038",1757,40277,485,169,38,634030],
  ["Cape Canaveral 039",1801,42078,500,166,39,661045],
  ["Cape Canaveral 040",1846,43924,515,163,40,688735],
  ["Cape Canaveral 041",1893,45817,535,134,41,717130],
  ["Cape Canaveral 042",1940,47757,550,143,42,746230],
  ["Cape Canaveral 043",1988,49745,565,133,43,776050],
  ["Cape Canaveral 044",2038,51783,580,146,44,806620],
  ["Cape Canaveral 045",2089,53872,595,140,45,837955],
  ["Cape Canaveral 046",2141,56013,615,125,46,870070],
  ["Cape Canaveral 047",2195,58208,630,141,47,902995],
  ["Cape Canaveral 048",2250,60458,645,130,48,936745],
  ["Cape Canaveral 049",2306,62764,660,149,49,971335],
  ["Cape Canaveral 050",2363,65127,675,148,50,1006780],
  ["Cape Canaveral 051",2422,67549,695,150,51,1043110],
  ["Cape Canaveral 052",2483,70032,710,164,52,1080355],
  ["Cape Canaveral 053",2545,72577,725,178,53,1118530],
  ["Cape Canaveral 054",2609,75186,745,175,54,1157665],
  ["Cape Canaveral 055",2674,77860,760,203,55,1197775],
  ["Cape Canaveral 056",2741,80601,775,203,56,1238890],
  ["Cape Canaveral 057",2809,83410,795,204,57,1281025],
  ["Cape Canaveral 058",2879,86289,810,236,58,1324210],
  ["Cape Canaveral 059",2951,89240,830,242,59,1368475],
  ["Cape Canaveral 060",3025,92265,845,268,60,1413850],
  ["Cape Canaveral 061",3101,95366,860,297,61,1460365],
  ["Cape Canaveral 062",3178,98544,880,317,62,1508035],
  ["Cape Canaveral 063",3258,101802,895,322,63,1556905],
  ["Cape Canaveral 064",3339,105141,915,336,64,1606990],
  ["Cape Canaveral 065",3423,108564,930,382,65,1658335],
  ["Cape Canaveral 066",3508,112072,945,410,66,1710955],
  ["Cape Canaveral 067",3596,115668,965,441,67,1764895],
  ["Cape Canaveral 068",3686,119354,985,464,68,1820185],
  ["Cape Canaveral 069",3778,123132,1000,519,69,1876855],
  ["Cape Canaveral 070",3872,127004,1020,537,70,1934935],
  ["Cape Canaveral 071",3969,130973,1035,576,71,1994470],
  ["Cape Canaveral 072",4068,135041,1050,637,72,2055490],
  ["Cape Canaveral 073",4170,139211,1070,673,73,2118040],
  ["Cape Canaveral 074",4274,143485,1090,720,74,2182150],
  ["Cape Canaveral 075",4381,147866,1105,769,75,2247865],
  ["Cape Canaveral 076",4491,152357,1125,804,76,2315230],
  ["Cape Canaveral 077",4603,156960,1140,879,77,2384275],
  ["Cape Canaveral 078",4718,161678,1160,927,78,2455045],
  ["Cape Canaveral 079",4836,166514,1175,997,79,2527585],
  ["Cape Canaveral 080",4957,171471,1195,1052,80,2601940],
  ["Cape Canaveral 081",5081,176552,1215,1109,81,2678155],
  ["Cape Canaveral 082",5208,181760,1230,1198,82,2756275],
  ["Cape Canaveral 083",5338,187098,1250,1252,83,2836345],
  ["Cape Canaveral 084",5471,192569,1265,1337,84,2918410],
  ["Cape Canaveral 085",5608,198177,1285,1407,85,3002530],
  ["Cape Canaveral 086",5748,203925,1305,1481,86,3088750],
  ["Cape Canaveral 087",5892,209817,1320,1588,87,3177130],
  ["Cape Canaveral 088",6039,215856,1340,1668,88,3267715],
  ["Cape Canaveral 089",6190,222046,1360,1762,89,3360565],
  ["Cape Canaveral 090",6345,228391,1375,1851,90,3455740],
  ["Cape Canaveral 091",6504,234895,1395,1943,91,3553300],
  ["Cape Canaveral 092",6666,241561,1415,2048,92,3653290],
  ["Cape Canaveral 093",6833,248394,1435,2149,93,3755785],
  ["Cape Canaveral 094",7004,255398,1450,2282,94,3860845],
  ["Cape Canaveral 095",7179,262577,1470,2390,95,3968530],
  ["Cape Canaveral 096",7358,269935,1490,2483,96,4078900],
  ["Cape Canaveral 097",7542,277477,1510,2610,97,4192030],
  ["Cape Canaveral 098",7731,285208,1525,2741,98,4307995],
  ["Cape Canaveral 099",7924,293132,1545,2868,99,4426855],
  ["Cape Canaveral 100",8122,301254,1565,3009,100,4548685],
  ["Cape Canaveral 101",8325,309579,1585,3145,101,4673560],
  ["Cape Canaveral 102",8533,318112,1600,3316,102,4801555],
  ["Cape Canaveral 103",8746,326858,1620,3454,103,4932745],
  ["Cape Canaveral 104",8965,335823,1640,3606,104,5067220],
  ["Cape Canaveral 105",9189,345012,1660,3773,105,5205055],
  ["Cape Canaveral 106",9419,354431,1680,3937,106,5346340],
  ["Cape Canaveral 107",9654,364085,1695,4114,107,5491150],
  ["Cape Canaveral 108",9896,373981,1715,4299,108,5639590],
  ["Cape Canaveral 109",10143,384124,1735,4470,109,5791735],
  ["Cape Canaveral 110",10397,394503,1755,4657,110,5947420],
  ["Cape Canaveral 111",10656,405159,1775,4859,111,6107260],
  ["Cape Canaveral 112",10923,416082,1790,5079,112,6271105],
  ["Cape Canaveral 113",11196,427278,1810,5295,113,6439045],
  ["Cape Canaveral 114",11476,438754,1830,5508,114,6611185],
  ["Cape Canaveral 115",11763,450517,1850,5720,115,6787630],
  ["Cape Canaveral 116",12057,462574,1870,5957,116,6968485],
  ["Cape Canaveral 117",12358,474932,1890,6191,117,7153855],
  ["Cape Canaveral 118",12667,487599,1910,6434,118,7343860],
  ["Cape Canaveral 119",12984,500583,1930,6694,119,7538620],
  ["Cape Canaveral 120",13308,513891,1950,6951,120,7738240],
  ["Cape Canaveral 121",13641,527532,1965,7217,121,7942855],
  ["Cape Canaveral 122",13982,541514,1985,7501,122,8152585],
  ["Cape Canaveral 123",14332,555846,2005,7784,123,8367565],
  ["Cape Canaveral 124",14690,570536,2025,8076,124,8587915],
  ["Cape Canaveral 125",15057,585593,2045,8386,125,8813770],
  ["Cape Canaveral 126",15434,601027,2065,8696,126,9045280],
  ["Cape Canaveral 127",15819,616846,2085,8997,127,9282565],
  ["Cape Canaveral 128",16215,633061,2105,9336,128,9525790],
  ["Cape Canaveral 129",16620,649681,2125,9674,129,9775090],
  ["Cape Canaveral 130",17036,666717,2145,10024,130,10030630],
  ["Cape Canaveral 131",17462,684179,2165,10393,131,10292560],
  ["Cape Canaveral 132",17898,702077,2185,10762,132,10561030],
  ["Cape Canaveral 133",18346,720423,2205,11134,133,10836220],
  ["Cape Canaveral 134",18804,739227,2225,11535,134,11118280],
  ["Cape Canaveral 135",19274,758501,2245,11938,135,11407390],
  ["Cape Canaveral 136",19756,778257,2265,12354,136,11703730],
  ["Castel del Monte 000",0,0,0,0,0,5250],
  ["Castel del Monte 001",50,50,5,30,1,6000],
  ["Castel del Monte 002",80,130,10,51,1,7200],
  ["Castel del Monte 003",140,270,15,82,2,9300],
  ["Castel del Monte 004",210,480,25,123,2,12450],
  ["Castel del Monte 005",290,770,30,194,3,16800],
  ["Castel del Monte 006",360,1130,40,236,3,22200],
  ["Castel del Monte 007",440,1570,45,277,4,28800],
  ["Castel del Monte 008",530,2100,55,339,4,36750],
  ["Castel del Monte 009",610,2710,65,390,5,45900],
  ["Castel del Monte 010",700,3410,70,471,6,56400],
  ["Castel del Monte 011",718,4128,80,451,6,67170],
  ["Castel del Monte 012",736,4864,90,440,7,78210],
  ["Castel del Monte 013",754,5618,100,430,7,89520],
  ["Castel del Monte 014",773,6391,110,411,8,101115],
  ["Castel del Monte 015",792,7183,120,402,9,112995],
  ["Castel del Monte 016",812,7995,125,402,9,125175],
  ["Castel del Monte 017",833,8828,140,376,10,137670],
  ["Castel del Monte 018",853,9681,150,367,10,150465],
  ["Castel del Monte 019",875,10556,155,370,11,163590],
  ["Castel del Monte 020",897,11453,170,336,12,177045],
  ["Castel del Monte 021",919,12372,180,330,12,190830],
  ["Castel del Monte 022",942,13314,190,324,13,204960],
  ["Castel del Monte 023",965,14279,200,309,13,219435],
  ["Castel del Monte 024",990,15269,210,305,14,234285],
  ["Castel del Monte 025",1014,16283,220,301,15,249495],
  ["Castel del Monte 026",1040,17323,230,289,15,265095],
  ["Castel del Monte 027",1066,18389,240,287,16,281085],
  ["Castel del Monte 028",1092,19481,250,284,16,297465],
  ["Castel del Monte 029",1120,20601,265,254,17,314265],
  ["Castel del Monte 030",1148,21749,275,254,18,331485],
  ["Castel del Monte 031",1176,22925,285,224,18,349125],
  ["Castel del Monte 032",1206,24131,295,226,19,367215],
  ["Castel del Monte 033",1236,25367,310,218,19,385755],
  ["Castel del Monte 034",1267,26634,320,211,20,404760],
  ["Castel del Monte 035",1298,27932,330,213,21,424230],
  ["Castel del Monte 036",1331,29263,340,218,21,444195],
  ["Castel del Monte 037",1364,30627,355,194,22,464655],
  ["Castel del Monte 038",1398,32025,365,199,22,485625],
  ["Castel del Monte 039",1433,33458,375,196,23,507120],
  ["Castel del Monte 040",1469,34927,390,194,24,529155],
  ["Castel del Monte 041",1506,36433,400,203,24,551745],
  ["Castel del Monte 042",1543,37976,410,193,25,574890],
  ["Castel del Monte 043",1582,39558,425,184,25,598620],
  ["Castel del Monte 044",1621,41179,435,185,26,622935],
  ["Castel del Monte 045",1662,42841,450,188,27,647865],
  ["Castel del Monte 046",1703,44544,460,201,27,673410],
  ["Castel del Monte 047",1746,46290,470,206,28,699600],
  ["Castel del Monte 048",1789,48079,485,201,28,726435],
  ["Castel del Monte 049",1834,49913,495,208,29,753945],
  ["Castel del Monte 050",1880,51793,510,216,30,782145],
  ["Castel del Monte 051",1927,53720,520,235,30,811050],
  ["Castel del Monte 052",1975,55695,535,216,31,840675],
  ["Castel del Monte 053",2025,57720,545,237,31,871050],
  ["Castel del Monte 054",2075,59795,560,240,32,902175],
  ["Castel del Monte 055",2127,61922,570,263,33,934080],
  ["Castel del Monte 056",2180,64102,585,259,33,966780],
  ["Castel del Monte 057",2235,66337,595,286,34,1000305],
  ["Castel del Monte 058",2291,68628,610,304,34,1034670],
  ["Castel del Monte 059",2348,70976,620,323,35,1069890],
  ["Castel del Monte 060",2406,73382,635,333,36,1105980],
  ["Castel del Monte 061",2467,75849,645,347,36,1142985],
  ["Castel del Monte 062",2528,78377,660,371,37,1180905],
  ["Castel del Monte 063",2591,80968,670,405,37,1219770],
  ["Castel del Monte 064",2656,83624,685,412,38,1259610],
  ["Castel del Monte 065",2723,86347,700,442,39,1300455],
  ["Castel del Monte 066",2791,89138,710,472,39,1342320],
  ["Castel del Monte 067",2860,91998,725,493,40,1385220],
  ["Castel del Monte 068",2932,94930,735,527,40,1429200],
  ["Castel del Monte 069",3005,97935,750,562,41,1474275],
  ["Castel del Monte 070",3080,101015,765,570,42,1520475],
  ["Castel del Monte 071",3157,104172,775,619,42,1567830],
  ["Castel del Monte 072",3236,107408,790,660,43,1616370],
  ["Castel del Monte 073",3317,110725,805,683,43,1666125],
  ["Castel del Monte 074",3400,114125,815,738,44,1717125],
  ["Castel del Monte 075",3485,117610,830,776,45,1769400],
  ["Castel del Monte 076",3572,121182,845,815,45,1822980],
  ["Castel del Monte 077",3661,124843,855,866,46,1877895],
  ["Castel del Monte 078",3753,128596,870,920,46,1934190],
  ["Castel del Monte 079",3847,132443,885,939,47,1991895],
  ["Castel del Monte 080",3943,136386,895,1007,48,2051040],
  ["Castel del Monte 081",4042,140428,910,1068,48,2111670],
  ["Castel del Monte 082",4143,144571,925,1111,49,2173815],
  ["Castel del Monte 083",4246,148817,935,1186,49,2237505],
  ["Castel del Monte 084",4352,153142,950,1245,50,2302380],
  ["Castel del Monte 085",4461,157603,965,1306,51,2369295],
  ["Castel del Monte 086",4573,162176,980,1371,51,2437890],
  ["Castel del Monte 087",4687,166863,990,1456,52,2508195],
  ["Castel del Monte 088",4804,171667,1005,1506,52,2580255],
  ["Castel del Monte 089",4924,176591,1020,1589,53,2654115],
  ["Castel del Monte 090",5047,181638,1035,1654,54,2729820],
  ["Castel del Monte 091",5173,186811,1045,1751,54,2807415],
  ["Castel del Monte 092",5303,192114,1060,1844,55,2886960],
  ["Castel del Monte 093",5435,197549,1075,1919,55,2968485],
  ["Castel del Monte 094",5571,203120,1090,2017,56,3052050],
  ["Castel del Monte 095",5710,208830,1105,2098,57,3137700],
  ["Castel del Monte 096",5853,214683,1115,2213,57,3225495],
  ["Castel del Monte 097",5999,220682,1130,2303,58,3315480],
  ["Castel del Monte 098",6149,226831,1145,2405,58,3407715],
  ["Castel del Monte 099",6303,233134,1160,2512,59,3502260],
  ["Castel del Monte 100",6461,239595,1175,2622,60,3599175],
  ["Castel del Monte 101",6622,246217,1185,2745,60,3698505],
  ["Castel del Monte 102",6788,253005,1200,2874,61,3800325],
  ["Castel del Monte 103",6957,259962,1215,2985,61,3904680],
  ["Castel del Monte 104",7131,267093,1230,3121,62,4011645],
  ["Castel del Monte 105",7310,274403,1245,3233,63,4121295],
  ["Castel del Monte 106",7492,281895,1260,3378,63,4233675],
  ["Castel del Monte 107",7680,289575,1275,3508,64,4348875],
  ["Castel del Monte 108",7872,297447,1285,3671,64,4466955],
  ["Castel del Monte 109",8068,305515,1300,3830,65,4587975],
  ["Castel del Monte 110",8270,313785,1315,3975,66,4712025],
  ["Castel del Monte 111",8477,322262,1330,4144,66,4839180],
  ["Castel del Monte 112",8689,330951,1345,4298,67,4969515],
  ["Castel del Monte 113",8906,339857,1360,4478,67,5103105],
  ["Castel del Monte 114",9129,348986,1375,4635,68,5240040],
  ["Castel del Monte 115",9357,358343,1390,4825,69,5380395],
  ["Castel del Monte 116",9591,367934,1405,5001,69,5524260],
  ["Castel del Monte 117",9830,377764,1415,5212,70,5671710],
  ["Castel del Monte 118",10076,387840,1430,5411,70,5822850],
  ["Castel del Monte 119",10328,398168,1445,5615,71,5977770],
  ["Castel del Monte 120",10586,408754,1460,5826,72,6136560],
  ["Castel del Monte 121",10851,419605,1475,6043,72,6299325],
  ["Castel del Monte 122",11122,430727,1490,6247,73,6466155],
  ["Castel del Monte 123",11400,442127,1505,6477,73,6637155],
  ["Castel del Monte 124",11685,453812,1520,6715,74,6812430],
  ["Castel del Monte 125",11977,465789,1535,6959,75,6992085],
  ["Castel del Monte 126",12277,478066,1550,7212,75,7176240],
  ["Castel del Monte 127",12584,490650,1565,7471,76,7365000],
  ["Castel del Monte 128",12898,503548,1580,7738,76,7558470],
  ["Castel del Monte 129",13221,516769,1595,8013,77,7756785],
  ["Castel del Monte 130",13551,530320,1610,8287,78,7960050],
  ["Castel del Monte 131",13890,544210,1625,8578,78,8168400],
  ["Castel del Monte 132",14237,558447,1640,8878,79,8381955],
  ["Castel del Monte 133",14593,573040,1655,9186,79,8600850],
  ["Castel del Monte 134",14958,587998,1670,9504,80,8825220],
  ["Chateau Frontenac 000",0,0,0,0,0,15000],
  ["Chateau Frontenac 001",60,60,10,31,0,15900],
  ["Chateau Frontenac 002",90,150,10,61,0,17250],
  ["Chateau Frontenac 003",170,320,20,103,0,19800],
  ["Chateau Frontenac 004",260,580,30,164,0,23700],
  ["Chateau Frontenac 005",340,920,35,225,0,28800],
  ["Chateau Frontenac 006",440,1360,45,277,0,35400],
  ["Chateau Frontenac 007",530,1890,55,339,0,43350],
  ["Chateau Frontenac 008",630,2520,65,410,0,52800],
  ["Chateau Frontenac 009",740,3260,75,482,0,63900],
  ["Chateau Frontenac 010",830,4090,85,543,0,76350],
  ["Chateau Frontenac 011",851,4941,95,536,0,89115],
  ["Chateau Frontenac 012",873,5814,105,520,0,102210],
  ["Chateau Frontenac 013",894,6708,120,504,0,115620],
  ["Chateau Frontenac 014",917,7625,130,498,0,129375],
  ["Chateau Frontenac 015",940,8565,140,483,0,143475],
  ["Chateau Frontenac 016",963,9528,155,458,0,157920],
  ["Chateau Frontenac 017",987,10515,165,435,0,172725],
  ["Chateau Frontenac 018",1012,11527,175,432,0,187905],
  ["Chateau Frontenac 019",1037,12564,190,419,0,203460],
  ["Chateau Frontenac 020",1063,13627,200,407,0,219405],
  ["Chateau Frontenac 021",1090,14717,215,386,0,235755],
  ["Chateau Frontenac 022",1117,15834,225,375,0,252510],
  ["Chateau Frontenac 023",1145,16979,240,366,0,269685],
  ["Chateau Frontenac 024",1173,18152,250,365,0,287280],
  ["Chateau Frontenac 025",1203,19355,265,337,0,305325],
  ["Chateau Frontenac 026",1233,20588,275,339,0,323820],
  ["Chateau Frontenac 027",1263,21851,290,302,0,342765],
  ["Chateau Frontenac 028",1295,23146,300,306,0,362190],
  ["Chateau Frontenac 029",1327,24473,315,280,0,382095],
  ["Chateau Frontenac 030",1361,25834,330,276,0,402510],
  ["Chateau Frontenac 031",1395,27229,340,282,0,423435],
  ["Chateau Frontenac 032",1429,28658,355,259,0,444870],
  ["Chateau Frontenac 033",1465,30123,370,257,0,466845],
  ["Chateau Frontenac 034",1502,31625,385,236,0,489375],
  ["Chateau Frontenac 035",1539,33164,395,245,0,512460],
  ["Chateau Frontenac 036",1578,34751,410,228,0,536265],
  ["Chateau Frontenac 037",1617,36368,425,219,0,560520],
  ["Chateau Frontenac 038",1658,38026,440,213,0,585390],
  ["Chateau Frontenac 039",1699,39725,450,225,0,610875],
  ["Chateau Frontenac 040",1741,41466,465,210,0,636990],
  ["Chateau Frontenac 041",1785,43251,480,217,0,663765],
  ["Chateau Frontenac 042",1830,45081,495,204,0,691215],
  ["Chateau Frontenac 043",1875,46956,510,211,0,719340],
  ["Chateau Frontenac 044",1922,48878,525,191,0,748170],
  ["Chateau Frontenac 045",1970,50848,535,211,0,777720],
  ["Chateau Frontenac 046",2020,52868,550,223,0,808020],
  ["Chateau Frontenac 047",2070,54938,565,215,0,839070],
  ["Chateau Frontenac 048",2122,57060,580,230,0,870900],
  ["Chateau Frontenac 049",2175,59235,595,226,0,903525],
  ["Chateau Frontenac 050",2229,61464,610,242,0,936960],
  ["Chateau Frontenac 051",2285,63749,625,240,0,971235],
  ["Chateau Frontenac 052",2342,66091,640,260,0,1006365],
  ["Chateau Frontenac 053",2400,68491,655,252,0,1042365],
  ["Chateau Frontenac 054",2460,70951,670,274,0,1079265],
  ["Chateau Frontenac 055",2522,73473,685,278,0,1117095],
  ["Chateau Frontenac 056",2585,76058,700,304,0,1155870],
  ["Chateau Frontenac 057",2650,78708,715,312,0,1195620],
  ["Chateau Frontenac 058",2716,81424,730,340,0,1236360],
  ["Chateau Frontenac 059",2784,84208,745,350,0,1278120],
  ["Chateau Frontenac 060",2853,87061,760,382,0,1320915],
  ["Chateau Frontenac 061",2925,89986,775,387,0,1364790],
  ["Chateau Frontenac 062",2998,92984,790,422,0,1409760],
  ["Chateau Frontenac 063",3073,96057,805,439,0,1455855],
  ["Chateau Frontenac 064",3149,99206,820,478,0,1503090],
  ["Chateau Frontenac 065",3228,102434,835,500,0,1551510],
  ["Chateau Frontenac 066",3309,105743,855,514,0,1601145],
  ["Chateau Frontenac 067",3392,109135,870,559,0,1652025],
  ["Chateau Frontenac 068",3476,112611,885,568,0,1704165],
  ["Chateau Frontenac 069",3563,116174,900,618,0,1757610],
  ["Chateau Frontenac 070",3652,119826,915,649,0,1812390],
  ["Chateau Frontenac 071",3744,123570,930,703,0,1868550],
  ["Chateau Frontenac 072",3837,127407,945,739,0,1926105],
  ["Chateau Frontenac 073",3933,131340,965,778,0,1985100],
  ["Chateau Frontenac 074",4031,135371,980,829,0,2045565],
  ["Chateau Frontenac 075",4132,139503,995,882,0,2107545],
  ["Chateau Frontenac 076",4235,143738,1010,928,0,2171070],
  ["Chateau Frontenac 077",4341,148079,1025,986,0,2236185],
  ["Chateau Frontenac 078",4450,152529,1045,1028,0,2302935],
  ["Chateau Frontenac 079",4561,157090,1060,1102,0,2371350],
  ["Chateau Frontenac 080",4675,161765,1075,1159,0,2441475],
  ["Chateau Frontenac 081",4792,166557,1090,1238,0,2513355],
  ["Chateau Frontenac 082",4912,171469,1110,1291,0,2587035],
  ["Chateau Frontenac 083",5035,176504,1125,1348,0,2662560],
  ["Chateau Frontenac 084",5160,181664,1140,1436,0,2739960],
  ["Chateau Frontenac 085",5289,186953,1160,1498,0,2819295],
  ["Chateau Frontenac 086",5422,192375,1175,1583,0,2900625],
  ["Chateau Frontenac 087",5557,197932,1190,1671,0,2983980],
  ["Chateau Frontenac 088",5696,203628,1205,1762,0,3069420],
  ["Chateau Frontenac 089",5838,209466,1225,1837,0,3156990],
  ["Chateau Frontenac 090",5984,215450,1240,1946,0,3246750],
  ["Chateau Frontenac 091",6134,221584,1255,2029,0,3338760],
  ["Chateau Frontenac 092",6287,227871,1275,2115,0,3433065],
  ["Chateau Frontenac 093",6445,234316,1290,2235,0,3529740],
  ["Chateau Frontenac 094",6606,240922,1305,2339,0,3628830],
  ["Chateau Frontenac 095",6771,247693,1325,2447,0,3730395],
  ["Chateau Frontenac 096",6940,254633,1340,2569,0,3834495],
  ["Chateau Frontenac 097",7114,261747,1355,2695,0,3941205],
  ["Chateau Frontenac 098",7291,269038,1375,2797,0,4050570],
  ["Chateau Frontenac 099",7474,276512,1390,2942,0,4162680],
  ["Chateau Frontenac 100",7660,284172,1410,3061,0,4277580],
  ["Chateau Frontenac 101",7852,292024,1425,3196,0,4395360],
  ["Chateau Frontenac 102",8048,300072,1440,3355,0,4516080],
  ["Chateau Frontenac 103",8249,308321,1460,3489,0,4639815],
  ["Chateau Frontenac 104",8456,316777,1475,3648,0,4766655],
  ["Chateau Frontenac 105",8667,325444,1490,3792,0,4896660],
  ["Chateau Frontenac 106",8884,334328,1510,3952,0,5029920],
  ["Chateau Frontenac 107",9106,343434,1525,4116,0,5166510],
  ["Chateau Frontenac 108",9333,352767,1545,4277,0,5306505],
  ["Chateau Frontenac 109",9567,362334,1560,4474,0,5450010],
  ["Chateau Frontenac 110",9806,372140,1580,4646,0,5597100],
  ["Chateau Frontenac 111",10051,382191,1595,4843,0,5747865],
  ["Chateau Frontenac 112",10302,392493,1615,5019,0,5902395],
  ["Chateau Frontenac 113",10560,403053,1630,5239,0,6060795],
  ["Chateau Frontenac 114",10824,413877,1650,5436,0,6223155],
  ["Chateau Frontenac 115",11094,424971,1665,5649,0,6389565],
  ["Chateau Frontenac 116",11372,436343,1685,5870,0,6560145],
  ["Chateau Frontenac 117",11656,447999,1700,6107,0,6734985],
  ["Chateau Frontenac 118",11947,459946,1715,6350,0,6914190],
  ["Chateau Frontenac 119",12246,472192,1735,6573,0,7097880],
  ["Chateau Frontenac 120",12552,484744,1755,6812,0,7286160],
  ["Chateau Frontenac 121",12866,497610,1770,7088,0,7479150],
  ["Chateau Frontenac 122",13188,510798,1790,7344,0,7676970],
  ["Chateau Frontenac 123",13517,524315,1805,7625,0,7879725],
  ["Chateau Frontenac 124",13855,538170,1825,7896,0,8087550],
  ["Chateau Frontenac 125",14202,552372,1840,8206,0,8300580],
  ["Chateau Frontenac 126",14557,566929,1860,8486,0,8518935],
  ["Chateau Frontenac 127",14921,581850,1875,8792,0,8742750],
  ["Chateau Frontenac 128",15294,597144,1895,9108,0,8972160],
  ["Chateau Frontenac 129",15676,612820,1915,9424,0,9207300],
  ["Chateau Frontenac 130",16068,628888,1930,9778,0,9448320],
  ["Chateau Frontenac 131",16469,645357,1950,10112,0,9695355],
  ["Chateau Frontenac 132",16881,662238,1965,10457,0,9948570],
  ["Chateau Frontenac 133",17303,679541,1985,10822,0,10208115],
  ["Chateau Frontenac 134",17736,697277,2000,11208,0,10474155],
  ["Chateau Frontenac 135",18179,715456,2020,11594,0,10746840],
  ["Chateau Frontenac 136",18634,734090,2040,11983,0,11026350],
  ["Chateau Frontenac 137",19099,753189,2055,12390,0,11312835],
  ["Chateau Frontenac 138",19577,772766,2075,12811,0,11606490],
  ["Chateau Frontenac 139",20066,792832,2095,13216,0,11907480],
  ["Chateau Frontenac 140",20568,813400,2110,13680,0,12216000],
  ["Chateau Frontenac 141",21082,834482,2130,14127,0,12532230],
  ["Chateau Frontenac 142",21609,856091,2145,14597,0,12856365],
  ["Chateau Frontenac 143",22149,878240,2165,15080,0,13188600],
  ["Chateau Frontenac 144",22703,900943,2185,15567,0,13529145],
  ["Chateau Frontenac 145",23271,924214,2200,16098,0,13878210],
  ["Chateau Frontenac 146",23852,948066,2220,16603,0,14235990],
  ["Chateau Frontenac 147",24449,972515,2240,17133,0,14602725],
  ["Chateau Frontenac 148",25060,997575,2255,17696,0,14978625],
  ["Chateau Frontenac 149",25686,1023261,2275,18256,0,15363915],
  ["Chateau Frontenac 150",26329,1049590,2295,18832,0,15758850],
  ["Chateau Frontenac 151",26987,1076577,2310,19452,0,16163655],
  ["Chateau Frontenac 152",27661,1104238,2330,20051,0,16578570],
  ["Chateau Frontenac 153",28353,1132591,2350,20686,0,17003865],
  ["Chateau Frontenac 154",29062,1161653,2365,21337,0,17439795],
  ["Chateau Frontenac 155",29788,1191441,2385,21997,0,17886615],
  ["Chateau Frontenac 156",30533,1221974,2405,22685,0,18344610],
  ["Chateau Frontenac 157",31296,1253270,2420,23401,0,18814050],
  ["Chateau Frontenac 158",32079,1285349,2440,24127,0,19295235],
  ["Chateau Frontenac 159",32881,1318230,2460,24853,0,19788450],
  ["Chateau Frontenac 160",33703,1351933,2480,25608,0,20293995],
  ["Chateau Frontenac 161",34545,1386478,2495,26402,0,20812170],
  ["Chateau Frontenac 162",35409,1421887,2515,27200,0,21343305],
  ["Chateau Frontenac 163",36294,1458181,2535,28018,0,21887715],
  ["Chateau Frontenac 164",37201,1495382,2555,28868,0,22445730],
  ["Chateau Frontenac 165",38131,1533513,2570,29742,0,23017695],
  ["Chateau Frontenac 166",39085,1572598,2590,30639,0,23603970],
  ["Chateau Frontenac 167",40062,1612660,2610,31549,0,24204900],
  ["Das Habitat 000",0,0,0,0,0,38675],
  ["Das Habitat 001",60,60,10,31,0,39575],
  ["Das Habitat 002",100,160,10,71,0,41075],
  ["Das Habitat 003",180,340,20,113,0,43775],
  ["Das Habitat 004",270,610,30,174,0,47825],
  ["Das Habitat 005",360,970,40,236,0,53225],
  ["Das Habitat 006",460,1430,50,288,0,60125],
  ["Das Habitat 007",560,1990,60,360,0,68525],
  ["Das Habitat 008",670,2660,75,412,0,78575],
  ["Das Habitat 009",770,3430,85,483,0,90125],
  ["Das Habitat 010",880,4310,95,565,0,103325],
  ["Das Habitat 011",902,5212,110,540,0,116855],
  ["Das Habitat 012",925,6137,120,535,0,130730],
  ["Das Habitat 013",948,7085,130,529,0,144950],
  ["Das Habitat 014",972,8057,145,495,0,159530],
  ["Das Habitat 015",996,9053,155,491,0,174470],
  ["Das Habitat 016",1021,10074,170,460,0,189785],
  ["Das Habitat 017",1047,11121,185,438,0,205490],
  ["Das Habitat 018",1073,12194,195,426,0,221585],
  ["Das Habitat 019",1099,13293,210,414,0,238070],
  ["Das Habitat 020",1127,14420,225,385,0,254975],
  ["Das Habitat 021",1155,15575,235,385,0,272300],
  ["Das Habitat 022",1184,16759,250,376,0,290060],
  ["Das Habitat 023",1214,17973,265,348,0,308270],
  ["Das Habitat 024",1244,19217,280,341,0,326930],
  ["Das Habitat 025",1275,20492,295,295,0,346055],
  ["Das Habitat 026",1307,21799,305,298,0,365660],
  ["Das Habitat 027",1340,23139,320,284,0,385760],
  ["Das Habitat 028",1373,24512,335,269,0,406355],
  ["Das Habitat 029",1407,25919,350,256,0,427460],
  ["Das Habitat 030",1442,27361,365,243,0,449090],
  ["Das Habitat 031",1479,28840,380,233,0,471275],
  ["Das Habitat 032",1515,30355,395,221,0,494000],
  ["Das Habitat 033",1553,31908,410,203,0,517295],
  ["Das Habitat 034",1592,33500,425,194,0,541175],
  ["Das Habitat 035",1632,35132,440,187,0,565655],
  ["Das Habitat 036",1673,36805,455,180,0,590750],
  ["Das Habitat 037",1715,38520,470,175,0,616475],
  ["Das Habitat 038",1757,40277,485,169,0,642830],
  ["Das Habitat 039",1801,42078,500,166,0,669845],
  ["Das Habitat 040",1846,43924,515,163,0,697535],
  ["Das Habitat 041",1893,45817,535,134,0,725930],
  ["Das Habitat 042",1940,47757,550,143,0,755030],
  ["Das Habitat 043",1988,49745,565,133,0,784850],
  ["Das Habitat 044",2038,51783,580,146,0,815420],
  ["Das Habitat 045",2089,53872,595,140,0,846755],
  ["Das Habitat 046",2141,56013,615,125,0,878870],
  ["Das Habitat 047",2195,58208,630,141,0,911795],
  ["Das Habitat 048",2250,60458,645,130,0,945545],
  ["Das Habitat 049",2306,62764,660,149,0,980135],
  ["Das Habitat 050",2363,65127,675,148,0,1015580],
  ["Das Habitat 051",2422,67549,695,150,0,1051910],
  ["Das Habitat 052",2483,70032,710,164,0,1089155],
  ["Das Habitat 053",2545,72577,725,178,0,1127330],
  ["Das Habitat 054",2609,75186,745,175,0,1166465],
  ["Das Habitat 055",2674,77860,760,203,0,1206575],
  ["Das Habitat 056",2741,80601,775,203,0,1247690],
  ["Das Habitat 057",2809,83410,795,204,0,1289825],
  ["Das Habitat 058",2879,86289,810,236,0,1333010],
  ["Das Habitat 059",2951,89240,830,242,0,1377275],
  ["Das Habitat 060",3025,92265,845,268,0,1422650],
  ["Das Habitat 061",3101,95366,860,297,0,1469165],
  ["Das Habitat 062",3178,98544,880,317,0,1516835],
  ["Das Habitat 063",3258,101802,895,322,0,1565705],
  ["Das Habitat 064",3339,105141,915,336,0,1615790],
  ["Das Habitat 065",3423,108564,930,382,0,1667135],
  ["Das Habitat 066",3508,112072,945,410,0,1719755],
  ["Das Habitat 067",3596,115668,965,441,0,1773695],
  ["Das Habitat 068",3686,119354,985,464,0,1828985],
  ["Das Habitat 069",3778,123132,1000,519,0,1885655],
  ["Das Habitat 070",3872,127004,1020,537,0,1943735],
  ["Das Habitat 071",3969,130973,1035,576,0,2003270],
  ["Das Habitat 072",4068,135041,1050,637,0,2064290],
  ["Das Habitat 073",4170,139211,1070,673,0,2126840],
  ["Das Habitat 074",4274,143485,1090,720,0,2190950],
  ["Das Habitat 075",4381,147866,1105,769,0,2256665],
  ["Das Habitat 076",4491,152357,1125,804,0,2324030],
  ["Das Habitat 077",4603,156960,1140,879,0,2393075],
  ["Das Habitat 078",4718,161678,1160,927,0,2463845],
  ["Das Habitat 079",4836,166514,1175,997,0,2536385],
  ["Das Habitat 080",4957,171471,1195,1052,0,2610740],
  ["Das Habitat 081",5081,176552,1215,1109,0,2686955],
  ["Das Habitat 082",5208,181760,1230,1198,0,2765075],
  ["Das Habitat 083",5338,187098,1250,1252,0,2845145],
  ["Das Habitat 084",5471,192569,1265,1337,0,2927210],
  ["Das Habitat 085",5608,198177,1285,1407,0,3011330],
  ["Das Habitat 086",5748,203925,1305,1481,0,3097550],
  ["Das Habitat 087",5892,209817,1320,1588,0,3185930],
  ["Das Habitat 088",6039,215856,1340,1668,0,3276515],
  ["Das Habitat 089",6190,222046,1360,1762,0,3369365],
  ["Das Habitat 090",6345,228391,1375,1851,0,3464540],
  ["Das Habitat 091",6504,234895,1395,1943,0,3562100],
  ["Das Habitat 092",6666,241561,1415,2048,0,3662090],
  ["Das Habitat 093",6833,248394,1435,2149,0,3764585],
  ["Das Habitat 094",7004,255398,1450,2282,0,3869645],
  ["Das Habitat 095",7179,262577,1470,2390,0,3977330],
  ["Das Habitat 096",7358,269935,1490,2483,0,4087700],
  ["Das Habitat 097",7542,277477,1510,2610,0,4200830],
  ["Das Habitat 098",7731,285208,1525,2741,0,4316795],
  ["Das Habitat 099",7924,293132,1545,2868,0,4435655],
  ["Das Habitat 100",8122,301254,1565,3009,0,4557485],
  ["Das Habitat 101",8325,309579,1585,3145,0,4682360],
  ["Das Habitat 102",8533,318112,1600,3316,0,4810355],
  ["Das Habitat 103",8746,326858,1620,3454,0,4941545],
  ["Das Habitat 104",8965,335823,1640,3606,0,5076020],
  ["Das Habitat 105",9189,345012,1660,3773,0,5213855],
  ["Das Habitat 106",9419,354431,1680,3937,0,5355140],
  ["Das Habitat 107",9654,364085,1695,4114,0,5499950],
  ["Das Habitat 108",9896,373981,1715,4299,0,5648390],
  ["Das Habitat 109",10143,384124,1735,4470,0,5800535],
  ["Das Habitat 110",10397,394503,1755,4657,0,5956220],
  ["Das Habitat 111",10656,405159,1775,4859,0,6116060],
  ["Das Habitat 112",10923,416082,1790,5079,0,6279905],
  ["Das Habitat 113",11196,427278,1810,5295,0,6447845],
  ["Das Habitat 114",11476,438754,1830,5508,0,6619985],
  ["Das Habitat 115",11763,450517,1850,5720,0,6796430],
  ["Das Habitat 116",12057,462574,1870,5957,0,6977285],
  ["Das Habitat 117",12358,474932,1890,6191,0,7162655],
  ["Das Habitat 118",12667,487599,1910,6434,0,7352660],
  ["Das Habitat 119",12984,500583,1930,6694,0,7547420],
  ["Das Habitat 120",13308,513891,1950,6951,0,7747040],
  ["Das Habitat 121",13641,527532,1965,7217,0,7951655],
  ["Das Habitat 122",13982,541514,1985,7501,0,8161385],
  ["Das Habitat 123",14332,555846,2005,7784,0,8376365],
  ["Das Habitat 124",14690,570536,2025,8076,0,8596715],
  ["Das Habitat 125",15057,585593,2045,8386,0,8822570],
  ["Das Habitat 126",15434,601027,2065,8696,0,9054080],
  ["Das Habitat 127",15819,616846,2085,8997,0,9291365],
  ["Das Habitat 128",16215,633061,2105,9336,0,9534590],
  ["Das Habitat 129",16620,649681,2125,9674,0,9783890],
  ["Das Habitat 130",17036,666717,2145,10024,0,10039430],
  ["Das Habitat 131",17462,684179,2165,10393,0,10301360],
  ["Das Habitat 132",17898,702077,2185,10762,0,10569830],
  ["Das Habitat 133",18346,720423,2205,11134,0,10845020],
  ["Das Habitat 134",18804,739227,2225,11535,0,11127080],
  ["Das Habitat 135",19274,758501,2245,11938,0,11416190],
  ["Das Habitat 136",19756,778257,2265,12354,0,11712530],
  ["Das Virgo-Projekt 000",0,0,0,0,0,275000],
  ["Das Virgo-Projekt 001",90,90,10,61,0,276350],
  ["Das Virgo-Projekt 002",140,230,15,82,0,278450],
  ["Das Virgo-Projekt 003",250,480,25,163,0,282200],
  ["Das Virgo-Projekt 004",380,860,40,256,0,287900],
  ["Das Virgo-Projekt 005",520,1380,55,329,0,295700],
  ["Das Virgo-Projekt 006",650,2030,70,421,0,305450],
  ["Das Virgo-Projekt 007",800,2830,80,533,0,317450],
  ["Das Virgo-Projekt 008",940,3770,95,625,0,331550],
  ["Das Virgo-Projekt 009",1110,4880,115,729,0,348200],
  ["Das Virgo-Projekt 010",1250,6130,125,840,0,366950],
  ["Das Virgo-Projekt 011",1282,7412,145,805,0,386180],
  ["Das Virgo-Projekt 012",1314,8726,160,800,0,405890],
  ["Das Virgo-Projekt 013",1347,10073,175,767,0,426095],
  ["Das Virgo-Projekt 014",1380,11453,195,733,0,446795],
  ["Das Virgo-Projekt 015",1415,12868,210,730,0,468020],
  ["Das Virgo-Projekt 016",1450,14318,230,699,0,489770],
  ["Das Virgo-Projekt 017",1486,15804,245,687,0,512060],
  ["Das Virgo-Projekt 018",1524,17328,265,658,0,534920],
  ["Das Virgo-Projekt 019",1562,18890,280,659,0,558350],
  ["Das Virgo-Projekt 020",1601,20491,300,612,0,582365],
  ["Das Virgo-Projekt 021",1641,22132,320,585,0,606980],
  ["Das Virgo-Projekt 022",1682,23814,335,578,0,632210],
  ["Das Virgo-Projekt 023",1724,25538,355,554,0,658070],
  ["Das Virgo-Projekt 024",1767,27305,375,530,0,684575],
  ["Das Virgo-Projekt 025",1811,29116,395,517,0,711740],
  ["Das Virgo-Projekt 026",1856,30972,415,487,0,739580],
  ["Das Virgo-Projekt 027",1903,32875,435,467,0,768125],
  ["Das Virgo-Projekt 028",1950,34825,455,457,0,797375],
  ["Das Virgo-Projekt 029",1999,36824,470,459,0,827360],
  ["Das Virgo-Projekt 030",2049,38873,490,452,0,858095],
  ["Das Virgo-Projekt 031",2100,40973,510,436,0,889595],
  ["Das Virgo-Projekt 032",2152,43125,535,393,0,921875],
  ["Das Virgo-Projekt 033",2206,45331,550,409,0,954965],
  ["Das Virgo-Projekt 034",2261,47592,575,378,0,988880],
  ["Das Virgo-Projekt 035",2318,49910,595,369,0,1023650],
  ["Das Virgo-Projekt 036",2376,52286,615,360,0,1059290],
  ["Das Virgo-Projekt 037",2435,54721,635,362,0,1095815],
  ["Das Virgo-Projekt 038",2496,57217,655,348,0,1133255],
  ["Das Virgo-Projekt 039",2559,59776,675,344,0,1171640],
  ["Das Virgo-Projekt 040",2622,62398,700,341,0,1210970],
  ["Das Virgo-Projekt 041",2688,65086,720,341,0,1251290],
  ["Das Virgo-Projekt 042",2755,67841,740,341,0,1292615],
  ["Das Virgo-Projekt 043",2824,70665,760,353,0,1334975],
  ["Das Virgo-Projekt 044",2895,73560,785,328,0,1378400],
  ["Das Virgo-Projekt 045",2967,76527,805,333,0,1422905],
  ["Das Virgo-Projekt 046",3041,79568,825,341,0,1468520],
  ["Das Virgo-Projekt 047",3117,82685,850,351,0,1515275],
  ["Das Virgo-Projekt 048",3195,85880,870,362,0,1563200],
  ["Das Virgo-Projekt 049",3275,89155,890,358,0,1612325],
  ["Das Virgo-Projekt 050",3357,92512,915,354,0,1662680],
  ["Das Virgo-Projekt 051",3441,95953,935,381,0,1714295],
  ["Das Virgo-Projekt 052",3527,99480,960,392,0,1767200],
  ["Das Virgo-Projekt 053",3615,103095,980,413,0,1821425],
  ["Das Virgo-Projekt 054",3705,106800,1005,407,0,1877000],
  ["Das Virgo-Projekt 055",3798,110598,1025,443,0,1933970],
  ["Das Virgo-Projekt 056",3893,114491,1050,462,0,1992365],
  ["Das Virgo-Projekt 057",3990,118481,1070,493,0,2052215],
  ["Das Virgo-Projekt 058",4090,122571,1095,507,0,2113565],
  ["Das Virgo-Projekt 059",4192,126763,1115,552,0,2176445],
  ["Das Virgo-Projekt 060",4297,131060,1140,573,0,2240900],
  ["Das Virgo-Projekt 061",4404,135464,1160,613,0,2306960],
  ["Das Virgo-Projekt 062",4514,139978,1185,637,0,2374670],
  ["Das Virgo-Projekt 063",4627,144605,1210,684,0,2444075],
  ["Das Virgo-Projekt 064",4743,149348,1230,733,0,2515220],
  ["Das Virgo-Projekt 065",4861,154209,1255,756,0,2588135],
  ["Das Virgo-Projekt 066",4983,159192,1280,802,0,2662880],
  ["Das Virgo-Projekt 067",5108,164300,1300,870,0,2739500],
  ["Das Virgo-Projekt 068",5235,169535,1325,911,0,2818025],
  ["Das Virgo-Projekt 069",5366,174901,1350,966,0,2898515],
  ["Das Virgo-Projekt 070",5500,180401,1370,1025,0,2981015],
  ["Das Virgo-Projekt 071",5638,186039,1395,1077,0,3065585],
  ["Das Virgo-Projekt 072",5779,191818,1420,1152,0,3152270],
  ["Das Virgo-Projekt 073",5923,197741,1445,1210,0,3241115],
  ["Das Virgo-Projekt 074",6071,203812,1470,1282,0,3332180],
  ["Das Virgo-Projekt 075",6223,210035,1490,1348,0,3425525],
  ["Das Virgo-Projekt 076",6379,216414,1515,1418,0,3521210],
  ["Das Virgo-Projekt 077",6538,222952,1540,1511,0,3619280],
  ["Das Virgo-Projekt 078",6701,229653,1565,1588,0,3719795],
  ["Das Virgo-Projekt 079",6869,236522,1590,1680,0,3822830],
  ["Das Virgo-Projekt 080",7041,243563,1615,1758,0,3928445],
  ["Das Virgo-Projekt 081",7217,250780,1635,1867,0,4036700],
  ["Das Virgo-Projekt 082",7397,258177,1660,1981,0,4147655],
  ["Das Virgo-Projekt 083",7582,265759,1685,2080,0,4261385],
  ["Das Virgo-Projekt 084",7771,273530,1710,2193,0,4377950],
  ["Das Virgo-Projekt 085",7966,281496,1735,2293,0,4497440],
  ["Das Virgo-Projekt 086",8165,289661,1760,2416,0,4619915],
  ["Das Virgo-Projekt 087",8369,298030,1785,2534,0,4745450],
  ["Das Virgo-Projekt 088",8578,306608,1810,2677,0,4874120],
  ["Das Virgo-Projekt 089",8793,315401,1835,2806,0,5006015],
  ["Das Virgo-Projekt 090",9012,324413,1860,2941,0,5141195],
  ["Das Virgo-Projekt 091",9238,333651,1885,3080,0,5279765],
  ["Das Virgo-Projekt 092",9469,343120,1910,3236,0,5421800],
  ["Das Virgo-Projekt 093",9705,352825,1935,3386,0,5567375],
  ["Das Virgo-Projekt 094",9948,362773,1960,3563,0,5716595],
  ["Das Virgo-Projekt 095",10197,372970,1985,3716,0,5869550],
  ["Das Virgo-Projekt 096",10452,383422,2010,3895,0,6026330],
  ["Das Virgo-Projekt 097",10713,394135,2035,4071,0,6187025],
  ["Das Virgo-Projekt 098",10981,405116,2060,4263,0,6351740],
  ["Das Virgo-Projekt 099",11255,416371,2085,4433,0,6520565],
  ["Das Virgo-Projekt 100",11537,427908,2110,4649,0,6693620],
  ["Deal Castle 000",0,0,0,0,0,8700],
  ["Deal Castle 001",50,50,5,30,0,9450],
  ["Deal Castle 002",90,140,10,61,0,10800],
  ["Deal Castle 003",150,290,15,92,0,13050],
  ["Deal Castle 004",220,510,25,133,0,16350],
  ["Deal Castle 005",310,820,35,195,0,21000],
  ["Deal Castle 006",380,1200,40,256,0,26700],
  ["Deal Castle 007",480,1680,50,308,0,33900],
  ["Deal Castle 008",560,2240,60,360,0,42300],
  ["Deal Castle 009",650,2890,65,430,0,52050],
  ["Deal Castle 010",740,3630,75,482,0,63150],
  ["Deal Castle 011",759,4389,85,472,0,74535],
  ["Deal Castle 012",778,5167,95,463,0,86205],
  ["Deal Castle 013",797,5964,105,444,0,98160],
  ["Deal Castle 014",817,6781,115,436,0,110415],
  ["Deal Castle 015",838,7619,125,428,0,122985],
  ["Deal Castle 016",859,8478,135,411,0,135870],
  ["Deal Castle 017",880,9358,145,403,0,149070],
  ["Deal Castle 018",902,10260,155,397,0,162600],
  ["Deal Castle 019",925,11185,170,364,0,176475],
  ["Deal Castle 020",948,12133,180,359,0,190695],
  ["Deal Castle 021",971,13104,190,353,0,205260],
  ["Deal Castle 022",996,14100,200,340,0,220200],
  ["Deal Castle 023",1021,15121,210,336,0,235515],
  ["Deal Castle 024",1046,16167,225,304,0,251205],
  ["Deal Castle 025",1072,17239,235,302,0,267285],
  ["Deal Castle 026",1099,18338,245,300,0,283770],
  ["Deal Castle 027",1126,19464,260,280,0,300660],
  ["Deal Castle 028",1155,20619,270,280,0,317985],
  ["Deal Castle 029",1184,21803,280,281,0,335745],
  ["Deal Castle 030",1213,23016,295,233,0,353940],
  ["Deal Castle 031",1243,24259,305,234,0,372585],
  ["Deal Castle 032",1274,25533,315,227,0,391695],
  ["Deal Castle 033",1306,26839,330,221,0,411285],
  ["Deal Castle 034",1339,28178,340,226,0,431370],
  ["Deal Castle 035",1372,29550,350,221,0,451950],
  ["Deal Castle 036",1407,30957,365,208,0,473055],
  ["Deal Castle 037",1442,32399,375,205,0,494685],
  ["Deal Castle 038",1478,33877,390,203,0,516855],
  ["Deal Castle 039",1515,35392,400,212,0,539580],
  ["Deal Castle 040",1553,36945,415,184,0,562875],
  ["Deal Castle 041",1592,38537,425,194,0,586755],
  ["Deal Castle 042",1631,40168,440,186,0,611220],
  ["Deal Castle 043",1672,41840,450,198,0,636300],
  ["Deal Castle 044",1714,43554,465,183,0,662010],
  ["Deal Castle 045",1757,45311,480,189,0,688365],
  ["Deal Castle 046",1801,47112,490,204,0,715380],
  ["Deal Castle 047",1846,48958,505,191,0,743070],
  ["Deal Castle 048",1892,50850,515,209,0,771450],
  ["Deal Castle 049",1939,52789,530,199,0,800535],
  ["Deal Castle 050",1987,54776,540,219,0,830340],
  ["Deal Castle 051",2037,56813,555,211,0,860895],
  ["Deal Castle 052",2088,58901,570,224,0,892215],
  ["Deal Castle 053",2140,61041,580,248,0,924315],
  ["Deal Castle 054",2194,63235,595,245,0,957225],
  ["Deal Castle 055",2249,65484,610,262,0,990960],
  ["Deal Castle 056",2305,67789,620,280,0,1025535],
  ["Deal Castle 057",2362,70151,635,289,0,1060965],
  ["Deal Castle 058",2421,72572,650,292,0,1097280],
  ["Deal Castle 059",2482,75054,665,305,0,1134510],
  ["Deal Castle 060",2544,77598,675,329,0,1172670],
  ["Deal Castle 061",2608,80206,690,355,0,1211790],
  ["Deal Castle 062",2673,82879,705,363,0,1251885],
  ["Deal Castle 063",2740,85619,715,402,0,1292985],
  ["Deal Castle 064",2808,88427,730,432,0,1335105],
  ["Deal Castle 065",2878,91305,745,444,0,1378275],
  ["Deal Castle 066",2950,94255,760,479,0,1422525],
  ["Deal Castle 067",3024,97279,775,486,0,1467885],
  ["Deal Castle 068",3099,100378,785,532,0,1514370],
  ["Deal Castle 069",3177,103555,800,563,0,1562025],
  ["Deal Castle 070",3256,106811,815,594,0,1610865],
  ["Deal Castle 071",3338,110149,830,629,0,1660935],
  ["Deal Castle 072",3421,113570,840,684,0,1712250],
  ["Deal Castle 073",3507,117077,855,712,0,1764855],
  ["Deal Castle 074",3594,120671,870,761,0,1818765],
  ["Deal Castle 075",3684,124355,885,776,0,1874025],
  ["Deal Castle 076",3776,128131,900,831,0,1930665],
  ["Deal Castle 077",3871,132002,915,868,0,1988730],
  ["Deal Castle 078",3967,135969,930,926,0,2048235],
  ["Deal Castle 079",4067,140036,940,998,0,2109240],
  ["Deal Castle 080",4168,144204,955,1042,0,2171760],
  ["Deal Castle 081",4272,148476,970,1108,0,2235840],
  ["Deal Castle 082",4379,152855,985,1157,0,2301525],
  ["Deal Castle 083",4489,157344,1000,1230,0,2368860],
  ["Deal Castle 084",4601,161945,1015,1275,0,2437875],
  ["Deal Castle 085",4716,166661,1030,1352,0,2508615],
  ["Deal Castle 086",4834,171495,1045,1412,0,2581125],
  ["Deal Castle 087",4955,176450,1060,1496,0,2655450],
  ["Deal Castle 088",5079,181529,1075,1563,0,2731635],
  ["Deal Castle 089",5205,186734,1090,1651,0,2809710],
  ["Deal Castle 090",5336,192070,1100,1744,0,2889750],
  ["Deal Castle 091",5469,197539,1115,1829,0,2971785],
  ["Deal Castle 092",5606,203145,1130,1910,0,3055875],
  ["Deal Castle 093",5746,208891,1145,2002,0,3142065],
  ["Deal Castle 094",5889,214780,1160,2098,0,3230400],
  ["Deal Castle 095",6037,220817,1175,2198,0,3320955],
  ["Deal Castle 096",6188,227005,1190,2302,0,3413775],
  ["Deal Castle 097",6342,233347,1205,2408,0,3508905],
  ["Deal Castle 098",6501,239848,1220,2520,0,3606420],
  ["Deal Castle 099",6663,246511,1235,2634,0,3706365],
  ["Deal Castle 100",6830,253341,1250,2744,0,3808815],
  ["Deal Castle 101",7001,260342,1265,2867,0,3913830],
  ["Der Kraken 000",0,0,0,0,0,137350],
  ["Der Kraken 001",80,80,10,51,0,138550],
  ["Der Kraken 002",130,210,15,72,0,140500],
  ["Der Kraken 003",240,450,25,153,0,144100],
  ["Der Kraken 004",350,800,35,235,0,149350],
  ["Der Kraken 005",470,1270,50,298,0,156400],
  ["Der Kraken 006",610,1880,65,390,0,165550],
  ["Der Kraken 007",740,2620,75,482,0,176650],
  ["Der Kraken 008",880,3500,90,584,0,189850],
  ["Der Kraken 009",1020,4520,105,667,0,205150],
  ["Der Kraken 010",1160,5680,120,770,0,222550],
  ["Der Kraken 011",1189,6869,135,741,0,240385],
  ["Der Kraken 012",1219,8088,150,733,0,258670],
  ["Der Kraken 013",1250,9338,165,698,0,277420],
  ["Der Kraken 014",1281,10619,180,692,0,296635],
  ["Der Kraken 015",1313,11932,195,666,0,316330],
  ["Der Kraken 016",1346,13278,210,661,0,336520],
  ["Der Kraken 017",1379,14657,230,628,0,357205],
  ["Der Kraken 018",1414,16071,245,615,0,378415],
  ["Der Kraken 019",1449,17520,260,603,0,400150],
  ["Der Kraken 020",1485,19005,280,582,0,422425],
  ["Der Kraken 021",1523,20528,295,543,0,445270],
  ["Der Kraken 022",1561,22089,310,543,0,468685],
  ["Der Kraken 023",1600,23689,330,515,0,492685],
  ["Der Kraken 024",1640,25329,350,489,0,517285],
  ["Der Kraken 025",1681,27010,365,482,0,542500],
  ["Der Kraken 026",1723,28733,385,457,0,568345],
  ["Der Kraken 027",1766,30499,400,463,0,594835],
  ["Der Kraken 028",1810,32309,420,432,0,621985],
  ["Der Kraken 029",1855,34164,440,410,0,649810],
  ["Der Kraken 030",1901,36065,455,408,0,678325],
  ["Der Kraken 031",1949,38014,475,390,0,707560],
  ["Der Kraken 032",1998,40012,495,372,0,737530],
  ["Der Kraken 033",2047,42059,510,383,0,768235],
  ["Der Kraken 034",2099,44158,530,359,0,799720],
  ["Der Kraken 035",2151,46309,550,354,0,831985],
  ["Der Kraken 036",2205,48514,570,341,0,865060],
  ["Der Kraken 037",2260,50774,590,330,0,898960],
  ["Der Kraken 038",2316,53090,605,338,0,933700],
  ["Der Kraken 039",2374,55464,625,329,0,969310],
  ["Der Kraken 040",2434,57898,645,314,0,1005820],
  ["Der Kraken 041",2495,60393,665,318,0,1043245],
  ["Der Kraken 042",2557,62950,685,313,0,1081600],
  ["Der Kraken 043",2621,65571,705,311,0,1120915],
  ["Der Kraken 044",2686,68257,725,319,0,1161205],
  ["Der Kraken 045",2753,71010,745,319,0,1202500],
  ["Der Kraken 046",2822,73832,765,312,0,1244830],
  ["Der Kraken 047",2893,76725,785,326,0,1288225],
  ["Der Kraken 048",2965,79690,805,331,0,1332700],
  ["Der Kraken 049",3039,82729,825,339,0,1378285],
  ["Der Kraken 050",3115,85844,845,358,0,1425010],
  ["Der Kraken 051",3193,89037,865,369,0,1472905],
  ["Der Kraken 052",3273,92310,890,356,0,1522000],
  ["Der Kraken 053",3355,95665,910,381,0,1572325],
  ["Der Kraken 054",3439,99104,930,398,0,1623910],
  ["Der Kraken 055",3524,102628,950,417,0,1676770],
  ["Der Kraken 056",3613,106241,970,449,0,1730965],
  ["Der Kraken 057",3703,109944,990,472,0,1786510],
  ["Der Kraken 058",3795,113739,1015,469,0,1843435],
  ["Der Kraken 059",3890,117629,1035,497,0,1901785],
  ["Der Kraken 060",3988,121617,1055,538,0,1961605],
  ["Der Kraken 061",4087,125704,1075,571,0,2022910],
  ["Der Kraken 062",4189,129893,1100,597,0,2085745],
  ["Der Kraken 063",4294,134187,1120,645,0,2150155],
  ["Der Kraken 064",4401,138597,1140,677,0,2216305],
  ["Der Kraken 065",4511,143108,1160,720,0,2283970],
  ["Der Kraken 066",4624,147732,1185,747,0,2353330],
  ["Der Kraken 067",4740,152472,1205,806,0,2424430],
  ["Der Kraken 068",4858,157330,1225,857,0,2497300],
  ["Der Kraken 069",4980,162310,1250,894,0,2572000],
  ["Der Kraken 070",5104,167414,1270,961,0,2648560],
  ["Der Kraken 071",5232,172646,1295,1003,0,2727040],
  ["Der Kraken 072",5363,178009,1315,1068,0,2807485],
  ["Der Kraken 073",5497,183506,1335,1135,0,2889940],
  ["Der Kraken 074",5634,189140,1360,1206,0,2974450],
  ["Der Kraken 075",5775,194915,1380,1272,0,3061075],
  ["Der Kraken 076",5919,200834,1405,1329,0,3149860],
  ["Der Kraken 077",6067,206901,1425,1411,0,3240865],
  ["Der Kraken 078",6219,213120,1450,1497,0,3334150],
  ["Der Kraken 079",6374,219494,1470,1585,0,3429760],
  ["Der Kraken 080",6534,226028,1495,1640,0,3527770],
  ["Der Kraken 081",6697,232707,1515,1736,0,3627955],
  ["Der Kraken 082",6864,239571,1540,1837,0,3730915],
  ["Der Kraken 083",7036,246607,1560,1943,0,3836455],
  ["Der Kraken 084",7212,253819,1585,2032,0,3944635],
  ["Der Kraken 085",7392,261211,1605,2137,0,4055515],
  ["Der Kraken 086",7577,268788,1630,2256,0,4169170],
  ["Der Kraken 087",7766,276554,1650,2378,0,4285660],
  ["Der Kraken 088",7961,284515,1675,2488,0,4405075],
  ["Der Kraken 089",8160,292675,1700,2611,0,4527475],
  ["Der Kraken 090",8364,301039,1720,2758,0,4652935],
  ["Der Kraken 091",8573,309612,1745,2871,0,4781530],
  ["Der Kraken 092",8787,318399,1770,3009,0,4913335],
  ["Der Kraken 093",9007,327406,1790,3163,0,5048440],
  ["Der Kraken 094",9232,336638,1815,3302,0,5186920],
  ["Der Kraken 095",9463,346101,1835,3476,0,5328865],
  ["Der Kraken 096",9699,355800,1860,3628,0,5474350],
  ["Der Kraken 097",9942,365742,1885,3784,0,5623480],
  ["Der Kraken 098",10190,375932,1905,3966,0,5776330],
  ["Der Kraken 099",10445,386377,1930,4155,0,5933005],
  ["Der Kraken 100",10706,397083,1955,4330,0,6093595],
  ["Der Kraken 101",10974,408057,1980,4513,0,6258205],
  ["Der Kraken 102",11248,419305,2000,4720,0,6426925],
  ["Der Kraken 103",11529,430834,2025,4915,0,6599860],
  ["Der Kraken 104",11817,442651,2050,5137,0,6777115],
  ["Der Kraken 105",12113,454764,2070,5366,0,6958810],
  ["Der Kraken 106",12416,467180,2095,5566,0,7145050],
  ["Der Kraken 107",12726,479906,2120,5800,0,7335940],
  ["Der Kraken 108",13044,492950,2145,6032,0,7531600],
  ["Der Kraken 109",13370,506320,2170,6292,0,7732150],
  ["Der Kraken 110",13704,520024,2190,6559,0,7937710],
  ["Der Kraken 111",14047,534071,2215,6807,0,8148415],
  ["Der Kraken 112",14398,548469,2240,7082,0,8364385],
  ["Die Arche 000",0,0,0,0,0,95375],
  ["Die Arche 001",70,70,10,41,0,96425],
  ["Die Arche 002",110,180,15,52,0,98075],
  ["Die Arche 003",200,380,25,113,0,101075],
  ["Die Arche 004",290,670,35,175,0,105425],
  ["Die Arche 005",400,1070,45,237,0,111425],
  ["Die Arche 006",510,1580,60,310,0,119075],
  ["Die Arche 007",620,2200,70,391,0,128375],
  ["Die Arche 008",740,2940,85,453,0,139475],
  ["Die Arche 009",860,3800,95,545,0,152375],
  ["Die Arche 010",970,4770,110,608,0,166925],
  ["Die Arche 011",995,5765,120,605,0,181850],
  ["Die Arche 012",1020,6785,135,572,0,197150],
  ["Die Arche 013",1045,7830,150,559,0,212825],
  ["Die Arche 014",1071,8901,165,519,0,228890],
  ["Die Arche 015",1098,9999,180,509,0,245360],
  ["Die Arche 016",1125,11124,195,478,0,262235],
  ["Die Arche 017",1154,12278,210,469,0,279545],
  ["Die Arche 018",1182,13460,225,440,0,297275],
  ["Die Arche 019",1212,14672,240,433,0,315455],
  ["Die Arche 020",1242,15914,255,405,0,334085],
  ["Die Arche 021",1273,17187,270,398,0,353180],
  ["Die Arche 022",1305,18492,290,344,0,372755],
  ["Die Arche 023",1338,19830,305,329,0,392825],
  ["Die Arche 024",1371,21201,320,315,0,413390],
  ["Die Arche 025",1405,22606,335,301,0,434465],
  ["Die Arche 026",1440,24046,355,270,0,456065],
  ["Die Arche 027",1476,25522,370,268,0,478205],
  ["Die Arche 028",1513,27035,385,247,0,500900],
  ["Die Arche 029",1551,28586,405,210,0,524165],
  ["Die Arche 030",1590,30176,420,212,0,548015],
  ["Die Arche 031",1630,31806,435,194,0,572465],
  ["Die Arche 032",1670,33476,455,177,0,597515],
  ["Die Arche 033",1712,35188,470,172,0,623195],
  ["Die Arche 034",1755,36943,490,158,0,649520],
  ["Die Arche 035",1799,38742,505,144,0,676505],
  ["Die Arche 036",1844,40586,525,113,0,704165],
  ["Die Arche 037",1890,42476,540,122,0,732515],
  ["Die Arche 038",1937,44413,560,102,0,761570],
  ["Die Arche 039",1986,46399,575,103,0,791360],
  ["Die Arche 040",2035,48434,595,86,0,821885],
  ["Die Arche 041",2086,50520,615,70,0,853175],
  ["Die Arche 042",2138,52658,630,84,0,885245],
  ["Die Arche 043",2192,54850,650,63,0,918125],
  ["Die Arche 044",2246,57096,670,60,0,951815],
  ["Die Arche 045",2303,59399,685,59,0,986360],
  ["Die Arche 046",2360,61759,705,50,0,1021760],
  ["Die Arche 047",2419,64178,725,52,0,1058045],
  ["Die Arche 048",2480,66658,740,66,0,1095245],
  ["Die Arche 049",2541,69199,760,70,0,1133360],
  ["Die Arche 050",2605,71804,780,58,0,1172435],
  ["Die Arche 051",2670,74474,800,56,0,1212485],
  ["Die Arche 052",2737,77211,815,75,0,1253540],
  ["Die Arche 053",2805,80016,835,77,0,1295615],
  ["Die Arche 054",2875,82891,855,80,0,1338740],
  ["Die Arche 055",2947,85838,875,95,0,1382945],
  ["Die Arche 056",3021,88859,895,85,0,1428260],
  ["Die Arche 057",3096,91955,915,93,0,1474700],
  ["Die Arche 058",3174,95129,930,133,0,1522310],
  ["Die Arche 059",3253,98382,950,146,0,1571105],
  ["Die Arche 060",3334,101716,970,170,0,1621115],
  ["Die Arche 061",3418,105134,990,187,0,1672385],
  ["Die Arche 062",3503,108637,1010,196,0,1724930],
  ["Die Arche 063",3591,112228,1030,227,0,1778795],
  ["Die Arche 064",3681,115909,1050,250,0,1834010],
  ["Die Arche 065",3773,119682,1070,276,0,1890605],
  ["Die Arche 066",3867,123549,1090,313,0,1948610],
  ["Die Arche 067",3964,127513,1110,343,0,2008070],
  ["Die Arche 068",4063,131576,1130,367,0,2069015],
  ["Die Arche 069",4164,135740,1150,411,0,2131475],
  ["Die Arche 070",4268,140008,1170,448,0,2195495],
  ["Die Arche 071",4375,144383,1190,489,0,2261120],
  ["Die Arche 072",4484,148867,1210,541,0,2328380],
  ["Die Arche 073",4596,153463,1230,586,0,2397320],
  ["Die Arche 074",4711,158174,1250,625,0,2467985],
  ["Die Arche 075",4829,163003,1270,686,0,2540420],
  ["Die Arche 076",4950,167953,1290,740,0,2614670],
  ["Die Arche 077",5074,173027,1310,798,0,2690780],
  ["Die Arche 078",5200,178227,1335,838,0,2768780],
  ["Die Arche 079",5330,183557,1355,911,0,2848730],
  ["Die Arche 080",5464,189021,1375,970,0,2930690],
  ["Die Arche 081",5600,194621,1395,1039,0,3014690],
  ["Die Arche 082",5740,200361,1415,1122,0,3100790],
  ["Die Arche 083",5884,206245,1435,1200,0,3189050],
  ["Die Arche 084",6031,212276,1455,1280,0,3279515],
  ["Die Arche 085",6182,218458,1480,1365,0,3372245],
  ["Die Arche 086",6336,224794,1500,1433,0,3467285],
  ["Die Arche 087",6494,231288,1520,1524,0,3564695],
  ["Die Arche 088",6657,237945,1540,1630,0,3664550],
  ["Die Arche 089",6823,244768,1560,1730,0,3766895],
  ["Die Arche 090",6994,251762,1585,1814,0,3871805],
  ["Die Arche 091",7169,258931,1605,1914,0,3979340],
  ["Die Arche 092",7348,266279,1625,2036,0,4089560],
  ["Die Arche 093",7531,273810,1645,2152,0,4202525],
  ["Die Arche 094",7720,281530,1670,2266,0,4318325],
  ["Die Arche 095",7913,289443,1690,2402,0,4437020],
  ["Die Arche 096",8111,297554,1710,2533,0,4558685],
  ["Die Arche 097",8313,305867,1735,2640,0,4683380],
  ["Die Arche 098",8521,314388,1755,2781,0,4811195],
  ["Die Arche 099",8734,323122,1775,2937,0,4942205],
  ["Die Arche 100",8952,332074,1800,3080,0,5076485],
  ["Die Arche 101",9176,341250,1820,3237,0,5214125],
  ["Die Arche 102",9406,350656,1840,3410,0,5355215],
  ["Die Arche 103",9641,360297,1865,3550,0,5499830],
  ["Die Arche 104",9882,370179,1885,3724,0,5648060],
  ["Die Arche 105",10129,380308,1905,3905,0,5799995],
  ["Die Arche 106",10382,390690,1930,4092,0,5955725],
  ["Die Arche 107",10642,401332,1950,4285,0,6115355],
  ["Die Arche 108",10908,412240,1975,4456,0,6278975],
  ["Die Arche 109",11180,423420,1995,4661,0,6446675],
  ["Die Arche 110",11460,434880,2015,4884,0,6618575],
  ["Die Arche 111",11746,446626,2040,5095,0,6794765],
  ["Die Arche 112",12040,458666,2060,5322,0,6975365],
  ["Die Arche 113",12341,471007,2085,5519,0,7160480],
  ["Die Arche 114",12649,483656,2105,5770,0,7350215],
  ["Die Arche 115",12966,496622,2130,6011,0,7544705],
  ["Die Arche 116",13290,509912,2150,6269,0,7744055],
  ["Die Arche 117",13622,523534,2170,6544,0,7948385],
  ["Die Arche 118",13963,537497,2195,6799,0,8157830],
  ["Die Arche 119",14312,551809,2215,7072,0,8372510],
  ["Die Arche 120",14669,566478,2240,7353,0,8592545],
  ["Die Arche 121",15036,581514,2260,7663,0,8818085],
  ["Die Arche 122",15412,596926,2285,7953,0,9049265],
  ["Die Arche 123",15797,612723,2305,8271,0,9286220],
  ["Die Arche 124",16192,628915,2330,8582,0,9529100],
  ["Die Arche 125",16597,645512,2350,8930,0,9778055],
  ["Die Arche 126",17012,662524,2375,9259,0,10033235],
  ["Die Arche 127",17437,679961,2395,9618,0,10294790],
  ["Die Arche 128",17873,697834,2420,9978,0,10562885],
  ["Die Arche 129",18320,716154,2445,10329,0,10837685],
  ["Die Arche 130",18778,734932,2465,10730,0,11119355],
  ["Die Arche 131",19247,754179,2490,11123,0,11408060],
  ["Die Arche 132",19729,773908,2510,11539,0,11703995],
  ["Die Arche 133",20222,794130,2535,11946,0,12007325],
  ["Die Arche 134",20727,814857,2555,12394,0,12318230],
  ["Die Arche 135",21245,836102,2580,12828,0,12636905],
  ["Die Arche 136",21777,857879,2605,13273,0,12963560],
  ["Die Arche 137",22321,880200,2625,13751,0,13298375],
  ["Die Arche 138",22879,903079,2650,14243,0,13641560],
  ["Die Arche 139",23451,926530,2675,14729,0,13993325],
  ["Die Arche 140",24037,950567,2695,15229,0,14353880],
  ["Die Arche 141",24638,975205,2720,15754,0,14723450],
  ["Die Arche 142",25254,1000459,2740,16313,0,15102260],
  ["Die Arche 143",25885,1026344,2765,16858,0,15490535],
  ["Die Arche 144",26532,1052876,2790,17429,0,15888515],
  ["Die Arche 145",27196,1080072,2810,18018,0,16296455],
  ["Die Arche 146",27876,1107948,2835,18612,0,16714595],
  ["Die Arche 147",28573,1136521,2860,19243,0,17143190],
  ["Die Arche 148",29287,1165808,2880,19891,0,17582495],
  ["Die Arche 149",30019,1195827,2905,20536,0,18032780],
  ["Die Arche 150",30769,1226596,2930,21201,0,18494315],
  ["Die Arche 151",31539,1258135,2950,21914,0,18967400],
  ["Die Arche 152",32327,1290462,2975,22616,0,19452305],
  ["Die Arche 153",33135,1323597,3000,23349,0,19949330],
  ["Die Arche 154",33964,1357561,3025,24091,0,20458790],
  ["Die Arche 155",34813,1392374,3050,24856,0,20980985],
  ["Die Arche 156",35683,1428057,3070,25669,0,21516230],
  ["Die Arche 157",36575,1464632,3095,26475,0,22064855],
  ["Die Arche 158",37489,1502121,3120,27314,0,22627190],
  ["Die Arche 159",38427,1540548,3140,28185,0,23203595],
  ["Die Arche 160",39387,1579935,3165,29049,0,23794400],
  ["Die Arche 161",40372,1620307,3190,29968,0,24399980],
  ["Die Arche 162",41381,1661688,3215,30891,0,25020695],
  ["Die Arche 163",42416,1704104,3235,31860,0,25656935],
  ["Die Arche 164",43476,1747580,3260,32844,0,26309075],
  ["Die Arche 165",44563,1792143,3285,33827,0,26977520],
  ["Die Arche 166",45677,1837820,3310,34875,0,27662675],
  ["Die Arche 167",46819,1884639,3335,35931,0,28364960],
  ["Die Arche 168",47990,1932629,3355,37036,0,29084810],
  ["Die Arche 169",49189,1981818,3380,38159,0,29822645],
  ["Die Arche 170",50419,2032237,3405,39293,0,30578930],
  ["Die Arche 171",51679,2083916,3430,40487,0,31354115],
  ["Die Arche 172",52971,2136887,3455,41693,0,32148680],
  ["Die Arche 173",54296,2191183,3480,42943,0,32963120],
  ["Die Arche 174",55653,2246836,3500,44233,0,33797915],
  ["Die Arche 175",57044,2303880,3525,45529,0,34653575],
  ["Die Arche 176",58471,2362351,3550,46890,0,35530640],
  ["Die Arche 177",59932,2422283,3575,48265,0,36429620],
  ["Die Arche 178",61431,2483714,3600,49689,0,37351085],
  ["Die Arche 179",62966,2546680,3625,51137,0,38295575],
  ["Die Arche 180",64540,2611220,3650,52626,0,39263675],
  ["Die Arche 181",66154,2677374,3670,54183,0,40255985],
  ["Die Arche 182",67808,2745182,3695,55751,0,41273105],
  ["Die Arche 183",69503,2814685,3720,57371,0,42315650],
  ["Die blaue Galaxie 000",0,0,0,0,0,167350],
  ["Die blaue Galaxie 001",80,80,10,51,0,168550],
  ["Die blaue Galaxie 002",130,210,15,72,0,170500],
  ["Die blaue Galaxie 003",240,450,25,153,0,174100],
  ["Die blaue Galaxie 004",350,800,35,235,0,179350],
  ["Die blaue Galaxie 005",470,1270,50,298,0,186400],
  ["Die blaue Galaxie 006",610,1880,65,390,0,195550],
  ["Die blaue Galaxie 007",740,2620,75,482,0,206650],
  ["Die blaue Galaxie 008",880,3500,90,584,0,219850],
  ["Die blaue Galaxie 009",1020,4520,105,667,0,235150],
  ["Die blaue Galaxie 010",1160,5680,120,770,0,252550],
  ["Die blaue Galaxie 011",1189,6869,135,741,0,270385],
  ["Die blaue Galaxie 012",1219,8088,150,733,0,288670],
  ["Die blaue Galaxie 013",1250,9338,165,698,0,307420],
  ["Die blaue Galaxie 014",1281,10619,180,692,0,326635],
  ["Die blaue Galaxie 015",1313,11932,195,666,0,346330],
  ["Die blaue Galaxie 016",1346,13278,210,661,0,366520],
  ["Die blaue Galaxie 017",1379,14657,230,628,0,387205],
  ["Die blaue Galaxie 018",1414,16071,245,615,0,408415],
  ["Die blaue Galaxie 019",1449,17520,260,603,0,430150],
  ["Die blaue Galaxie 020",1485,19005,280,582,0,452425],
  ["Die blaue Galaxie 021",1523,20528,295,543,0,475270],
  ["Die blaue Galaxie 022",1561,22089,310,543,0,498685],
  ["Die blaue Galaxie 023",1600,23689,330,515,0,522685],
  ["Die blaue Galaxie 024",1640,25329,350,489,0,547285],
  ["Die blaue Galaxie 025",1681,27010,365,482,0,572500],
  ["Die blaue Galaxie 026",1723,28733,385,457,0,598345],
  ["Die blaue Galaxie 027",1766,30499,400,463,0,624835],
  ["Die blaue Galaxie 028",1810,32309,420,432,0,651985],
  ["Die blaue Galaxie 029",1855,34164,440,410,0,679810],
  ["Die blaue Galaxie 030",1901,36065,455,408,0,708325],
  ["Die blaue Galaxie 031",1949,38014,475,390,0,737560],
  ["Die blaue Galaxie 032",1998,40012,495,372,0,767530],
  ["Die blaue Galaxie 033",2047,42059,510,383,0,798235],
  ["Die blaue Galaxie 034",2099,44158,530,359,0,829720],
  ["Die blaue Galaxie 035",2151,46309,550,354,0,861985],
  ["Die blaue Galaxie 036",2205,48514,570,341,0,895060],
  ["Die blaue Galaxie 037",2260,50774,590,330,0,928960],
  ["Die blaue Galaxie 038",2316,53090,605,338,0,963700],
  ["Die blaue Galaxie 039",2374,55464,625,329,0,999310],
  ["Die blaue Galaxie 040",2434,57898,645,314,0,1035820],
  ["Die blaue Galaxie 041",2495,60393,665,318,0,1073245],
  ["Die blaue Galaxie 042",2557,62950,685,313,0,1111600],
  ["Die blaue Galaxie 043",2621,65571,705,311,0,1150915],
  ["Die blaue Galaxie 044",2686,68257,725,319,0,1191205],
  ["Die blaue Galaxie 045",2753,71010,745,319,0,1232500],
  ["Die blaue Galaxie 046",2822,73832,765,312,0,1274830],
  ["Die blaue Galaxie 047",2893,76725,785,326,0,1318225],
  ["Die blaue Galaxie 048",2965,79690,805,331,0,1362700],
  ["Die blaue Galaxie 049",3039,82729,825,339,0,1408285],
  ["Die blaue Galaxie 050",3115,85844,845,358,0,1455010],
  ["Die blaue Galaxie 051",3193,89037,865,369,0,1502905],
  ["Die blaue Galaxie 052",3273,92310,890,356,0,1552000],
  ["Die blaue Galaxie 053",3355,95665,910,381,0,1602325],
  ["Die blaue Galaxie 054",3439,99104,930,398,0,1653910],
  ["Die blaue Galaxie 055",3524,102628,950,417,0,1706770],
  ["Die blaue Galaxie 056",3613,106241,970,449,0,1760965],
  ["Die blaue Galaxie 057",3703,109944,990,472,0,1816510],
  ["Die blaue Galaxie 058",3795,113739,1015,469,0,1873435],
  ["Die blaue Galaxie 059",3890,117629,1035,497,0,1931785],
  ["Die blaue Galaxie 060",3988,121617,1055,538,0,1991605],
  ["Die blaue Galaxie 061",4087,125704,1075,571,0,2052910],
  ["Die blaue Galaxie 062",4189,129893,1100,597,0,2115745],
  ["Die blaue Galaxie 063",4294,134187,1120,645,0,2180155],
  ["Die blaue Galaxie 064",4401,138597,1140,677,0,2246305],
  ["Die blaue Galaxie 065",4511,143108,1160,720,0,2313970],
  ["Die blaue Galaxie 066",4624,147732,1185,747,0,2383330],
  ["Die blaue Galaxie 067",4740,152472,1205,806,0,2454430],
  ["Die blaue Galaxie 068",4858,157330,1225,857,0,2527300],
  ["Die blaue Galaxie 069",4980,162310,1250,894,0,2602000],
  ["Die blaue Galaxie 070",5104,167414,1270,961,0,2678560],
  ["Die blaue Galaxie 071",5232,172646,1295,1003,0,2757040],
  ["Die blaue Galaxie 072",5363,178009,1315,1068,0,2837485],
  ["Die blaue Galaxie 073",5497,183506,1335,1135,0,2919940],
  ["Die blaue Galaxie 074",5634,189140,1360,1206,0,3004450],
  ["Die blaue Galaxie 075",5775,194915,1380,1272,0,3091075],
  ["Die blaue Galaxie 076",5919,200834,1405,1329,0,3179860],
  ["Die blaue Galaxie 077",6067,206901,1425,1411,0,3270865],
  ["Die blaue Galaxie 078",6219,213120,1450,1497,0,3364150],
  ["Die blaue Galaxie 079",6374,219494,1470,1585,0,3459760],
  ["Die blaue Galaxie 080",6534,226028,1495,1640,0,3557770],
  ["Die blaue Galaxie 081",6697,232707,1515,1736,0,3657955],
  ["Die blaue Galaxie 082",6864,239571,1540,1837,0,3760915],
  ["Die blaue Galaxie 083",7036,246607,1560,1943,0,3866455],
  ["Die blaue Galaxie 084",7212,253819,1585,2032,0,3974635],
  ["Die blaue Galaxie 085",7392,261211,1605,2137,0,4085515],
  ["Die blaue Galaxie 086",7577,268788,1630,2256,0,4199170],
  ["Die blaue Galaxie 087",7766,276554,1650,2378,0,4315660],
  ["Die blaue Galaxie 088",7961,284515,1675,2488,0,4435075],
  ["Die blaue Galaxie 089",8160,292675,1700,2611,0,4557475],
  ["Die blaue Galaxie 090",8364,301039,1720,2758,0,4682935],
  ["Die blaue Galaxie 091",8573,309612,1745,2871,0,4811530],
  ["Die blaue Galaxie 092",8787,318399,1770,3009,0,4943335],
  ["Die blaue Galaxie 093",9007,327406,1790,3163,0,5078440],
  ["Die blaue Galaxie 094",9232,336638,1815,3302,0,5216920],
  ["Die blaue Galaxie 095",9463,346101,1835,3476,0,5358865],
  ["Die blaue Galaxie 096",9699,355800,1860,3628,0,5504350],
  ["Die blaue Galaxie 097",9942,365742,1885,3784,0,5653480],
  ["Die blaue Galaxie 098",10190,375932,1905,3966,0,5806330],
  ["Die blaue Galaxie 099",10445,386377,1930,4155,0,5963005],
  ["Die blaue Galaxie 100",10706,397083,1955,4330,0,6123595],
  ["Die blaue Galaxie 101",10974,408057,1980,4513,0,6288205],
  ["Die blaue Galaxie 102",11248,419305,2000,4720,0,6456925],
  ["Die blaue Galaxie 103",11529,430834,2025,4915,0,6629860],
  ["Die blaue Galaxie 104",11817,442651,2050,5137,0,6807115],
  ["Die blaue Galaxie 105",12113,454764,2070,5366,0,6988810],
  ["Die blaue Galaxie 106",12416,467180,2095,5566,0,7175050],
  ["Die blaue Galaxie 107",12726,479906,2120,5800,0,7365940],
  ["Die blaue Galaxie 108",13044,492950,2145,6032,0,7561600],
  ["Die blaue Galaxie 109",13370,506320,2170,6292,0,7762150],
  ["Die blaue Galaxie 110",13704,520024,2190,6559,0,7967710],
  ["Die blaue Galaxie 111",14047,534071,2215,6807,0,8178415],
  ["Die blaue Galaxie 112",14398,548469,2240,7082,0,8394385],
  ["Dresdner Frauenkirche 000",0,0,0,0,0,7500],
  ["Dresdner Frauenkirche 001",50,50,5,30,0,8250],
  ["Dresdner Frauenkirche 002",90,140,10,61,0,9600],
  ["Dresdner Frauenkirche 003",150,290,15,92,0,11850],
  ["Dresdner Frauenkirche 004",220,510,25,133,0,15150],
  ["Dresdner Frauenkirche 005",310,820,35,195,0,19800],
  ["Dresdner Frauenkirche 006",380,1200,40,256,0,25500],
  ["Dresdner Frauenkirche 007",480,1680,50,308,0,32700],
  ["Dresdner Frauenkirche 008",560,2240,60,360,0,41100],
  ["Dresdner Frauenkirche 009",650,2890,65,430,0,50850],
  ["Dresdner Frauenkirche 010",740,3630,75,482,0,61950],
  ["Dresdner Frauenkirche 011",759,4389,85,472,0,73335],
  ["Dresdner Frauenkirche 012",778,5167,95,463,0,85005],
  ["Dresdner Frauenkirche 013",797,5964,105,444,0,96960],
  ["Dresdner Frauenkirche 014",817,6781,115,436,0,109215],
  ["Dresdner Frauenkirche 015",838,7619,125,428,0,121785],
  ["Dresdner Frauenkirche 016",859,8478,135,411,0,134670],
  ["Dresdner Frauenkirche 017",880,9358,145,403,0,147870],
  ["Dresdner Frauenkirche 018",902,10260,155,397,0,161400],
  ["Dresdner Frauenkirche 019",925,11185,170,364,0,175275],
  ["Dresdner Frauenkirche 020",948,12133,180,359,0,189495],
  ["Dresdner Frauenkirche 021",971,13104,190,353,0,204060],
  ["Dresdner Frauenkirche 022",996,14100,200,340,0,219000],
  ["Dresdner Frauenkirche 023",1021,15121,210,336,0,234315],
  ["Dresdner Frauenkirche 024",1046,16167,225,304,0,250005],
  ["Dresdner Frauenkirche 025",1072,17239,235,302,0,266085],
  ["Dresdner Frauenkirche 026",1099,18338,245,300,0,282570],
  ["Dresdner Frauenkirche 027",1126,19464,260,280,0,299460],
  ["Dresdner Frauenkirche 028",1155,20619,270,280,0,316785],
  ["Dresdner Frauenkirche 029",1184,21803,280,281,0,334545],
  ["Dresdner Frauenkirche 030",1213,23016,295,233,0,352740],
  ["Dresdner Frauenkirche 031",1243,24259,305,234,0,371385],
  ["Dresdner Frauenkirche 032",1274,25533,315,227,0,390495],
  ["Dresdner Frauenkirche 033",1306,26839,330,221,0,410085],
  ["Dresdner Frauenkirche 034",1339,28178,340,226,0,430170],
  ["Dresdner Frauenkirche 035",1372,29550,350,221,0,450750],
  ["Dresdner Frauenkirche 036",1407,30957,365,208,0,471855],
  ["Dresdner Frauenkirche 037",1442,32399,375,205,0,493485],
  ["Dresdner Frauenkirche 038",1478,33877,390,203,0,515655],
  ["Dresdner Frauenkirche 039",1515,35392,400,212,0,538380],
  ["Dresdner Frauenkirche 040",1553,36945,415,184,0,561675],
  ["Dresdner Frauenkirche 041",1592,38537,425,194,0,585555],
  ["Dresdner Frauenkirche 042",1631,40168,440,186,0,610020],
  ["Dresdner Frauenkirche 043",1672,41840,450,198,0,635100],
  ["Dresdner Frauenkirche 044",1714,43554,465,183,0,660810],
  ["Dresdner Frauenkirche 045",1757,45311,480,189,0,687165],
  ["Dresdner Frauenkirche 046",1801,47112,490,204,0,714180],
  ["Dresdner Frauenkirche 047",1846,48958,505,191,0,741870],
  ["Dresdner Frauenkirche 048",1892,50850,515,209,0,770250],
  ["Dresdner Frauenkirche 049",1939,52789,530,199,0,799335],
  ["Dresdner Frauenkirche 050",1987,54776,540,219,0,829140],
  ["Dresdner Frauenkirche 051",2037,56813,555,211,0,859695],
  ["Dresdner Frauenkirche 052",2088,58901,570,224,0,891015],
  ["Dresdner Frauenkirche 053",2140,61041,580,248,0,923115],
  ["Dresdner Frauenkirche 054",2194,63235,595,245,0,956025],
  ["Dresdner Frauenkirche 055",2249,65484,610,262,0,989760],
  ["Dresdner Frauenkirche 056",2305,67789,620,280,0,1024335],
  ["Dresdner Frauenkirche 057",2362,70151,635,289,0,1059765],
  ["Dresdner Frauenkirche 058",2421,72572,650,292,0,1096080],
  ["Dresdner Frauenkirche 059",2482,75054,665,305,0,1133310],
  ["Dresdner Frauenkirche 060",2544,77598,675,329,0,1171470],
  ["Dresdner Frauenkirche 061",2608,80206,690,355,0,1210590],
  ["Dresdner Frauenkirche 062",2673,82879,705,363,0,1250685],
  ["Dresdner Frauenkirche 063",2740,85619,715,402,0,1291785],
  ["Dresdner Frauenkirche 064",2808,88427,730,432,0,1333905],
  ["Dresdner Frauenkirche 065",2878,91305,745,444,0,1377075],
  ["Dresdner Frauenkirche 066",2950,94255,760,479,0,1421325],
  ["Dresdner Frauenkirche 067",3024,97279,775,486,0,1466685],
  ["Dresdner Frauenkirche 068",3099,100378,785,532,0,1513170],
  ["Dresdner Frauenkirche 069",3177,103555,800,563,0,1560825],
  ["Dresdner Frauenkirche 070",3256,106811,815,594,0,1609665],
  ["Dresdner Frauenkirche 071",3338,110149,830,629,0,1659735],
  ["Dresdner Frauenkirche 072",3421,113570,840,684,0,1711050],
  ["Dresdner Frauenkirche 073",3507,117077,855,712,0,1763655],
  ["Dresdner Frauenkirche 074",3594,120671,870,761,0,1817565],
  ["Dresdner Frauenkirche 075",3684,124355,885,776,0,1872825],
  ["Dresdner Frauenkirche 076",3776,128131,900,831,0,1929465],
  ["Dresdner Frauenkirche 077",3871,132002,915,868,0,1987530],
  ["Dresdner Frauenkirche 078",3967,135969,930,926,0,2047035],
  ["Dresdner Frauenkirche 079",4067,140036,940,998,0,2108040],
  ["Dresdner Frauenkirche 080",4168,144204,955,1042,0,2170560],
  ["Dresdner Frauenkirche 081",4272,148476,970,1108,0,2234640],
  ["Dresdner Frauenkirche 082",4379,152855,985,1157,0,2300325],
  ["Dresdner Frauenkirche 083",4489,157344,1000,1230,0,2367660],
  ["Dresdner Frauenkirche 084",4601,161945,1015,1275,0,2436675],
  ["Dresdner Frauenkirche 085",4716,166661,1030,1352,0,2507415],
  ["Dresdner Frauenkirche 086",4834,171495,1045,1412,0,2579925],
  ["Dresdner Frauenkirche 087",4955,176450,1060,1496,0,2654250],
  ["Dresdner Frauenkirche 088",5079,181529,1075,1563,0,2730435],
  ["Dresdner Frauenkirche 089",5205,186734,1090,1651,0,2808510],
  ["Dresdner Frauenkirche 090",5336,192070,1100,1744,0,2888550],
  ["Dresdner Frauenkirche 091",5469,197539,1115,1829,0,2970585],
  ["Dresdner Frauenkirche 092",5606,203145,1130,1910,0,3054675],
  ["Dresdner Frauenkirche 093",5746,208891,1145,2002,0,3140865],
  ["Dresdner Frauenkirche 094",5889,214780,1160,2098,0,3229200],
  ["Dresdner Frauenkirche 095",6037,220817,1175,2198,0,3319755],
  ["Dresdner Frauenkirche 096",6188,227005,1190,2302,0,3412575],
  ["Dresdner Frauenkirche 097",6342,233347,1205,2408,0,3507705],
  ["Dresdner Frauenkirche 098",6501,239848,1220,2520,0,3605220],
  ["Dresdner Frauenkirche 099",6663,246511,1235,2634,0,3705165],
  ["Dresdner Frauenkirche 100",6830,253341,1250,2744,0,3807615],
  ["Dresdner Frauenkirche 101",7001,260342,1265,2867,0,3912630],
  ["Fliegende Insel 001",100,100,10,71,0,516750],
  ["Fliegende Insel 002",140,240,15,82,0,518850],
  ["Fliegende Insel 003",280,420,30,184,0,521550],
  ["Fliegende Insel 004",400,680,40,276,0,525450],
  ["Fliegende Insel 005",560,960,60,360,0,529650],
  ["Fliegende Insel 006",700,1260,70,471,0,534150],
  ["Fliegende Insel 007",850,1550,90,554,0,538500],
  ["Fliegende Insel 008",1020,1870,105,667,0,543300],
  ["Fliegende Insel 009",1180,2200,120,790,0,548250],
  ["Fliegende Insel 010",1350,2530,135,902,0,553200],
  ["Fliegende Insel 011",1384,2734,155,879,0,556260],
  ["Fliegende Insel 012",1419,2803,170,858,0,557295],
  ["Fliegende Insel 013",1454,2873,190,836,0,558345],
  ["Fliegende Insel 014",1491,2945,210,806,0,559425],
  ["Fliegende Insel 015",1528,3019,225,786,0,560535],
  ["Fliegende Insel 016",1566,3094,245,767,0,561660],
  ["Fliegende Insel 017",1605,3171,265,739,0,562815],
  ["Fliegende Insel 018",1645,3250,285,693,0,564000],
  ["Fliegende Insel 019",1686,3331,305,677,0,565215],
  ["Fliegende Insel 020",1729,3415,325,653,0,566475],
  ["Fliegende Insel 021",1772,3501,340,659,0,567765],
  ["Fliegende Insel 022",1816,3588,360,637,0,569070],
  ["Fliegende Insel 023",1861,3677,380,615,0,570405],
  ["Fliegende Insel 024",1908,3769,405,567,0,571785],
  ["Fliegende Insel 025",1956,3864,425,558,0,573210],
  ["Fliegende Insel 026",2005,3961,445,540,0,574665],
  ["Fliegende Insel 027",2055,4060,465,524,0,576150],
  ["Fliegende Insel 028",2106,4161,485,518,0,577665],
  ["Fliegende Insel 029",2159,4265,505,504,0,579225],
  ["Fliegende Insel 030",2213,4372,530,473,0,580830],
  ["Fliegende Insel 031",2268,4481,550,471,0,582465],
  ["Fliegende Insel 032",2325,4593,570,461,0,584145],
  ["Fliegende Insel 033",2383,4708,595,434,0,585870],
  ["Fliegende Insel 034",2442,4825,615,426,0,587625],
  ["Fliegende Insel 035",2503,4945,635,430,0,589425],
  ["Fliegende Insel 036",2566,5069,660,409,0,591285],
  ["Fliegende Insel 037",2630,5196,680,406,0,593190],
  ["Fliegende Insel 038",2696,5326,705,386,0,595140],
  ["Fliegende Insel 039",2763,5459,725,396,0,597135],
  ["Fliegende Insel 040",2832,5595,750,389,0,599175],
  ["Fliegende Insel 041",2903,5735,770,384,0,601275],
  ["Fliegende Insel 042",2976,5879,795,371,0,603435],
  ["Fliegende Insel 043",3050,6026,820,379,0,605640],
  ["Fliegende Insel 044",3126,6176,840,389,0,607890],
  ["Fliegende Insel 045",3204,6330,865,380,0,610200],
  ["Fliegende Insel 046",3284,6488,890,367,0,612570],
  ["Fliegende Insel 047",3367,6651,910,393,0,615015],
  ["Fliegende Insel 048",3451,6818,935,391,0,617520],
  ["Fliegende Insel 049",3537,6988,960,402,0,620070],
  ["Fliegende Insel 050",3625,7162,980,423,0,622680],
  ["Fliegende Insel 051",3716,7341,1005,418,0,625365],
  ["Fliegende Insel 052",3809,7525,1030,445,0,628125],
  ["Fliegende Insel 053",3904,7713,1055,454,0,630945],
  ["Fliegende Insel 054",4002,7906,1080,477,0,633840],
  ["Fliegende Insel 055",4102,8104,1100,510,0,636810],
  ["Fliegende Insel 056",4204,8306,1125,517,0,639840],
  ["Fliegende Insel 057",4309,8513,1150,556,0,642945],
  ["Fliegende Insel 058",4417,8726,1175,578,0,646140],
  ["Fliegende Insel 059",4527,8944,1200,613,0,649410],
  ["Fliegende Insel 060",4641,9168,1225,640,0,652770],
  ["Fliegende Insel 061",4757,9398,1250,671,0,656220],
  ["Fliegende Insel 062",4876,9633,1275,704,0,659745],
  ["Fliegende Insel 063",4997,9873,1300,759,0,663345],
  ["Fliegende Insel 064",5122,10119,1325,798,0,667035],
  ["Fliegende Insel 065",5250,10372,1350,850,0,670830],
  ["Fliegende Insel 066",5382,10632,1375,888,0,674730],
  ["Fliegende Insel 067",5516,10898,1400,946,0,678720],
  ["Fliegende Insel 068",5654,11170,1425,998,0,682800],
  ["Fliegende Insel 069",5795,11449,1450,1073,0,686985],
  ["Fliegende Insel 070",5940,11735,1475,1132,0,691275],
  ["Fliegende Insel 071",6089,12029,1500,1186,0,695685],
  ["Fliegende Insel 072",6241,12330,1525,1251,0,700200],
  ["Fliegende Insel 073",6397,12638,1550,1332,0,704820],
  ["Fliegende Insel 074",6557,12954,1575,1406,0,709560],
  ["Fliegende Insel 075",6721,13278,1600,1504,0,714420],
  ["Fliegende Insel 076",6889,13610,1630,1568,0,719400],
  ["Fliegende Insel 077",7061,13950,1655,1654,0,724500],
  ["Fliegende Insel 078",7237,14298,1680,1755,0,729720],
  ["Fliegende Insel 079",7418,14655,1705,1849,0,735075],
  ["Fliegende Insel 080",7604,15022,1730,1950,0,740580],
  ["Fliegende Insel 081",7794,15398,1760,2045,0,746220],
  ["Fliegende Insel 082",7989,15783,1785,2154,0,751995],
  ["Fliegende Insel 083",8188,16177,1810,2287,0,757905],
  ["Fliegende Insel 084",8393,16581,1835,2406,0,763965],
  ["Fliegende Insel 085",8603,16996,1865,2512,0,770190],
  ["Fliegende Insel 086",8818,17421,1890,2651,0,776565],
  ["Fliegende Insel 087",9038,17856,1915,2786,0,783090],
  ["Fliegende Insel 088",9264,18302,1945,2916,0,789780],
  ["Fliegende Insel 089",9496,18760,1970,3063,0,796650],
  ["Fliegende Insel 090",9733,19229,1995,3214,0,803685],
  ["Fliegende Insel 091",9977,19710,2025,3363,0,810900],
  ["Friedensturm 000",0,0,0,0,0,66562.5],
  ["Friedensturm 001",70,70,10,41,0,67612.5],
  ["Friedensturm 002",100,170,15,42,0,69112.5],
  ["Friedensturm 003",190,360,20,123,0,71962.5],
  ["Friedensturm 004",280,640,35,165,0,76162.5],
  ["Friedensturm 005",380,1020,45,217,0,81862.5],
  ["Friedensturm 006",480,1500,55,289,0,89062.5],
  ["Friedensturm 007",590,2090,65,370,0,97912.5],
  ["Friedensturm 008",710,2800,80,443,0,108562.5],
  ["Friedensturm 009",810,3610,90,514,0,120712.5],
  ["Friedensturm 010",930,4540,105,577,0,134662.5],
  ["Friedensturm 011",954,5494,120,564,0,148972.5],
  ["Friedensturm 012",978,6472,130,559,0,163642.5],
  ["Friedensturm 013",1002,7474,145,525,0,178672.5],
  ["Friedensturm 014",1027,8501,160,513,0,194077.5],
  ["Friedensturm 015",1053,9554,175,473,0,209872.5],
  ["Friedensturm 016",1079,10633,185,470,0,226057.5],
  ["Friedensturm 017",1106,11739,200,450,0,242647.5],
  ["Friedensturm 018",1134,12873,215,430,0,259657.5],
  ["Friedensturm 019",1162,14035,230,411,0,277087.5],
  ["Friedensturm 020",1191,15226,245,392,0,294952.5],
  ["Friedensturm 021",1221,16447,260,375,0,313267.5],
  ["Friedensturm 022",1251,17698,275,357,0,332032.5],
  ["Friedensturm 023",1283,18981,290,322,0,351277.5],
  ["Friedensturm 024",1315,20296,305,306,0,371002.5],
  ["Friedensturm 025",1347,21643,320,291,0,391207.5],
  ["Friedensturm 026",1381,23024,335,277,0,411922.5],
  ["Friedensturm 027",1416,24440,355,246,0,433162.5],
  ["Friedensturm 028",1451,25891,370,243,0,454927.5],
  ["Friedensturm 029",1487,27378,385,221,0,477232.5],
  ["Friedensturm 030",1524,28902,400,221,0,500092.5],
  ["Friedensturm 031",1563,30465,420,185,0,523537.5],
  ["Friedensturm 032",1602,32067,435,166,0,547567.5],
  ["Friedensturm 033",1642,33709,450,168,0,572197.5],
  ["Friedensturm 034",1683,35392,465,152,0,597442.5],
  ["Friedensturm 035",1725,37117,485,137,0,623317.5],
  ["Friedensturm 036",1768,38885,500,133,0,649837.5],
  ["Friedensturm 037",1812,40697,515,129,0,677017.5],
  ["Friedensturm 038",1857,42554,535,98,0,704872.5],
  ["Friedensturm 039",1904,44458,550,107,0,733432.5],
  ["Friedensturm 040",1951,46409,570,87,0,762697.5],
  ["Friedensturm 041",2000,48409,585,79,0,792697.5],
  ["Friedensturm 042",2050,50459,605,72,0,823447.5],
  ["Friedensturm 043",2101,52560,620,76,0,854962.5],
  ["Friedensturm 044",2154,54714,640,72,0,887272.5],
  ["Friedensturm 045",2208,56922,655,60,0,920392.5],
  ["Friedensturm 046",2263,59185,675,48,0,954337.5],
  ["Friedensturm 047",2319,61504,690,66,0,989122.5],
  ["Friedensturm 048",2377,63874,710,58,0,1024672.5],
  ["Friedensturm 049",2437,66311,730,61,0,1061227.5],
  ["Friedensturm 050",2498,68809,745,64,0,1098697.5],
  ["Friedensturm 051",2560,71369,765,50,0,1137097.5],
  ["Friedensturm 052",2624,73993,780,77,0,1176457.5],
  ["Friedensturm 053",2690,76683,800,76,0,1216807.5],
  ["Friedensturm 054",2757,79440,820,86,0,1258162.5],
  ["Friedensturm 055",2826,82266,835,98,0,1300552.5],
  ["Friedensturm 056",2896,85162,855,101,0,1343992.5],
  ["Friedensturm 057",2969,88131,875,117,0,1388527.5],
  ["Friedensturm 058",3043,91174,890,126,0,1434172.5],
  ["Friedensturm 059",3119,94293,910,145,0,1480957.5],
  ["Friedensturm 060",3197,97490,930,156,0,1528912.5],
  ["Friedensturm 061",3277,100767,945,179,0,1578067.5],
  ["Friedensturm 062",3359,104126,965,204,0,1628452.5],
  ["Friedensturm 063",3443,107569,985,221,0,1680097.5],
  ["Friedensturm 064",3529,111098,1005,231,0,1733032.5],
  ["Friedensturm 065",3617,114715,1025,262,0,1787287.5],
  ["Friedensturm 066",3707,118422,1040,305,0,1842892.5],
  ["Friedensturm 067",3800,122222,1060,341,0,1899892.5],
  ["Friedensturm 068",3895,126117,1080,370,0,1958317.5],
  ["Friedensturm 069",3993,130110,1100,401,0,2018212.5],
  ["Friedensturm 070",4092,134202,1120,443,0,2079592.5],
  ["Friedensturm 071",4195,138397,1140,471,0,2142517.5],
  ["Friedensturm 072",4299,142696,1155,517,0,2207002.5],
  ["Friedensturm 073",4407,147103,1175,568,0,2273107.5],
  ["Friedensturm 074",4517,151620,1195,612,0,2340862.5],
  ["Friedensturm 075",4630,156250,1215,658,0,2410312.5],
  ["Friedensturm 076",4746,160996,1235,717,0,2481502.5],
  ["Friedensturm 077",4864,165860,1255,759,0,2554462.5],
  ["Friedensturm 078",4986,170828,1275,814,0,2628982.5],
  ["Friedensturm 079",5111,175939,1295,882,0,2705647.5],
  ["Friedensturm 080",5238,181177,1315,943,0,2784217.5],
  ["Friedensturm 081",5369,186546,1335,1007,0,2864752.5],
  ["Friedensturm 082",5504,192050,1355,1085,0,2947312.5],
  ["Friedensturm 083",5641,197691,1375,1147,0,3031927.5],
  ["Friedensturm 084",5782,203473,1395,1221,0,3118657.5],
  ["Friedensturm 085",5927,209400,1415,1309,0,3207562.5],
  ["Friedensturm 086",6075,215475,1435,1391,0,3298687.5],
  ["Friedensturm 087",6227,221702,1455,1476,0,3392092.5],
  ["Friedensturm 088",6382,228084,1475,1574,0,3487822.5],
  ["Friedensturm 089",6542,234626,1495,1648,0,3585952.5],
  ["Friedensturm 090",6705,241331,1515,1744,0,3686527.5],
  ["Friedensturm 091",6873,248204,1535,1855,0,3789622.5],
  ["Friedensturm 092",7045,255249,1555,1961,0,3895297.5],
  ["Friedensturm 093",7221,262470,1575,2070,0,4003612.5],
  ["Friedensturm 094",7401,269871,1595,2193,0,4114627.5],
  ["Friedensturm 095",7586,277457,1615,2303,0,4228417.5],
  ["Friedensturm 096",7776,285233,1640,2417,0,4345057.5],
  ["Friedensturm 097",7971,293204,1660,2555,0,4464622.5],
  ["Friedensturm 098",8170,301374,1680,2688,0,4587172.5],
  ["Friedensturm 099",8374,309748,1700,2825,0,4712782.5],
  ["Friedensturm 100",8583,318331,1720,2977,0,4841527.5],
  ["Friedensturm 101",8798,327129,1740,3116,0,4973497.5],
  ["Friedensturm 102",9018,336147,1760,3269,0,5108767.5],
  ["Gaea-Statue 000",0,0,0,0,0,102600],
  ["Gaea-Statue 001",80,80,10,51,0,103800],
  ["Gaea-Statue 002",120,200,15,62,0,105600],
  ["Gaea-Statue 003",230,430,25,143,0,109050],
  ["Gaea-Statue 004",340,770,35,225,0,114150],
  ["Gaea-Statue 005",450,1220,45,287,0,120900],
  ["Gaea-Statue 006",580,1800,60,380,0,129600],
  ["Gaea-Statue 007",710,2510,75,452,0,140250],
  ["Gaea-Statue 008",850,3360,85,563,0,153000],
  ["Gaea-Statue 009",980,4340,100,656,0,167700],
  ["Gaea-Statue 010",1110,5450,115,729,0,184350],
  ["Gaea-Statue 011",1138,6588,130,719,0,201420],
  ["Gaea-Statue 012",1167,7755,145,690,0,218925],
  ["Gaea-Statue 013",1196,8951,160,682,0,236865],
  ["Gaea-Statue 014",1226,10177,170,665,0,255255],
  ["Gaea-Statue 015",1256,11433,190,638,0,274095],
  ["Gaea-Statue 016",1288,12721,205,612,0,293415],
  ["Gaea-Statue 017",1320,14041,220,607,0,313215],
  ["Gaea-Statue 018",1353,15394,235,583,0,333510],
  ["Gaea-Statue 019",1387,16781,250,579,0,354315],
  ["Gaea-Statue 020",1421,18202,265,555,0,375630],
  ["Gaea-Statue 021",1457,19659,285,505,0,397485],
  ["Gaea-Statue 022",1493,21152,300,504,0,419880],
  ["Gaea-Statue 023",1531,22683,315,484,0,442845],
  ["Gaea-Statue 024",1569,24252,335,465,0,466380],
  ["Gaea-Statue 025",1608,25860,350,457,0,490500],
  ["Gaea-Statue 026",1648,27508,370,440,0,515220],
  ["Gaea-Statue 027",1689,29197,385,423,0,540555],
  ["Gaea-Statue 028",1732,30929,400,429,0,566535],
  ["Gaea-Statue 029",1775,32704,420,397,0,593160],
  ["Gaea-Statue 030",1819,34523,440,374,0,620445],
  ["Gaea-Statue 031",1865,36388,455,372,0,648420],
  ["Gaea-Statue 032",1911,38299,475,352,0,677085],
  ["Gaea-Statue 033",1959,40258,490,362,0,706470],
  ["Gaea-Statue 034",2008,42266,510,344,0,736590],
  ["Gaea-Statue 035",2058,44324,525,327,0,767460],
  ["Gaea-Statue 036",2110,46434,545,322,0,799110],
  ["Gaea-Statue 037",2163,48597,565,308,0,831555],
  ["Gaea-Statue 038",2217,50814,585,296,0,864810],
  ["Gaea-Statue 039",2272,53086,600,314,0,898890],
  ["Gaea-Statue 040",2329,55415,620,304,0,933825],
  ["Gaea-Statue 041",2387,57804,640,305,0,969660],
  ["Gaea-Statue 042",2447,60251,660,290,0,1006365],
  ["Gaea-Statue 043",2508,62759,675,293,0,1043985],
  ["Gaea-Statue 044",2571,65330,695,299,0,1082550],
  ["Gaea-Statue 045",2635,67965,715,297,0,1122075],
  ["Gaea-Statue 046",2701,70666,735,296,0,1162590],
  ["Gaea-Statue 047",2768,73434,755,306,0,1204110],
  ["Gaea-Statue 048",2837,76271,775,299,0,1246665],
  ["Gaea-Statue 049",2908,79179,795,303,0,1290285],
  ["Gaea-Statue 050",2981,82160,815,319,0,1335000],
  ["Gaea-Statue 051",3055,85215,830,346,0,1380825],
  ["Gaea-Statue 052",3132,88347,850,366,0,1427805],
  ["Gaea-Statue 053",3210,91557,870,377,0,1475955],
  ["Gaea-Statue 054",3290,94847,895,354,0,1525305],
  ["Gaea-Statue 055",3373,98220,910,399,0,1575900],
  ["Gaea-Statue 056",3457,101677,930,416,0,1627755],
  ["Gaea-Statue 057",3543,105220,950,436,0,1680900],
  ["Gaea-Statue 058",3632,108852,970,468,0,1735380],
  ["Gaea-Statue 059",3723,112575,995,473,0,1791225],
  ["Gaea-Statue 060",3816,116391,1015,490,0,1848465],
  ["Gaea-Statue 061",3911,120302,1035,518,0,1907130],
  ["Gaea-Statue 062",4009,124311,1055,559,0,1967265],
  ["Gaea-Statue 063",4109,128420,1075,593,0,2028900],
  ["Gaea-Statue 064",4212,132632,1095,629,0,2092080],
  ["Gaea-Statue 065",4317,136949,1115,677,0,2156835],
  ["Gaea-Statue 066",4425,141374,1135,710,0,2223210],
  ["Gaea-Statue 067",4536,145910,1155,754,0,2291250],
  ["Gaea-Statue 068",4649,150559,1180,801,0,2360985],
  ["Gaea-Statue 069",4765,155324,1200,851,0,2432460],
  ["Gaea-Statue 070",4884,160208,1220,903,0,2505720],
  ["Gaea-Statue 071",5006,165214,1240,968,0,2580810],
  ["Gaea-Statue 072",5132,170346,1260,1018,0,2657790],
  ["Gaea-Statue 073",5260,175606,1285,1059,0,2736690],
  ["Gaea-Statue 074",5391,180997,1305,1124,0,2817555],
  ["Gaea-Statue 075",5526,186523,1325,1202,0,2900445],
  ["Gaea-Statue 076",5664,192187,1350,1264,0,2985405],
  ["Gaea-Statue 077",5806,197993,1370,1331,0,3072495],
  ["Gaea-Statue 078",5951,203944,1390,1419,0,3161760],
  ["Gaea-Statue 079",6100,210044,1410,1501,0,3253260],
  ["Gaea-Statue 080",6252,216296,1435,1568,0,3347040],
  ["Gaea-Statue 081",6408,222704,1455,1657,0,3443160],
  ["Gaea-Statue 082",6569,229273,1475,1761,0,3541695],
  ["Gaea-Statue 083",6733,236006,1500,1830,0,3642690],
  ["Gaea-Statue 084",6901,242907,1520,1931,0,3746205],
  ["Gaea-Statue 085",7074,249981,1545,2018,0,3852315],
  ["Gaea-Statue 086",7250,257231,1565,2137,0,3961065],
  ["Gaea-Statue 087",7432,264663,1585,2252,0,4072545],
  ["Gaea-Statue 088",7618,272281,1610,2354,0,4186815],
  ["Gaea-Statue 089",7808,280089,1630,2487,0,4303935],
  ["Gaea-Statue 090",8003,288092,1650,2615,0,4423980],
  ["Gaea-Statue 091",8203,296295,1675,2730,0,4547025],
  ["Gaea-Statue 092",8408,304703,1695,2868,0,4673145],
  ["Gaea-Statue 093",8618,313321,1720,3012,0,4802415],
  ["Gaea-Statue 094",8834,322155,1740,3152,0,4934925],
  ["Gaea-Statue 095",9055,331210,1765,3286,0,5070750],
  ["Gaea-Statue 096",9281,340428,1785,3446,0,5209020],
  ["Gaea-Statue 097",9513,349941,1810,3612,0,5351715],
  ["Gaea-Statue 098",9751,359692,1830,3783,0,5497980],
  ["Gaea-Statue 099",9995,369687,1855,3933,0,5647905],
  ["Gaea-Statue 100",10245,379932,1875,4116,0,5801580],
  ["Gaea-Statue 101",10501,390433,1900,4306,0,5959095],
  ["Gaea-Statue 102",10763,401196,1920,4502,0,6120540],
  ["Gaea-Statue 103",11032,412228,1945,4684,0,6286020],
  ["Gaea-Statue 104",11308,423536,1965,4884,0,6455640],
  ["Gaea-Statue 105",11591,435127,1990,5101,0,6629505],
  ["Gaea-Statue 106",11880,447007,2015,5304,0,6807705],
  ["Gaea-Statue 107",12177,459184,2035,5535,0,6990360],
  ["Gaea-Statue 108",12482,471666,2060,5764,0,7177590],
  ["Gaea-Statue 109",12794,484460,2080,6019,0,7369500],
  ["Gaea-Statue 110",13114,497574,2105,6235,0,7566210],
  ["Gaea-Statue 111",13442,511016,2125,6496,0,7767840],
  ["Gaea-Statue 112",13778,524794,2150,6757,0,7974510],
  ["Gaea-Statue 113",14122,538916,2175,7015,0,8186340],
  ["Gaea-Statue 114",14475,553391,2195,7311,0,8403465],
  ["Gaea-Statue 115",14837,568228,2220,7588,0,8626020],
  ["Gaea-Statue 116",15208,583436,2245,7872,0,8854140],
  ["Gaea-Statue 117",15588,599024,2265,8186,0,9087960],
  ["Gaea-Statue 118",15978,615002,2290,8510,0,9327630],
  ["Gaea-Statue 119",16377,631379,2315,8823,0,9573285],
  ["Gaea-Statue 120",16787,648166,2335,9158,0,9825090],
  ["Gaea-Statue 121",17206,665372,2360,9501,0,10083180],
  ["Gaea-Statue 122",17636,683008,2385,9845,0,10347720],
  ["Gaea-Statue 123",18077,701085,2405,10229,0,10618875],
  ["Gaea-Statue 124",18529,719614,2430,10605,0,10896810],
  ["Gaea-Statue 125",18992,738606,2455,10973,0,11181690],
  ["Gaea-Statue 126",19467,758073,2480,11372,0,11473695],
  ["Gaea-Statue 127",19954,778027,2500,11802,0,11773005],
  ["Gaea-Statue 128",20453,798480,2525,12215,0,12079800],
  ["Gaea-Statue 129",20964,819426,2550,12650,0,12393990],
  ["Gaea-Statue 130",21488,840914,2575,13080,0,12716310],
  ["Gaea-Statue 131",22025,862939,2595,13550,0,13046685],
  ["Gaea-Statue 132",22576,885515,2620,14035,0,13385325],
  ["Gaea-Statue 133",23140,908655,2645,14513,0,13732425],
  ["Gaea-Statue 134",23719,932374,2670,15016,0,14088210],
  ["Gaea-Statue 135",24312,956686,2690,15523,0,14452890],
  ["Gaea-Statue 136",24919,981605,2715,16044,0,14826675],
  ["Gaea-Statue 137",25542,1007147,2740,16601,0,15209805],
  ["Galataturm 000",0,268534,0,0,0,4028910],
  ["Galataturm 001",20,20,5,0,0,1200],
  ["Galataturm 002",40,60,10,11,0,1800],
  ["Galataturm 003",60,120,15,2,0,2700],
  ["Galataturm 004",130,250,20,63,0,4650],
  ["Galataturm 005",210,460,25,123,0,7800],
  ["Galataturm 006",290,750,35,175,0,12150],
  ["Galataturm 007",370,1120,40,246,0,17700],
  ["Galataturm 008",460,1580,50,288,0,24600],
  ["Galataturm 009",550,2130,55,359,0,32850],
  ["Galataturm 010",650,2780,65,430,0,42600],
  ["Galataturm 011",667,3447,70,438,0,52605],
  ["Galataturm 012",683,4130,80,416,0,62850],
  ["Galataturm 013",700,4830,85,413,0,73350],
  ["Galataturm 014",718,5548,95,403,0,84120],
  ["Galataturm 015",736,6284,100,412,0,95160],
  ["Galataturm 016",754,7038,110,392,0,106470],
  ["Galataturm 017",773,7811,120,383,0,118065],
  ["Galataturm 018",792,8603,130,373,0,129945],
  ["Galataturm 019",812,9415,135,364,0,142125],
  ["Galataturm 020",833,10248,145,356,0,154620],
  ["Galataturm 021",853,11101,155,348,0,167415],
  ["Galataturm 022",875,11976,165,323,0,180540],
  ["Galataturm 023",897,12873,175,317,0,193995],
  ["Galataturm 024",919,13792,180,330,0,207780],
  ["Galataturm 025",942,14734,190,324,0,221910],
  ["Galataturm 026",965,15699,210,280,0,236385],
  ["Galataturm 027",990,16689,220,277,0,251235],
  ["Galataturm 028",1014,17703,225,272,0,266445],
  ["Galataturm 029",1040,18743,230,289,0,282045],
  ["Galataturm 030",1066,19809,240,287,0,298035],
  ["Galataturm 031",1092,20901,250,284,0,314415],
  ["Galataturm 032",1120,22021,255,283,0,331215],
  ["Galataturm 033",1147,23168,265,281,0,348420],
  ["Galataturm 034",1176,24344,275,282,0,366060],
  ["Galataturm 035",1206,25550,285,254,0,384150],
  ["Galataturm 036",1236,26786,295,256,0,402690],
  ["Galataturm 037",1267,28053,305,258,0,421695],
  ["Galataturm 038",1298,29351,315,251,0,441165],
  ["Galataturm 039",1331,30682,325,255,0,461130],
  ["Galataturm 040",1364,32046,335,260,0,481590],
  ["Galataturm 041",1398,33444,345,256,0,502560],
  ["Galataturm 042",1433,34877,360,254,0,524055],
  ["Galataturm 043",1469,36346,370,261,0,546090],
  ["Galataturm 044",1505,37851,380,259,0,568665],
  ["Galataturm 045",1543,39394,390,268,0,591810],
  ["Galataturm 046",1582,40976,400,279,0,615540],
  ["Galataturm 047",1621,42597,410,271,0,639855],
  ["Galataturm 048",1662,44259,420,284,0,664785],
  ["Galataturm 049",1703,45962,430,296,0,690330],
  ["Galataturm 050",1746,47708,440,301,0,716520],
  ["Galataturm 051",1789,49497,450,315,0,743355],
  ["Galataturm 052",1834,51331,465,303,0,770865],
  ["Galataturm 053",1880,53211,475,321,0,799065],
  ["Galataturm 054",1927,55138,485,339,0,827970],
  ["Galataturm 055",1975,57113,495,349,0,857595],
  ["Galataturm 056",2025,59138,505,370,0,887970],
  ["Galataturm 057",2075,61213,515,392,0,919095],
  ["Galataturm 058",2127,63340,525,396,0,951000],
  ["Galataturm 059",2180,65520,540,412,0,983700],
  ["Galataturm 060",2235,67755,550,438,0,1017225],
  ["Galataturm 061",2290,70045,560,455,0,1051575],
  ["Galataturm 062",2348,72393,570,484,0,1086795],
  ["Galataturm 063",2406,74799,585,485,0,1122885],
  ["Galataturm 064",2467,77266,595,518,0,1159890],
  ["Galataturm 065",2528,79794,605,550,0,1197810],
  ["Galataturm 066",2591,82385,615,575,0,1236675],
  ["Galataturm 067",2656,85041,625,611,0,1276515],
  ["Galataturm 068",2723,87764,640,641,0,1317360],
  ["Galataturm 069",2791,90555,650,662,0,1359225],
  ["Galataturm 070",2860,93415,660,703,0,1402125],
  ["Galataturm 071",2932,96347,675,717,0,1446105],
  ["Galataturm 072",3005,99352,685,761,0,1491180],
  ["Galataturm 073",3080,102432,695,808,0,1537380],
  ["Galataturm 074",3157,105589,705,847,0,1584735],
  ["Galataturm 075",3236,108825,720,889,0,1633275],
  ["Galataturm 076",3317,112142,730,941,0,1683030],
  ["Galataturm 077",3400,115542,740,986,0,1734030],
  ["Galataturm 078",3485,119027,755,1023,0,1786305],
  ["Galataturm 079",3572,122599,765,1062,0,1839885],
  ["Galataturm 080",3661,126260,775,1123,0,1894800],
  ["Galataturm 081",3753,130013,790,1177,0,1951095],
  ["Galataturm 082",3847,133860,800,1233,0,2008800],
  ["Galataturm 083",3943,137803,810,1300,0,2067945],
  ["Galataturm 084",4041,141844,825,1341,0,2128560],
  ["Galataturm 085",4142,145986,835,1414,0,2190690],
  ["Galataturm 086",4246,150232,850,1480,0,2254380],
  ["Galataturm 087",4352,154584,860,1548,0,2319660],
  ["Galataturm 088",4461,159045,875,1609,0,2386575],
  ["Galataturm 089",4572,163617,885,1664,0,2455155],
  ["Galataturm 090",4687,168304,895,1751,0,2525460],
  ["Galataturm 091",4804,173108,910,1830,0,2597520],
  ["Galataturm 092",4924,178032,920,1912,0,2671380],
  ["Galataturm 093",5047,183079,930,2006,0,2747085],
  ["Galataturm 094",5173,188252,945,2075,0,2824680],
  ["Galataturm 095",5303,193555,955,2177,0,2904225],
  ["Galataturm 096",5435,198990,970,2271,0,2985750],
  ["Galataturm 097",5571,204561,980,2369,0,3069315],
  ["Galataturm 098",5710,210271,995,2460,0,3154965],
  ["Galataturm 099",5853,216124,1005,2555,0,3242760],
  ["Galataturm 100",5999,222123,1015,2673,0,3332745],
  ["Galataturm 101",6149,228272,1030,2785,0,3424980],
  ["Galataturm 102",6303,234575,1040,2901,0,3519525],
  ["Galataturm 103",6461,241036,1055,3011,0,3616440],
  ["Galataturm 104",6622,247658,1065,3134,0,3715770],
  ["Galataturm 105",6788,254446,1080,3263,0,3817590],
  ["Galataturm 106",6957,261403,1090,3403,0,3921945],
  ["Galataturm 107",7131,268534,1105,3519,0,4028910],
  ["Hagia Sophia 000",0,0,0,0,0,2170],
  ["Hagia Sophia 001",50,50,5,30,1,2920],
  ["Hagia Sophia 002",60,110,10,31,1,3820],
  ["Hagia Sophia 003",120,230,15,62,2,5620],
  ["Hagia Sophia 004",190,420,20,123,2,8470],
  ["Hagia Sophia 005",240,660,25,153,3,12070],
  ["Hagia Sophia 006",320,980,35,205,3,16870],
  ["Hagia Sophia 007",380,1360,40,256,4,22570],
  ["Hagia Sophia 008",460,1820,50,288,4,29470],
  ["Hagia Sophia 009",530,2350,55,339,5,37420],
  ["Hagia Sophia 010",600,2950,65,380,6,46420],
  ["Hagia Sophia 011",615,3565,70,386,6,55645],
  ["Hagia Sophia 012",631,4196,80,364,7,65110],
  ["Hagia Sophia 013",647,4843,85,360,7,74815],
  ["Hagia Sophia 014",663,5506,95,348,8,84760],
  ["Hagia Sophia 015",679,6185,100,355,9,94945],
  ["Hagia Sophia 016",696,6881,110,334,9,105385],
  ["Hagia Sophia 017",714,7595,120,324,10,116095],
  ["Hagia Sophia 018",732,8327,130,313,10,127075],
  ["Hagia Sophia 019",750,9077,135,302,11,138325],
  ["Hagia Sophia 020",769,9846,145,292,12,149860],
  ["Hagia Sophia 021",788,10634,155,283,12,161680],
  ["Hagia Sophia 022",807,11441,165,255,13,173785],
  ["Hagia Sophia 023",828,12269,175,248,13,186205],
  ["Hagia Sophia 024",848,13117,180,259,14,198925],
  ["Hagia Sophia 025",869,13986,190,251,15,211960],
  ["Hagia Sophia 026",891,14877,200,235,15,225325],
  ["Hagia Sophia 027",913,15790,210,228,16,239020],
  ["Hagia Sophia 028",936,16726,220,223,16,253060],
  ["Hagia Sophia 029",960,17686,230,209,17,267460],
  ["Hagia Sophia 030",984,18670,240,205,18,282220],
  ["Hagia Sophia 031",1008,19678,250,200,18,297340],
  ["Hagia Sophia 032",1033,20711,255,196,19,312835],
  ["Hagia Sophia 033",1059,21770,265,193,19,328720],
  ["Hagia Sophia 034",1086,22856,275,192,20,345010],
  ["Hagia Sophia 035",1113,23969,285,161,21,361705],
  ["Hagia Sophia 036",1141,25110,295,161,21,378820],
  ["Hagia Sophia 037",1169,26279,305,160,22,396355],
  ["Hagia Sophia 038",1198,27477,315,151,22,414325],
  ["Hagia Sophia 039",1228,28705,325,152,23,432745],
  ["Hagia Sophia 040",1259,29964,335,155,24,451630],
  ["Hagia Sophia 041",1291,31255,345,149,24,470995],
  ["Hagia Sophia 042",1323,32578,360,144,25,490840],
  ["Hagia Sophia 043",1356,33934,370,148,25,511180],
  ["Hagia Sophia 044",1390,35324,380,144,26,532030],
  ["Hagia Sophia 045",1424,36748,390,149,27,553390],
  ["Hagia Sophia 046",1460,38208,400,157,27,575290],
  ["Hagia Sophia 047",1497,39705,410,147,28,597745],
  ["Hagia Sophia 048",1534,41239,420,156,28,620755],
  ["Hagia Sophia 049",1572,42811,430,165,29,644335],
  ["Hagia Sophia 050",1612,44423,440,167,30,668515],
  ["Hagia Sophia 051",1652,46075,450,178,30,693295],
  ["Hagia Sophia 052",1693,47768,465,162,31,718690],
  ["Hagia Sophia 053",1735,49503,475,176,31,744715],
  ["Hagia Sophia 054",1779,51282,485,191,32,771400],
  ["Hagia Sophia 055",1823,53105,495,197,33,798745],
  ["Hagia Sophia 056",1869,54974,505,214,33,826780],
  ["Hagia Sophia 057",1916,56890,515,233,34,855520],
  ["Hagia Sophia 058",1963,58853,525,232,34,884965],
  ["Hagia Sophia 059",2012,60865,540,244,35,915145],
  ["Hagia Sophia 060",2063,62928,550,266,36,946090],
  ["Hagia Sophia 061",2114,65042,560,279,36,977800],
  ["Hagia Sophia 062",2167,67209,570,303,37,1010305],
  ["Hagia Sophia 063",2221,69430,585,300,37,1043620],
  ["Hagia Sophia 064",2277,71707,595,328,38,1077775],
  ["Hagia Sophia 065",2334,74041,605,356,39,1112785],
  ["Hagia Sophia 066",2392,76433,615,376,39,1148665],
  ["Hagia Sophia 067",2452,78885,625,407,40,1185445],
  ["Hagia Sophia 068",2513,81398,640,431,40,1223140],
  ["Hagia Sophia 069",2576,83974,650,447,41,1261780],
  ["Hagia Sophia 070",2640,86614,660,483,42,1301380],
  ["Hagia Sophia 071",2706,89320,675,491,42,1341970],
  ["Hagia Sophia 072",2774,92094,685,530,43,1383580],
  ["Hagia Sophia 073",2843,94937,695,571,43,1426225],
  ["Hagia Sophia 074",2914,97851,705,604,44,1469935],
  ["Hagia Sophia 075",2987,100838,720,640,45,1514740],
  ["Hagia Sophia 076",3062,103900,730,686,45,1560670],
  ["Hagia Sophia 077",3138,107038,740,724,46,1607740],
  ["Hagia Sophia 078",3217,110255,755,755,46,1655995],
  ["Hagia Sophia 079",3297,113552,765,787,47,1705450],
  ["Hagia Sophia 080",3380,116932,775,842,48,1756150],
  ["Hagia Sophia 081",3464,120396,790,888,48,1808110],
  ["Hagia Sophia 082",3551,123947,800,937,49,1861375],
  ["Hagia Sophia 083",3640,127587,810,997,49,1915975],
  ["Hagia Sophia 084",3731,131300,825,1031,50,1971670],
  ["Hagia Sophia 085",3824,135124,835,1096,51,2029030],
  ["Hagia Sophia 086",3919,139043,850,1153,51,2087815],
  ["Hagia Sophia 087",4017,143060,860,1213,52,2148070],
  ["Hagia Sophia 088",4118,147178,875,1266,52,2209840],
  ["Hagia Sophia 089",4221,151399,885,1313,53,2273155],
  ["Hagia Sophia 090",4326,155725,895,1390,54,2338045],
  ["Hagia Sophia 091",4434,160159,910,1460,54,2404555],
  ["Hagia Sophia 092",4545,164704,920,1533,55,2472730],
  ["Hagia Sophia 093",4659,169363,930,1618,55,2542615],
  ["Hagia Sophia 094",4775,174138,945,1677,56,2614240],
  ["Hagia Sophia 095",4895,179033,955,1769,57,2687665],
  ["Hagia Sophia 096",5017,184050,970,1853,57,2762920],
  ["Hagia Sophia 097",5142,189192,980,1940,58,2840050],
  ["Hagia Sophia 098",5271,194463,995,2021,58,2919115],
  ["Hagia Sophia 099",5403,199866,1005,2105,59,3000160],
  ["Hagia Sophia 100",5538,205404,1015,2212,60,3083230],
  ["Hagia Sophia 101",5676,211080,1030,2312,60,3168370],
  ["Hagia Sophia 102",5818,216898,1040,2416,61,3255640],
  ["Hagia Sophia 103",5964,222862,1055,2514,61,3345100],
  ["Hagia Sophia 104",6113,228975,1065,2625,62,3436795],
  ["Hagia Sophia 105",6265,235240,1080,2740,63,3530770],
  ["Hagia Sophia 106",6422,241662,1090,2868,63,3627100],
  ["Hagia Sophia 107",6583,248245,1105,2971,64,3725845],
  ["Hagia Sophia 108",6747,254992,1115,3107,64,3827050],
  ["Hagia Sophia 109",6916,261908,1130,3220,65,3930790],
  ["Hagia Sophia 110",7089,268997,1140,3365,66,4037125],
  ["Hagia Sophia 111",7266,276263,1155,3484,66,4146115],
  ["Hagia Sophia 112",7448,283711,1165,3637,67,4257835],
  ["Hagia Sophia 113",7634,291345,1180,3786,67,4372345],
  ["Hagia Sophia 114",7825,299170,1190,3939,68,4489720],
  ["Hagia Sophia 115",8020,307190,1205,4086,69,4610020],
  ["Hagia Sophia 116",8221,315411,1215,4249,69,4733335],
  ["Hagia Sophia 117",8426,323837,1230,4416,70,4859725],
  ["Hagia Sophia 118",8637,332474,1240,4599,70,4989280],
  ["Hagia Sophia 119",8853,341327,1255,4748,71,5122075],
  ["Hagia Sophia 120",9074,350401,1265,4940,72,5258185],
  ["Hagia Sophia 121",9301,359702,1280,5120,72,5397700],
  ["Hagia Sophia 122",9533,369235,1290,5323,73,5540695],
  ["Hagia Sophia 123",9772,379007,1305,5505,73,5687275],
  ["Hagia Sophia 124",10016,389023,1320,5712,74,5837515],
  ["Hagia Sophia 125",10266,399289,1330,5933,75,5991505],
  ["Hagia Sophia 126",10523,409812,1345,6132,75,6149350],
  ["Hagia Sophia 127",10786,420598,1355,6367,76,6311140],
  ["Hagia Sophia 128",11056,431654,1370,6581,76,6476980],
  ["Hagia Sophia 129",11332,442986,1380,6829,77,6646960],
  ["Hagia Sophia 130",11615,454601,1395,7054,78,6821185],
  ["Hagia Sophia 131",11906,466507,1405,7316,78,6999775],
  ["Innovation Tower 000",0,0,0,0,0,45225],
  ["Innovation Tower 001",60,60,10,31,1,46125],
  ["Innovation Tower 002",100,160,15,42,1,47625],
  ["Innovation Tower 003",180,340,20,113,2,50325],
  ["Innovation Tower 004",270,610,30,174,2,54375],
  ["Innovation Tower 005",360,970,40,236,3,59775],
  ["Innovation Tower 006",460,1430,55,269,3,66675],
  ["Innovation Tower 007",560,1990,65,340,4,75075],
  ["Innovation Tower 008",670,2660,75,412,4,85125],
  ["Innovation Tower 009",770,3430,85,483,5,96675],
  ["Innovation Tower 010",880,4310,100,556,6,109875],
  ["Innovation Tower 011",902,5212,115,521,6,123405],
  ["Innovation Tower 012",925,6137,125,515,7,137280],
  ["Innovation Tower 013",948,7085,140,491,7,151500],
  ["Innovation Tower 014",972,8057,150,486,8,166080],
  ["Innovation Tower 015",996,9053,165,444,9,181020],
  ["Innovation Tower 016",1021,10074,180,432,9,196335],
  ["Innovation Tower 017",1047,11121,190,429,10,212040],
  ["Innovation Tower 018",1073,12194,205,397,10,228135],
  ["Innovation Tower 019",1099,13293,220,386,11,244620],
  ["Innovation Tower 020",1127,14420,235,357,12,261525],
  ["Innovation Tower 021",1155,15575,250,347,12,278850],
  ["Innovation Tower 022",1184,16759,265,318,13,296610],
  ["Innovation Tower 023",1214,17973,280,311,13,314820],
  ["Innovation Tower 024",1244,19217,290,283,14,333480],
  ["Innovation Tower 025",1275,20492,305,266,15,352605],
  ["Innovation Tower 026",1307,21799,320,251,15,372210],
  ["Innovation Tower 027",1340,23139,335,236,16,392310],
  ["Innovation Tower 028",1373,24512,355,203,17,412905],
  ["Innovation Tower 029",1407,25919,365,208,17,434010],
  ["Innovation Tower 030",1442,27361,385,176,18,455640],
  ["Innovation Tower 031",1479,28840,400,176,18,477825],
  ["Innovation Tower 032",1515,30355,415,146,19,500550],
  ["Innovation Tower 033",1553,31908,430,146,19,523845],
  ["Innovation Tower 034",1592,33500,445,127,20,547725],
  ["Innovation Tower 035",1632,35132,460,130,21,572205],
  ["Innovation Tower 036",1673,36805,480,105,21,597300],
  ["Innovation Tower 037",1715,38520,495,89,22,623025],
  ["Innovation Tower 038",1757,40277,510,93,22,649380],
  ["Innovation Tower 039",1801,42078,525,70,23,676395],
  ["Innovation Tower 040",1846,43924,545,58,24,704085],
  ["Innovation Tower 041",1893,45817,560,58,24,732480],
  ["Innovation Tower 042",1940,47757,575,57,25,761580],
  ["Innovation Tower 043",1988,49745,590,58,25,791400],
  ["Innovation Tower 044",2038,51783,610,51,26,821970],
  ["Innovation Tower 045",2089,53872,625,44,27,853305],
  ["Innovation Tower 046",2141,56013,645,21,27,885420],
  ["Innovation Tower 047",2195,58208,660,38,28,918345],
  ["Innovation Tower 048",2250,60458,675,35,28,952095],
  ["Innovation Tower 049",2306,62764,695,34,29,986685],
  ["Innovation Tower 050",2363,65127,710,44,30,1022130],
  ["Innovation Tower 051",2422,67549,730,46,30,1058460],
  ["Innovation Tower 052",2483,70032,745,49,31,1095705],
  ["Innovation Tower 053",2545,72577,765,35,31,1133880],
  ["Innovation Tower 054",2609,75186,780,62,32,1173015],
  ["Innovation Tower 055",2674,77860,800,60,33,1213125],
  ["Innovation Tower 056",2741,80601,815,79,33,1254240],
  ["Innovation Tower 057",2809,83410,835,81,34,1296375],
  ["Innovation Tower 058",2879,86289,850,113,34,1339560],
  ["Innovation Tower 059",2951,89240,870,118,35,1383825],
  ["Innovation Tower 060",3025,92265,885,117,36,1429200],
  ["Innovation Tower 061",3101,95366,905,136,36,1475715],
  ["Innovation Tower 062",3178,98544,920,166,37,1523385],
  ["Innovation Tower 063",3258,101802,940,189,37,1572255],
  ["Innovation Tower 064",3339,105141,960,204,38,1622340],
  ["Innovation Tower 065",3423,108564,975,230,39,1673685],
  ["Innovation Tower 066",3508,112072,995,258,39,1726305],
  ["Innovation Tower 067",3596,115668,1015,270,40,1780245],
  ["Innovation Tower 068",3686,119354,1030,322,40,1835535],
  ["Innovation Tower 069",3778,123132,1050,347,41,1892205],
  ["Innovation Tower 070",3872,127004,1070,375,42,1950285],
  ["Innovation Tower 071",3969,130973,1085,424,42,2009820],
  ["Innovation Tower 072",4068,135041,1105,456,43,2070840],
  ["Innovation Tower 073",4170,139211,1125,483,43,2133390],
  ["Innovation Tower 074",4274,143485,1140,550,44,2197500],
  ["Innovation Tower 075",4381,147866,1160,590,45,2263215],
  ["Innovation Tower 076",4491,152357,1180,643,45,2330580],
  ["Innovation Tower 077",4603,156960,1200,689,46,2399625],
  ["Innovation Tower 078",4718,161678,1215,746,46,2470395],
  ["Innovation Tower 079",4836,166514,1235,807,47,2542935],
  ["Innovation Tower 080",4957,171471,1255,852,48,2617290],
  ["Innovation Tower 081",5081,176552,1275,909,48,2693505],
  ["Innovation Tower 082",5208,181760,1290,998,49,2771625],
  ["Innovation Tower 083",5338,187098,1310,1062,49,2851695],
  ["Innovation Tower 084",5471,192569,1330,1138,50,2933760],
  ["Innovation Tower 085",5608,198177,1350,1208,51,3017880],
  ["Innovation Tower 086",5748,203925,1370,1273,51,3104100],
  ["Innovation Tower 087",5892,209817,1390,1360,52,3192480],
  ["Innovation Tower 088",6039,215856,1410,1440,52,3283065],
  ["Innovation Tower 089",6190,222046,1425,1534,53,3375915],
  ["Innovation Tower 090",6345,228391,1445,1632,54,3471090],
  ["Innovation Tower 091",6504,234895,1465,1724,54,3568650],
  ["Innovation Tower 092",6666,241561,1485,1800,55,3668640],
  ["Innovation Tower 093",6833,248394,1505,1910,55,3771135],
  ["Innovation Tower 094",7004,255398,1525,2014,56,3876195],
  ["Innovation Tower 095",7179,262577,1545,2123,57,3983880],
  ["Innovation Tower 096",7358,269935,1565,2245,57,4094250],
  ["Innovation Tower 097",7542,277477,1580,2382,58,4207380],
  ["Innovation Tower 098",7731,285208,1600,2514,58,4323345],
  ["Innovation Tower 099",7924,293132,1625,2612,59,4442205],
  ["Innovation Tower 100",8122,301254,1640,2763,60,4564035],
  ["Innovation Tower 101",8325,309579,1660,2909,60,4688910],
  ["Innovation Tower 102",8533,318112,1680,3051,61,4816905],
  ["Innovation Tower 103",8746,326858,1700,3197,61,4948095],
  ["Innovation Tower 104",8965,335823,1720,3359,62,5082570],
  ["Innovation Tower 105",9189,345012,1740,3507,63,5220405],
  ["Innovation Tower 106",9419,354431,1760,3670,63,5361690],
  ["Innovation Tower 107",9654,364085,1780,3848,64,5506500],
  ["Innovation Tower 108",9896,373981,1800,4024,64,5654940],
  ["Innovation Tower 109",10143,384124,1820,4204,65,5807085],
  ["Innovation Tower 110",10397,394521,1840,4401,66,5963040],
  ["Innovation Tower 111",10656,405177,1860,4585,66,6122880],
  ["Innovation Tower 112",10923,416100,1880,4785,67,6286725],
  ["Innovation Tower 113",11196,427296,1900,5001,67,6454665],
  ["Innovation Tower 114",11476,438772,1920,5215,68,6626805],
  ["Innovation Tower 115",11763,450535,1945,5415,69,6803250],
  ["Innovation Tower 116",12057,462592,1965,5633,69,6984105],
  ["Innovation Tower 117",12358,474950,1985,5877,70,7169475],
  ["Innovation Tower 118",12667,487617,2005,6119,70,7359480],
  ["Innovation Tower 119",12984,500601,2025,6370,71,7554240],
  ["Innovation Tower 120",13308,513909,2045,6637,72,7753860],
  ["Innovation Tower 121",13641,527550,2065,6903,72,7958475],
  ["Kapitol 000",0,0,0,0,0,11750],
  ["Kapitol 001",60,60,10,31,0,12650],
  ["Kapitol 002",90,150,10,61,0,14000],
  ["Kapitol 003",150,300,20,83,0,16250],
  ["Kapitol 004",240,540,25,153,0,19850],
  ["Kapitol 005",330,870,35,215,0,24800],
  ["Kapitol 006",410,1280,45,247,0,30950],
  ["Kapitol 007",500,1780,50,328,0,38450],
  ["Kapitol 008",600,2380,60,400,0,47450],
  ["Kapitol 009",690,3070,70,461,0,57800],
  ["Kapitol 010",790,3860,80,523,0,69650],
  ["Kapitol 011",810,4670,90,514,0,81800],
  ["Kapitol 012",830,5500,100,506,0,94250],
  ["Kapitol 013",851,6351,115,470,0,107015],
  ["Kapitol 014",873,7224,120,483,0,120110],
  ["Kapitol 015",894,8118,135,446,0,133520],
  ["Kapitol 016",917,9035,145,440,0,147275],
  ["Kapitol 017",940,9975,155,435,0,161375],
  ["Kapitol 018",963,10938,165,411,0,175820],
  ["Kapitol 019",987,11925,180,398,0,190625],
  ["Kapitol 020",1012,12937,190,394,0,205805],
  ["Kapitol 021",1037,13974,200,381,0,221360],
  ["Kapitol 022",1063,15037,215,359,0,237305],
  ["Kapitol 023",1090,16127,225,348,0,253655],
  ["Kapitol 024",1117,17244,235,347,0,270410],
  ["Kapitol 025",1145,18389,250,337,0,287585],
  ["Kapitol 026",1173,19562,260,327,0,305180],
  ["Kapitol 027",1203,20765,275,309,0,323225],
  ["Kapitol 028",1233,21998,285,281,0,341720],
  ["Kapitol 029",1263,23261,300,274,0,360665],
  ["Kapitol 030",1295,24556,310,277,0,380090],
  ["Kapitol 031",1327,25883,325,251,0,399995],
  ["Kapitol 032",1361,27244,335,257,0,420410],
  ["Kapitol 033",1395,28639,350,244,0,441335],
  ["Kapitol 034",1429,30068,360,250,0,462770],
  ["Kapitol 035",1465,31533,375,228,0,484745],
  ["Kapitol 036",1502,33035,390,227,0,507275],
  ["Kapitol 037",1539,34574,400,236,0,530360],
  ["Kapitol 038",1578,36152,415,209,0,554030],
  ["Kapitol 039",1617,37769,425,219,0,578285],
  ["Kapitol 040",1658,39427,440,213,0,603155],
  ["Kapitol 041",1699,41126,455,206,0,628640],
  ["Kapitol 042",1741,42867,465,210,0,654755],
  ["Kapitol 043",1785,44652,480,217,0,681530],
  ["Kapitol 044",1830,46482,495,204,0,708980],
  ["Kapitol 045",1875,48357,505,220,0,737105],
  ["Kapitol 046",1922,50279,520,230,0,765935],
  ["Kapitol 047",1970,52249,535,211,0,795485],
  ["Kapitol 048",2019,54268,550,222,0,825770],
  ["Kapitol 049",2070,56338,560,235,0,856820],
  ["Kapitol 050",2122,58460,575,239,0,888650],
  ["Kapitol 051",2175,60635,590,245,0,921275],
  ["Kapitol 052",2229,62864,605,251,0,954710],
  ["Kapitol 053",2285,65149,620,260,0,988985],
  ["Kapitol 054",2342,67491,635,269,0,1024115],
  ["Kapitol 055",2400,69891,645,280,0,1060115],
  ["Kapitol 056",2460,72351,660,303,0,1097015],
  ["Kapitol 057",2522,74873,675,307,0,1134845],
  ["Kapitol 058",2585,77458,690,332,0,1173620],
  ["Kapitol 059",2650,80108,705,340,0,1213370],
  ["Kapitol 060",2716,82824,720,369,0,1254110],
  ["Kapitol 061",2784,85608,735,379,0,1295870],
  ["Kapitol 062",2853,88461,745,419,0,1338665],
  ["Kapitol 063",2925,91386,760,454,0,1382540],
  ["Kapitol 064",2998,94384,775,460,0,1427510],
  ["Kapitol 065",3073,97457,790,497,0,1473605],
  ["Kapitol 066",3149,100606,805,515,0,1520840],
  ["Kapitol 067",3228,103834,820,557,0,1569260],
  ["Kapitol 068",3309,107143,835,581,0,1618895],
  ["Kapitol 069",3392,110535,850,626,0,1669775],
  ["Kapitol 070",3476,114011,865,652,0,1721915],
  ["Kapitol 071",3563,117574,880,702,0,1775360],
  ["Kapitol 072",3652,121226,895,716,0,1830140],
  ["Kapitol 073",3744,124970,910,770,0,1886300],
  ["Kapitol 074",3837,128807,925,805,0,1943855],
  ["Kapitol 075",3933,132740,940,864,0,2002850],
  ["Kapitol 076",4031,136771,955,905,0,2063315],
  ["Kapitol 077",4132,140903,970,968,0,2125295],
  ["Kapitol 078",4235,145138,985,1013,0,2188820],
  ["Kapitol 079",4341,149479,1000,1082,0,2253935],
  ["Kapitol 080",4450,153929,1015,1124,0,2320685],
  ["Kapitol 081",4561,158490,1030,1197,0,2389100],
  ["Kapitol 082",4675,163165,1045,1253,0,2459225],
  ["Kapitol 083",4792,167957,1065,1304,0,2531105],
  ["Kapitol 084",4912,172869,1075,1396,0,2604785],
  ["Kapitol 085",5035,177904,1095,1452,0,2680310],
  ["Kapitol 086",5160,183064,1110,1539,0,2757710],
  ["Kapitol 087",5289,188353,1125,1602,0,2837045],
  ["Kapitol 088",5422,193775,1140,1698,0,2918375],
  ["Kapitol 089",5557,199332,1155,1775,0,3001730],
  ["Kapitol 090",5696,205028,1170,1876,0,3087170],
  ["Kapitol 091",5838,210866,1185,1961,0,3174740],
  ["Kapitol 092",5984,216850,1200,2070,0,3264500],
  ["Kapitol 093",6134,222984,1220,2153,0,3356510],
  ["Kapitol 094",6287,229271,1235,2258,0,3450815],
  ["Kapitol 095",6445,235716,1250,2359,0,3547490],
  ["Kapitol 096",6606,242322,1265,2472,0,3646580],
  ["Kapitol 097",6771,249093,1280,2590,0,3748145],
  ["Kapitol 098",6940,256033,1300,2702,0,3852245],
  ["Kapitol 099",7113,263146,1315,2818,0,3958940],
  ["Kapitol 100",7291,270437,1330,2958,0,4068305],
  ["Kapitol 101",7474,277911,1345,3083,0,4180415],
  ["Kapitol 102",7660,285571,1360,3232,0,4295315],
  ["Kapitol 103",7852,293423,1375,3358,0,4413095],
  ["Kolosseum 000",0,0,0,0,0,1068],
  ["Kolosseum 001",40,40,5,20,0,1668],
  ["Kolosseum 002",60,100,10,31,0,2568],
  ["Kolosseum 003",120,220,15,62,0,4368],
  ["Kolosseum 004",170,390,20,103,0,6918],
  ["Kolosseum 005",220,610,25,133,0,10218],
  ["Kolosseum 006",290,900,30,194,0,14568],
  ["Kolosseum 007",360,1260,40,236,0,19968],
  ["Kolosseum 008",420,1680,45,257,0,26268],
  ["Kolosseum 009",490,2170,50,318,0,33618],
  ["Kolosseum 010",550,2720,60,350,0,41868],
  ["Kolosseum 011",564,3284,65,344,0,50328],
  ["Kolosseum 012",578,3862,70,349,0,58998],
  ["Kolosseum 013",593,4455,80,326,0,67893],
  ["Kolosseum 014",608,5063,85,321,0,77013],
  ["Kolosseum 015",623,5686,95,308,0,86358],
  ["Kolosseum 016",638,6324,105,285,0,95928],
  ["Kolosseum 017",654,6978,110,292,0,105738],
  ["Kolosseum 018",671,7649,120,281,0,115803],
  ["Kolosseum 019",687,8336,125,277,0,126108],
  ["Kolosseum 020",705,9041,135,257,0,136683],
  ["Kolosseum 021",722,9763,145,245,0,147513],
  ["Kolosseum 022",740,10503,150,254,0,158613],
  ["Kolosseum 023",759,11262,160,245,0,169998],
  ["Kolosseum 024",778,12040,170,217,0,181668],
  ["Kolosseum 025",797,12837,175,217,0,193623],
  ["Kolosseum 026",817,13654,185,208,0,205878],
  ["Kolosseum 027",837,14491,195,190,0,218433],
  ["Kolosseum 028",858,15349,200,202,0,231303],
  ["Kolosseum 029",880,16229,210,195,0,244503],
  ["Kolosseum 030",902,17131,220,189,0,258033],
  ["Kolosseum 031",924,18055,230,173,0,271893],
  ["Kolosseum 032",947,19002,240,168,0,286098],
  ["Kolosseum 033",971,19973,245,172,0,300663],
  ["Kolosseum 034",995,20968,255,158,0,315588],
  ["Kolosseum 035",1020,21988,265,154,0,330888],
  ["Kolosseum 036",1046,23034,275,152,0,346578],
  ["Kolosseum 037",1072,24106,285,120,0,362658],
  ["Kolosseum 038",1099,25205,290,138,0,379143],
  ["Kolosseum 039",1126,26331,300,137,0,396033],
  ["Kolosseum 040",1154,27485,310,136,0,413343],
  ["Kolosseum 041",1183,28668,320,127,0,431088],
  ["Kolosseum 042",1213,29881,330,128,0,449283],
  ["Kolosseum 043",1243,31124,340,130,0,467928],
  ["Kolosseum 044",1274,32398,350,123,0,487038],
  ["Kolosseum 045",1306,33704,360,127,0,506628],
  ["Kolosseum 046",1338,35042,370,130,0,526698],
  ["Kolosseum 047",1372,36414,380,126,0,547278],
  ["Kolosseum 048",1406,37820,390,131,0,568368],
  ["Kolosseum 049",1441,39261,400,138,0,589983],
  ["Kolosseum 050",1477,40738,405,136,0,612138],
  ["Kolosseum 051",1514,42252,415,145,0,634848],
  ["Kolosseum 052",1552,43804,425,154,0,658128],
  ["Kolosseum 053",1591,45395,435,155,0,681993],
  ["Kolosseum 054",1631,47026,450,157,0,706458],
  ["Kolosseum 055",1671,48697,455,178,0,731523],
  ["Kolosseum 056",1713,50410,465,182,0,757218],
  ["Kolosseum 057",1756,52166,475,197,0,783558],
  ["Kolosseum 058",1800,53966,485,212,0,810558],
  ["Kolosseum 059",1845,55811,495,219,0,838233],
  ["Kolosseum 060",1891,57702,510,227,0,866598],
  ["Kolosseum 061",1938,59640,520,246,0,895668],
  ["Kolosseum 062",1987,61627,530,247,0,925473],
  ["Kolosseum 063",2036,63663,540,268,0,956013],
  ["Kolosseum 064",2087,65750,550,290,0,987318],
  ["Kolosseum 065",2139,67889,560,304,0,1019403],
  ["Kolosseum 066",2193,70082,570,329,0,1052298],
  ["Kolosseum 067",2248,72330,580,356,0,1086018],
  ["Kolosseum 068",2304,74634,590,374,0,1120578],
  ["Kolosseum 069",2361,76995,600,403,0,1155993],
  ["Kolosseum 070",2420,79415,610,433,0,1192293],
  ["Kolosseum 071",2481,81896,620,456,0,1229508],
  ["Kolosseum 072",2543,84439,630,489,0,1267653],
  ["Kolosseum 073",2606,87045,645,486,0,1306743],
  ["Kolosseum 074",2672,89717,655,524,0,1346823],
  ["Kolosseum 075",2738,92455,665,561,0,1387893],
  ["Kolosseum 076",2807,95262,675,592,0,1429998],
  ["Kolosseum 077",2877,98139,685,633,0,1473153],
  ["Kolosseum 078",2949,101088,695,677,0,1517388],
  ["Kolosseum 079",3023,104111,705,713,0,1562733],
  ["Kolosseum 080",3098,107209,720,751,0,1609203],
  ["Kolosseum 081",3176,110385,730,800,0,1656843],
  ["Kolosseum 082",3255,113640,740,841,0,1705668],
  ["Kolosseum 083",3336,116976,750,893,0,1755708],
  ["Kolosseum 084",3420,120396,760,949,0,1807008],
  ["Kolosseum 085",3505,123901,775,967,0,1859583],
  ["Kolosseum 086",3593,127494,785,1026,0,1913478],
  ["Kolosseum 087",3683,131177,795,1078,0,1968723],
  ["Kolosseum 088",3775,134952,805,1141,0,2025348],
  ["Kolosseum 089",3869,138821,815,1207,0,2083383],
  ["Kolosseum 090",3966,142787,825,1266,0,2142873],
  ["Kolosseum 091",4065,146852,840,1328,0,2203848],
  ["Kolosseum 092",4167,151019,850,1401,0,2266353],
  ["Kolosseum 093",4271,155290,860,1467,0,2330418],
  ["Kolosseum 094",4377,159667,870,1544,0,2396073],
  ["Kolosseum 095",4487,164154,885,1579,0,2463378],
  ["Kolosseum 096",4599,168753,895,1663,0,2532363],
  ["Kolosseum 097",4714,173467,905,1749,0,2603073],
  ["Kolosseum 098",4832,178299,915,1829,0,2675553],
  ["Kolosseum 099",4953,183252,930,1912,0,2749848],
  ["Kolosseum 100",5076,188328,940,2007,0,2825988],
  ["Kolosseum 101",5203,193531,950,2096,0,2904033],
  ["Kolosseum 102",5333,198864,960,2198,0,2984028],
  ["Kolosseum 103",5467,204331,975,2274,0,3066033],
  ["Kolosseum 104",5603,209934,985,2381,0,3150078],
  ["Kolosseum 105",5743,215677,995,2493,0,3236223],
  ["Leuchtturm von Alexandria 000",0,0,0,0,0,964],
  ["Leuchtturm von Alexandria 001",40,40,5,20,0,1564],
  ["Leuchtturm von Alexandria 002",60,100,10,31,0,2464],
  ["Leuchtturm von Alexandria 003",120,220,15,62,0,4264],
  ["Leuchtturm von Alexandria 004",170,390,20,103,0,6814],
  ["Leuchtturm von Alexandria 005",220,610,25,133,0,10114],
  ["Leuchtturm von Alexandria 006",290,900,30,194,0,14464],
  ["Leuchtturm von Alexandria 007",360,1260,40,236,0,19864],
  ["Leuchtturm von Alexandria 008",420,1680,45,257,0,26164],
  ["Leuchtturm von Alexandria 009",490,2170,50,318,0,33514],
  ["Leuchtturm von Alexandria 010",550,2720,60,350,0,41764],
  ["Leuchtturm von Alexandria 011",564,3284,65,344,0,50224],
  ["Leuchtturm von Alexandria 012",578,3862,70,349,0,58894],
  ["Leuchtturm von Alexandria 013",593,4455,80,326,0,67789],
  ["Leuchtturm von Alexandria 014",608,5063,85,321,0,76909],
  ["Leuchtturm von Alexandria 015",623,5686,95,308,0,86254],
  ["Leuchtturm von Alexandria 016",638,6324,105,285,0,95824],
  ["Leuchtturm von Alexandria 017",654,6978,110,292,0,105634],
  ["Leuchtturm von Alexandria 018",671,7649,120,281,0,115699],
  ["Leuchtturm von Alexandria 019",687,8336,125,277,0,126004],
  ["Leuchtturm von Alexandria 020",705,9041,135,257,0,136579],
  ["Leuchtturm von Alexandria 021",722,9763,145,245,0,147409],
  ["Leuchtturm von Alexandria 022",740,10503,150,254,0,158509],
  ["Leuchtturm von Alexandria 023",759,11262,160,245,0,169894],
  ["Leuchtturm von Alexandria 024",778,12040,170,217,0,181564],
  ["Leuchtturm von Alexandria 025",797,12837,175,217,0,193519],
  ["Leuchtturm von Alexandria 026",817,13654,185,208,0,205774],
  ["Leuchtturm von Alexandria 027",837,14491,195,190,0,218329],
  ["Leuchtturm von Alexandria 028",858,15349,200,202,0,231199],
  ["Leuchtturm von Alexandria 029",880,16229,210,195,0,244399],
  ["Leuchtturm von Alexandria 030",902,17131,220,189,0,257929],
  ["Leuchtturm von Alexandria 031",924,18055,230,173,0,271789],
  ["Leuchtturm von Alexandria 032",947,19002,240,168,0,285994],
  ["Leuchtturm von Alexandria 033",971,19973,245,172,0,300559],
  ["Leuchtturm von Alexandria 034",995,20968,255,158,0,315484],
  ["Leuchtturm von Alexandria 035",1020,21988,265,154,0,330784],
  ["Leuchtturm von Alexandria 036",1046,23034,275,152,0,346474],
  ["Leuchtturm von Alexandria 037",1072,24106,285,120,0,362554],
  ["Leuchtturm von Alexandria 038",1099,25205,290,138,0,379039],
  ["Leuchtturm von Alexandria 039",1126,26331,300,137,0,395929],
  ["Leuchtturm von Alexandria 040",1154,27485,310,136,0,413239],
  ["Leuchtturm von Alexandria 041",1183,28668,320,127,0,430984],
  ["Leuchtturm von Alexandria 042",1213,29881,330,128,0,449179],
  ["Leuchtturm von Alexandria 043",1243,31124,340,130,0,467824],
  ["Leuchtturm von Alexandria 044",1274,32398,350,123,0,486934],
  ["Leuchtturm von Alexandria 045",1306,33704,360,127,0,506524],
  ["Leuchtturm von Alexandria 046",1338,35042,370,130,0,526594],
  ["Leuchtturm von Alexandria 047",1372,36414,380,126,0,547174],
  ["Leuchtturm von Alexandria 048",1406,37820,390,131,0,568264],
  ["Leuchtturm von Alexandria 049",1441,39261,400,138,0,589879],
  ["Leuchtturm von Alexandria 050",1477,40738,405,136,0,612034],
  ["Leuchtturm von Alexandria 051",1514,42252,415,145,0,634744],
  ["Leuchtturm von Alexandria 052",1552,43804,425,154,0,658024],
  ["Leuchtturm von Alexandria 053",1591,45395,435,155,0,681889],
  ["Leuchtturm von Alexandria 054",1631,47026,450,157,0,706354],
  ["Leuchtturm von Alexandria 055",1671,48697,455,178,0,731419],
  ["Leuchtturm von Alexandria 056",1713,50410,465,182,0,757114],
  ["Leuchtturm von Alexandria 057",1756,52166,475,197,0,783454],
  ["Leuchtturm von Alexandria 058",1800,53966,485,212,0,810454],
  ["Leuchtturm von Alexandria 059",1845,55811,495,219,0,838129],
  ["Leuchtturm von Alexandria 060",1891,57702,510,227,0,866494],
  ["Leuchtturm von Alexandria 061",1938,59640,520,246,0,895564],
  ["Leuchtturm von Alexandria 062",1987,61627,530,247,0,925369],
  ["Leuchtturm von Alexandria 063",2036,63663,540,268,0,955909],
  ["Leuchtturm von Alexandria 064",2087,65750,550,290,0,987214],
  ["Leuchtturm von Alexandria 065",2139,67889,560,304,0,1019299],
  ["Leuchtturm von Alexandria 066",2193,70082,570,329,0,1052194],
  ["Leuchtturm von Alexandria 067",2248,72330,580,356,0,1085914],
  ["Leuchtturm von Alexandria 068",2304,74634,590,374,0,1120474],
  ["Leuchtturm von Alexandria 069",2361,76995,600,403,0,1155889],
  ["Leuchtturm von Alexandria 070",2420,79415,610,433,0,1192189],
  ["Leuchtturm von Alexandria 071",2481,81896,620,456,0,1229404],
  ["Leuchtturm von Alexandria 072",2543,84439,630,489,0,1267549],
  ["Leuchtturm von Alexandria 073",2606,87045,645,486,0,1306639],
  ["Leuchtturm von Alexandria 074",2672,89717,655,524,0,1346719],
  ["Leuchtturm von Alexandria 075",2738,92455,665,561,0,1387789],
  ["Leuchtturm von Alexandria 076",2807,95262,675,592,0,1429894],
  ["Leuchtturm von Alexandria 077",2877,98139,685,633,0,1473049],
  ["Leuchtturm von Alexandria 078",2949,101088,695,677,0,1517284],
  ["Leuchtturm von Alexandria 079",3023,104111,705,713,0,1562629],
  ["Leuchtturm von Alexandria 080",3098,107209,720,751,0,1609099],
  ["Leuchtturm von Alexandria 081",3176,110385,730,800,0,1656739],
  ["Leuchtturm von Alexandria 082",3255,113640,740,841,0,1705564],
  ["Leuchtturm von Alexandria 083",3336,116976,750,893,0,1755604],
  ["Leuchtturm von Alexandria 084",3420,120396,760,949,0,1806904],
  ["Leuchtturm von Alexandria 085",3505,123901,775,967,0,1859479],
  ["Leuchtturm von Alexandria 086",3593,127494,785,1026,0,1913374],
  ["Leuchtturm von Alexandria 087",3683,131177,795,1078,0,1968619],
  ["Leuchtturm von Alexandria 088",3775,134952,805,1141,0,2025244],
  ["Leuchtturm von Alexandria 089",3869,138821,815,1207,0,2083279],
  ["Leuchtturm von Alexandria 090",3966,142787,825,1266,0,2142769],
  ["Leuchtturm von Alexandria 091",4065,146852,840,1328,0,2203744],
  ["Leuchtturm von Alexandria 092",4167,151019,850,1401,0,2266249],
  ["Leuchtturm von Alexandria 093",4271,155290,860,1467,0,2330314],
  ["Leuchtturm von Alexandria 094",4377,159667,870,1544,0,2395969],
  ["Leuchtturm von Alexandria 095",4487,164154,885,1579,0,2463274],
  ["Leuchtturm von Alexandria 096",4599,168753,895,1663,0,2532259],
  ["Leuchtturm von Alexandria 097",4714,173467,905,1749,0,2602969],
  ["Leuchtturm von Alexandria 098",4832,178299,915,1829,0,2675449],
  ["Leuchtturm von Alexandria 099",4953,183252,930,1912,0,2749744],
  ["Leuchtturm von Alexandria 100",5076,188328,940,2007,0,2825884],
  ["Leuchtturm von Alexandria 101",5203,193531,950,2096,0,2903929],
  ["Leuchtturm von Alexandria 102",5333,198864,960,2198,0,2983924],
  ["Leuchtturm von Alexandria 103",5467,204331,975,2274,0,3065929],
  ["Leuchtturm von Alexandria 104",5603,209934,985,2381,0,3149974],
  ["Leuchtturm von Alexandria 105",5743,215677,995,2493,0,3236119],
  ["Lotustempel 000",0,0,0,0,0,45225],
  ["Lotustempel 001",60,60,10,31,0,46125],
  ["Lotustempel 002",100,160,15,42,0,47625],
  ["Lotustempel 003",180,340,20,113,0,50325],
  ["Lotustempel 004",270,610,30,174,0,54375],
  ["Lotustempel 005",360,970,40,236,0,59775],
  ["Lotustempel 006",460,1430,55,269,0,66675],
  ["Lotustempel 007",560,1990,65,340,0,75075],
  ["Lotustempel 008",670,2660,75,412,0,85125],
  ["Lotustempel 009",770,3430,85,483,0,96675],
  ["Lotustempel 010",880,4310,100,556,0,109875],
  ["Lotustempel 011",902,5212,115,521,0,123405],
  ["Lotustempel 012",925,6137,125,515,0,137280],
  ["Lotustempel 013",948,7085,140,491,0,151500],
  ["Lotustempel 014",972,8057,150,486,0,166080],
  ["Lotustempel 015",996,9053,165,444,0,181020],
  ["Lotustempel 016",1021,10074,180,432,0,196335],
  ["Lotustempel 017",1047,11121,190,429,0,212040],
  ["Lotustempel 018",1073,12194,205,397,0,228135],
  ["Lotustempel 019",1099,13293,220,386,0,244620],
  ["Lotustempel 020",1127,14420,235,357,0,261525],
  ["Lotustempel 021",1155,15575,250,347,0,278850],
  ["Lotustempel 022",1184,16759,265,318,0,296610],
  ["Lotustempel 023",1214,17973,280,311,0,314820],
  ["Lotustempel 024",1244,19217,290,283,0,333480],
  ["Lotustempel 025",1275,20492,305,266,0,352605],
  ["Lotustempel 026",1307,21799,320,251,0,372210],
  ["Lotustempel 027",1340,23139,335,236,0,392310],
  ["Lotustempel 028",1373,24512,355,203,0,412905],
  ["Lotustempel 029",1407,25919,365,208,0,434010],
  ["Lotustempel 030",1442,27361,385,176,0,455640],
  ["Lotustempel 031",1479,28840,400,176,0,477825],
  ["Lotustempel 032",1515,30355,415,146,0,500550],
  ["Lotustempel 033",1553,31908,430,146,0,523845],
  ["Lotustempel 034",1592,33500,445,127,0,547725],
  ["Lotustempel 035",1632,35132,460,130,0,572205],
  ["Lotustempel 036",1673,36805,480,105,0,597300],
  ["Lotustempel 037",1715,38520,495,89,0,623025],
  ["Lotustempel 038",1757,40277,510,93,0,649380],
  ["Lotustempel 039",1801,42078,525,70,0,676395],
  ["Lotustempel 040",1846,43924,545,58,0,704085],
  ["Lotustempel 041",1893,45817,560,58,0,732480],
  ["Lotustempel 042",1940,47757,575,57,0,761580],
  ["Lotustempel 043",1988,49745,590,58,0,791400],
  ["Lotustempel 044",2038,51783,610,51,0,821970],
  ["Lotustempel 045",2089,53872,625,44,0,853305],
  ["Lotustempel 046",2141,56013,645,21,0,885420],
  ["Lotustempel 047",2195,58208,660,38,0,918345],
  ["Lotustempel 048",2250,60458,675,35,0,952095],
  ["Lotustempel 049",2306,62764,695,34,0,986685],
  ["Lotustempel 050",2363,65127,710,44,0,1022130],
  ["Lotustempel 051",2422,67549,730,46,0,1058460],
  ["Lotustempel 052",2483,70032,745,49,0,1095705],
  ["Lotustempel 053",2545,72577,765,35,0,1133880],
  ["Lotustempel 054",2609,75186,780,62,0,1173015],
  ["Lotustempel 055",2674,77860,800,60,0,1213125],
  ["Lotustempel 056",2741,80601,815,79,0,1254240],
  ["Lotustempel 057",2809,83410,835,81,0,1296375],
  ["Lotustempel 058",2879,86289,850,113,0,1339560],
  ["Lotustempel 059",2951,89240,870,118,0,1383825],
  ["Lotustempel 060",3025,92265,885,117,0,1429200],
  ["Lotustempel 061",3101,95366,905,136,0,1475715],
  ["Lotustempel 062",3178,98544,920,166,0,1523385],
  ["Lotustempel 063",3258,101802,940,189,0,1572255],
  ["Lotustempel 064",3339,105141,960,204,0,1622340],
  ["Lotustempel 065",3423,108564,975,230,0,1673685],
  ["Lotustempel 066",3508,112072,995,258,0,1726305],
  ["Lotustempel 067",3596,115668,1015,270,0,1780245],
  ["Lotustempel 068",3686,119354,1030,322,0,1835535],
  ["Lotustempel 069",3778,123132,1050,347,0,1892205],
  ["Lotustempel 070",3872,127004,1070,375,0,1950285],
  ["Lotustempel 071",3969,130973,1085,424,0,2009820],
  ["Lotustempel 072",4068,135041,1105,456,0,2070840],
  ["Lotustempel 073",4170,139211,1125,483,0,2133390],
  ["Lotustempel 074",4274,143485,1140,550,0,2197500],
  ["Lotustempel 075",4381,147866,1160,590,0,2263215],
  ["Lotustempel 076",4491,152357,1180,643,0,2330580],
  ["Lotustempel 077",4603,156960,1200,689,0,2399625],
  ["Lotustempel 078",4718,161678,1215,746,0,2470395],
  ["Lotustempel 079",4836,166514,1235,807,0,2542935],
  ["Lotustempel 080",4957,171471,1255,852,0,2617290],
  ["Lotustempel 081",5081,176552,1275,909,0,2693505],
  ["Lotustempel 082",5208,181760,1290,998,0,2771625],
  ["Lotustempel 083",5338,187098,1310,1062,0,2851695],
  ["Lotustempel 084",5471,192569,1330,1138,0,2933760],
  ["Lotustempel 085",5608,198177,1350,1208,0,3017880],
  ["Lotustempel 086",5748,203925,1370,1273,0,3104100],
  ["Lotustempel 087",5892,209817,1390,1360,0,3192480],
  ["Lotustempel 088",6039,215856,1410,1440,0,3283065],
  ["Lotustempel 089",6190,222046,1425,1534,0,3375915],
  ["Lotustempel 090",6345,228391,1445,1632,0,3471090],
  ["Lotustempel 091",6504,234895,1465,1724,0,3568650],
  ["Lotustempel 092",6666,241561,1485,1800,0,3668640],
  ["Lotustempel 093",6833,248394,1505,1910,0,3771135],
  ["Lotustempel 094",7004,255398,1525,2014,0,3876195],
  ["Lotustempel 095",7179,262577,1545,2123,0,3983880],
  ["Lotustempel 096",7358,269935,1565,2245,0,4094250],
  ["Lotustempel 097",7542,277477,1580,2382,0,4207380],
  ["Lotustempel 098",7731,285208,1600,2514,0,4323345],
  ["Lotustempel 099",7924,293132,1625,2612,0,4442205],
  ["Lotustempel 100",8122,301254,1640,2763,0,4564035],
  ["Lotustempel 101",8325,309579,1660,2909,0,4688910],
  ["Lotustempel 102",8533,318112,1680,3051,0,4816905],
  ["Lotustempel 103",8746,326858,1700,3197,0,4948095],
  ["Lotustempel 104",8965,335823,1720,3359,0,5082570],
  ["Lotustempel 105",9189,345012,1740,3507,0,5220405],
  ["Lotustempel 106",9419,354431,1760,3670,0,5361690],
  ["Lotustempel 107",9654,364085,1780,3848,0,5506500],
  ["Lotustempel 108",9896,373981,1800,4024,0,5654940],
  ["Lotustempel 109",10143,384124,1820,4204,0,5807085],
  ["Lotustempel 110",10397,394521,1840,4401,0,5963040],
  ["Lotustempel 111",10656,405177,1860,4585,0,6122880],
  ["Lotustempel 112",10923,416100,1880,4785,0,6286725],
  ["Lotustempel 113",11196,427296,1900,5001,0,6454665],
  ["Lotustempel 114",11476,438772,1920,5215,0,6626805],
  ["Lotustempel 115",11763,450535,1945,5415,0,6803250],
  ["Lotustempel 116",12057,462592,1965,5633,0,6984105],
  ["Lotustempel 117",12358,474950,1985,5877,0,7169475],
  ["Lotustempel 118",12667,487617,2005,6119,0,7359480],
  ["Lotustempel 119",12984,500601,2025,6370,0,7554240],
  ["Lotustempel 120",13308,513909,2045,6637,0,7753860],
  ["Lotustempel 121",13641,527550,2065,6903,0,7958475],
  ["Markusdom 000",0,0,0,0,0,3720],
  ["Markusdom 001",50,50,5,30,0,4470],
  ["Markusdom 002",70,120,10,41,0,5520],
  ["Markusdom 003",130,250,15,72,0,7470],
  ["Markusdom 004",200,450,20,133,0,10470],
  ["Markusdom 005",270,720,30,174,0,14520],
  ["Markusdom 006",330,1050,35,215,0,19470],
  ["Markusdom 007",420,1470,45,257,0,25770],
  ["Markusdom 008",490,1960,50,318,0,33120],
  ["Markusdom 009",570,2530,60,370,0,41670],
  ["Markusdom 010",650,3180,65,430,0,51420],
  ["Markusdom 011",667,3847,75,409,0,61425],
  ["Markusdom 012",683,4530,85,396,0,71670],
  ["Markusdom 013",700,5230,95,385,0,82170],
  ["Markusdom 014",718,5948,100,394,0,92940],
  ["Markusdom 015",736,6684,110,374,0,103980],
  ["Markusdom 016",754,7438,120,364,0,115290],
  ["Markusdom 017",773,8211,130,354,0,126885],
  ["Markusdom 018",792,9003,140,335,0,138765],
  ["Markusdom 019",812,9815,150,326,0,150945],
  ["Markusdom 020",833,10643,155,328,0,163365],
  ["Markusdom 021",853,11496,165,301,0,176160],
  ["Markusdom 022",875,12371,175,295,0,189285],
  ["Markusdom 023",897,13268,185,288,0,202740],
  ["Markusdom 024",919,14187,195,272,0,216525],
  ["Markusdom 025",942,15129,205,266,0,230655],
  ["Markusdom 026",965,16094,215,261,0,245130],
  ["Markusdom 027",990,17084,225,248,0,259980],
  ["Markusdom 028",1014,18098,235,244,0,275190],
  ["Markusdom 029",1040,19138,245,241,0,290790],
  ["Markusdom 030",1066,20204,255,229,0,306780],
  ["Markusdom 031",1092,21296,265,226,0,323160],
  ["Markusdom 032",1120,22416,275,226,0,339960],
  ["Markusdom 033",1147,23563,285,195,0,357165],
  ["Markusdom 034",1176,24739,300,187,0,374805],
  ["Markusdom 035",1206,25945,310,188,0,392895],
  ["Markusdom 036",1236,27181,320,180,0,411435],
  ["Markusdom 037",1267,28448,330,182,0,430440],
  ["Markusdom 038",1298,29746,340,185,0,449910],
  ["Markusdom 039",1331,31077,350,180,0,469875],
  ["Markusdom 040",1364,32441,365,165,0,490335],
  ["Markusdom 041",1398,33839,375,161,0,511305],
  ["Markusdom 042",1433,35272,385,167,0,532800],
  ["Markusdom 043",1469,36741,395,175,0,554835],
  ["Markusdom 044",1505,38246,405,164,0,577410],
  ["Markusdom 045",1543,39789,420,165,0,600555],
  ["Markusdom 046",1582,41371,430,175,0,624285],
  ["Markusdom 047",1621,42992,440,176,0,648600],
  ["Markusdom 048",1662,44654,450,188,0,673530],
  ["Markusdom 049",1703,46357,465,172,0,699075],
  ["Markusdom 050",1746,48103,475,187,0,725265],
  ["Markusdom 051",1789,49892,485,201,0,752100],
  ["Markusdom 052",1834,51726,500,199,0,779610],
  ["Markusdom 053",1880,53606,510,216,0,807810],
  ["Markusdom 054",1927,55533,520,235,0,836715],
  ["Markusdom 055",1975,57508,535,216,0,866340],
  ["Markusdom 056",2025,59533,545,237,0,896715],
  ["Markusdom 057",2075,61608,555,249,0,927840],
  ["Markusdom 058",2127,63735,570,263,0,959745],
  ["Markusdom 059",2180,65915,580,288,0,992445],
  ["Markusdom 060",2235,68150,590,305,0,1025970],
  ["Markusdom 061",2290,70440,605,312,0,1060320],
  ["Markusdom 062",2348,72788,615,332,0,1095540],
  ["Markusdom 063",2406,75194,630,352,0,1131630],
  ["Markusdom 064",2467,77661,640,385,0,1168635],
  ["Markusdom 065",2528,80189,650,399,0,1206555],
  ["Markusdom 066",2591,82780,665,414,0,1245420],
  ["Markusdom 067",2656,85436,675,441,0,1285260],
  ["Markusdom 068",2723,88159,690,470,0,1326105],
  ["Markusdom 069",2791,90950,700,510,0,1367970],
  ["Markusdom 070",2860,93810,715,522,0,1410870],
  ["Markusdom 071",2932,96742,725,565,0,1454850],
  ["Markusdom 072",3005,99747,735,600,0,1499925],
  ["Markusdom 073",3080,102827,750,637,0,1546125],
  ["Markusdom 074",3157,105984,760,686,0,1593480],
  ["Markusdom 075",3236,109220,775,698,0,1642020],
  ["Markusdom 076",3317,112537,785,750,0,1691775],
  ["Markusdom 077",3400,115937,800,786,0,1742775],
  ["Markusdom 078",3485,119422,810,842,0,1795050],
  ["Markusdom 079",3572,122994,825,872,0,1848630],
  ["Markusdom 080",3661,126655,835,933,0,1903545],
  ["Markusdom 081",3753,130408,850,987,0,1959840],
  ["Markusdom 082",3847,134255,860,1043,0,2017545],
  ["Markusdom 083",3943,138198,875,1091,0,2076690],
  ["Markusdom 084",4041,142239,890,1124,0,2137305],
  ["Markusdom 085",4142,146381,900,1197,0,2199435],
  ["Markusdom 086",4246,150627,915,1243,0,2263125],
  ["Markusdom 087",4352,154979,925,1320,0,2328405],
  ["Markusdom 088",4461,159440,940,1392,0,2395320],
  ["Markusdom 089",4572,164012,950,1465,0,2463900],
  ["Markusdom 090",4687,168699,965,1532,0,2534205],
  ["Markusdom 091",4804,173503,975,1611,0,2606265],
  ["Markusdom 092",4924,178427,990,1693,0,2680125],
  ["Markusdom 093",5047,183474,1005,1749,0,2755830],
  ["Markusdom 094",5173,188647,1015,1847,0,2833425],
  ["Markusdom 095",5303,193950,1030,1939,0,2912970],
  ["Markusdom 096",5435,199385,1040,2033,0,2994495],
  ["Markusdom 097",5571,204956,1055,2121,0,3078060],
  ["Markusdom 098",5710,210666,1070,2213,0,3163710],
  ["Markusdom 099",5853,216519,1080,2328,0,3251505],
  ["Markusdom 100",5999,222518,1095,2416,0,3341490],
  ["Markusdom 101",6149,228667,1110,2528,0,3433725],
  ["Markusdom 102",6303,234970,1120,2654,0,3528270],
  ["Markusdom 103",6461,241431,1135,2746,0,3625185],
  ["Markusdom 104",6622,248053,1150,2869,0,3724515],
  ["Markusdom 105",6788,254841,1160,2997,0,3826335],
  ["Markusdom 106",6957,261798,1175,3118,0,3930690],
  ["Markusdom 107",7131,268929,1190,3245,0,4037655],
  ["Notre Dame 000",0,0,0,0,0,3480],
  ["Notre Dame 001",50,50,5,30,0,4230],
  ["Notre Dame 002",70,120,10,41,0,5280],
  ["Notre Dame 003",130,250,15,72,0,7230],
  ["Notre Dame 004",200,450,20,133,0,10230],
  ["Notre Dame 005",270,720,30,174,0,14280],
  ["Notre Dame 006",330,1050,35,215,0,19230],
  ["Notre Dame 007",420,1470,45,257,0,25530],
  ["Notre Dame 008",490,1960,50,318,0,32880],
  ["Notre Dame 009",570,2530,60,370,0,41430],
  ["Notre Dame 010",650,3180,65,430,0,51180],
  ["Notre Dame 011",667,3847,75,409,0,61185],
  ["Notre Dame 012",683,4530,85,396,0,71430],
  ["Notre Dame 013",700,5230,95,385,0,81930],
  ["Notre Dame 014",718,5948,100,394,0,92700],
  ["Notre Dame 015",736,6684,110,374,0,103740],
  ["Notre Dame 016",754,7438,120,364,0,115050],
  ["Notre Dame 017",773,8211,130,354,0,126645],
  ["Notre Dame 018",792,9003,140,335,0,138525],
  ["Notre Dame 019",812,9815,150,326,0,150705],
  ["Notre Dame 020",833,10643,155,328,0,163125],
  ["Notre Dame 021",853,11496,165,301,0,175920],
  ["Notre Dame 022",875,12371,175,295,0,189045],
  ["Notre Dame 023",897,13268,185,288,0,202500],
  ["Notre Dame 024",919,14187,195,272,0,216285],
  ["Notre Dame 025",942,15129,205,266,0,230415],
  ["Notre Dame 026",965,16094,215,261,0,244890],
  ["Notre Dame 027",990,17084,225,248,0,259740],
  ["Notre Dame 028",1014,18098,235,244,0,274950],
  ["Notre Dame 029",1040,19138,245,241,0,290550],
  ["Notre Dame 030",1066,20204,255,229,0,306540],
  ["Notre Dame 031",1092,21296,265,226,0,322920],
  ["Notre Dame 032",1120,22416,275,226,0,339720],
  ["Notre Dame 033",1147,23563,285,195,0,356925],
  ["Notre Dame 034",1176,24739,300,187,0,374565],
  ["Notre Dame 035",1206,25945,310,188,0,392655],
  ["Notre Dame 036",1236,27181,320,180,0,411195],
  ["Notre Dame 037",1267,28448,330,182,0,430200],
  ["Notre Dame 038",1298,29746,340,185,0,449670],
  ["Notre Dame 039",1331,31077,350,180,0,469635],
  ["Notre Dame 040",1364,32441,365,165,0,490095],
  ["Notre Dame 041",1398,33839,375,161,0,511065],
  ["Notre Dame 042",1433,35272,385,167,0,532560],
  ["Notre Dame 043",1469,36741,395,175,0,554595],
  ["Notre Dame 044",1505,38246,405,164,0,577170],
  ["Notre Dame 045",1543,39789,420,165,0,600315],
  ["Notre Dame 046",1582,41371,430,175,0,624045],
  ["Notre Dame 047",1621,42992,440,176,0,648360],
  ["Notre Dame 048",1662,44654,450,188,0,673290],
  ["Notre Dame 049",1703,46357,465,172,0,698835],
  ["Notre Dame 050",1746,48103,475,187,0,725025],
  ["Notre Dame 051",1789,49892,485,201,0,751860],
  ["Notre Dame 052",1834,51726,500,199,0,779370],
  ["Notre Dame 053",1880,53606,510,216,0,807570],
  ["Notre Dame 054",1927,55533,520,235,0,836475],
  ["Notre Dame 055",1975,57508,535,216,0,866100],
  ["Notre Dame 056",2025,59533,545,237,0,896475],
  ["Notre Dame 057",2075,61608,555,249,0,927600],
  ["Notre Dame 058",2127,63735,570,263,0,959505],
  ["Notre Dame 059",2180,65915,580,288,0,992205],
  ["Notre Dame 060",2235,68150,590,305,0,1025730],
  ["Notre Dame 061",2290,70440,605,312,0,1060080],
  ["Notre Dame 062",2348,72788,615,332,0,1095300],
  ["Notre Dame 063",2406,75194,630,352,0,1131390],
  ["Notre Dame 064",2467,77661,640,385,0,1168395],
  ["Notre Dame 065",2528,80189,650,399,0,1206315],
  ["Notre Dame 066",2591,82780,665,414,0,1245180],
  ["Notre Dame 067",2656,85436,675,441,0,1285020],
  ["Notre Dame 068",2723,88159,690,470,0,1325865],
  ["Notre Dame 069",2791,90950,700,510,0,1367730],
  ["Notre Dame 070",2860,93810,715,522,0,1410630],
  ["Notre Dame 071",2932,96742,725,565,0,1454610],
  ["Notre Dame 072",3005,99747,735,600,0,1499685],
  ["Notre Dame 073",3080,102827,750,637,0,1545885],
  ["Notre Dame 074",3157,105984,760,686,0,1593240],
  ["Notre Dame 075",3236,109220,775,698,0,1641780],
  ["Notre Dame 076",3317,112537,785,750,0,1691535],
  ["Notre Dame 077",3400,115937,800,786,0,1742535],
  ["Notre Dame 078",3485,119422,810,842,0,1794810],
  ["Notre Dame 079",3572,122994,825,872,0,1848390],
  ["Notre Dame 080",3661,126655,835,933,0,1903305],
  ["Notre Dame 081",3753,130408,850,987,0,1959600],
  ["Notre Dame 082",3847,134255,860,1043,0,2017305],
  ["Notre Dame 083",3943,138198,875,1091,0,2076450],
  ["Notre Dame 084",4041,142239,890,1124,0,2137065],
  ["Notre Dame 085",4142,146381,900,1197,0,2199195],
  ["Notre Dame 086",4246,150627,915,1243,0,2262885],
  ["Notre Dame 087",4352,154979,925,1320,0,2328165],
  ["Notre Dame 088",4461,159440,940,1392,0,2395080],
  ["Notre Dame 089",4572,164012,950,1465,0,2463660],
  ["Notre Dame 090",4687,168699,965,1532,0,2533965],
  ["Notre Dame 091",4804,173503,975,1611,0,2606025],
  ["Notre Dame 092",4924,178427,990,1693,0,2679885],
  ["Notre Dame 093",5047,183474,1005,1749,0,2755590],
  ["Notre Dame 094",5173,188647,1015,1847,0,2833185],
  ["Notre Dame 095",5303,193950,1030,1939,0,2912730],
  ["Notre Dame 096",5435,199385,1040,2033,0,2994255],
  ["Notre Dame 097",5571,204956,1055,2121,0,3077820],
  ["Notre Dame 098",5710,210666,1070,2213,0,3163470],
  ["Notre Dame 099",5853,216519,1080,2328,0,3251265],
  ["Notre Dame 100",5999,222518,1095,2416,0,3341250],
  ["Notre Dame 101",6149,228667,1110,2528,0,3433485],
  ["Notre Dame 102",6303,234970,1120,2654,0,3528030],
  ["Notre Dame 103",6461,241431,1135,2746,0,3624945],
  ["Notre Dame 104",6622,248053,1150,2869,0,3724275],
  ["Notre Dame 105",6788,254841,1160,2997,0,3826095],
  ["Notre Dame 106",6957,261798,1175,3118,0,3930450],
  ["Notre Dame 107",7131,268929,1190,3245,0,4037415],
  ["Observatorium 000",0,0,0,0,0,900],
  ["Observatorium 001",50,50,5,30,0,1650],
  ["Observatorium 002",70,120,10,41,0,2700],
  ["Observatorium 003",130,250,15,72,0,4650],
  ["Observatorium 004",200,450,20,133,0,7650],
  ["Observatorium 005",270,720,30,174,0,11700],
  ["Observatorium 006",330,1050,35,215,0,16650],
  ["Observatorium 007",420,1470,45,257,0,22950],
  ["Observatorium 008",490,1960,50,318,0,30300],
  ["Observatorium 009",570,2530,60,370,0,38850],
  ["Observatorium 010",650,3180,65,430,0,48600],
  ["Observatorium 011",667,3847,75,409,0,58605],
  ["Observatorium 012",683,4530,85,396,0,68850],
  ["Observatorium 013",700,5230,95,385,0,79350],
  ["Observatorium 014",718,5948,100,394,0,90120],
  ["Observatorium 015",736,6684,110,374,0,101160],
  ["Observatorium 016",754,7438,120,364,0,112470],
  ["Observatorium 017",773,8211,130,354,0,124065],
  ["Observatorium 018",792,9003,140,335,0,135945],
  ["Observatorium 019",812,9815,150,326,0,148125],
  ["Observatorium 020",833,10648,155,328,0,160620],
  ["Observatorium 021",853,11501,165,301,0,173415],
  ["Observatorium 022",875,12376,175,295,0,186540],
  ["Observatorium 023",897,13273,185,288,0,199995],
  ["Observatorium 024",919,14192,195,272,0,213780],
  ["Observatorium 025",942,15134,205,266,0,227910],
  ["Observatorium 026",965,16099,215,261,0,242385],
  ["Observatorium 027",990,17089,225,248,0,257235],
  ["Observatorium 028",1014,18103,235,244,0,272445],
  ["Observatorium 029",1040,19143,245,241,0,288045],
  ["Observatorium 030",1066,20209,255,229,0,304035],
  ["Observatorium 031",1092,21301,265,226,0,320415],
  ["Observatorium 032",1120,22421,275,226,0,337215],
  ["Observatorium 033",1147,23568,285,195,0,354420],
  ["Observatorium 034",1176,24744,300,187,0,372060],
  ["Observatorium 035",1206,25950,310,188,0,390150],
  ["Observatorium 036",1236,27186,320,180,0,408690],
  ["Observatorium 037",1267,28453,330,182,0,427695],
  ["Observatorium 038",1298,29751,340,185,0,447165],
  ["Observatorium 039",1331,31082,350,180,0,467130],
  ["Observatorium 040",1364,32446,365,165,0,487590],
  ["Observatorium 041",1398,33844,375,161,0,508560],
  ["Observatorium 042",1433,35277,385,167,0,530055],
  ["Observatorium 043",1469,36746,395,175,0,552090],
  ["Observatorium 044",1505,38251,405,164,0,574665],
  ["Observatorium 045",1543,39794,420,165,0,597810],
  ["Observatorium 046",1582,41376,430,175,0,621540],
  ["Observatorium 047",1621,42997,440,176,0,645855],
  ["Observatorium 048",1662,44659,450,188,0,670785],
  ["Observatorium 049",1703,46362,465,172,0,696330],
  ["Observatorium 050",1746,48108,475,187,0,722520],
  ["Observatorium 051",1789,49897,485,201,0,749355],
  ["Observatorium 052",1834,51731,500,199,0,776865],
  ["Observatorium 053",1880,53611,510,216,0,805065],
  ["Observatorium 054",1927,55538,520,235,0,833970],
  ["Observatorium 055",1975,57513,535,216,0,863595],
  ["Observatorium 056",2025,59538,545,237,0,893970],
  ["Observatorium 057",2075,61613,555,249,0,925095],
  ["Observatorium 058",2127,63740,570,263,0,957000],
  ["Observatorium 059",2180,65920,580,288,0,989700],
  ["Observatorium 060",2235,68155,590,305,0,1023225],
  ["Observatorium 061",2290,70445,605,312,0,1057575],
  ["Observatorium 062",2348,72793,615,332,0,1092795],
  ["Observatorium 063",2406,75199,630,352,0,1128885],
  ["Observatorium 064",2467,77666,640,385,0,1165890],
  ["Observatorium 065",2528,80194,650,399,0,1203810],
  ["Observatorium 066",2591,82785,665,414,0,1242675],
  ["Observatorium 067",2656,85441,675,441,0,1282515],
  ["Observatorium 068",2723,88164,690,470,0,1323360],
  ["Observatorium 069",2791,90955,700,510,0,1365225],
  ["Observatorium 070",2860,93815,715,522,0,1408125],
  ["Observatorium 071",2932,96747,725,565,0,1452105],
  ["Observatorium 072",3005,99752,735,600,0,1497180],
  ["Observatorium 073",3080,102832,750,637,0,1543380],
  ["Observatorium 074",3157,105989,760,686,0,1590735],
  ["Observatorium 075",3236,109225,775,698,0,1639275],
  ["Observatorium 076",3317,112542,785,750,0,1689030],
  ["Observatorium 077",3400,115942,800,786,0,1740030],
  ["Observatorium 078",3485,119427,810,842,0,1792305],
  ["Observatorium 079",3572,122999,825,872,0,1845885],
  ["Observatorium 080",3661,126660,835,933,0,1900800],
  ["Observatorium 081",3753,130413,850,987,0,1957095],
  ["Observatorium 082",3847,134260,860,1043,0,2014800],
  ["Observatorium 083",3943,138183,875,1091,0,2073645],
  ["Observatorium 084",4041,142224,890,1124,0,2134260],
  ["Observatorium 085",4142,146366,900,1197,0,2196390],
  ["Observatorium 086",4246,150612,915,1243,0,2260080],
  ["Observatorium 087",4352,154964,925,1320,0,2325360],
  ["Observatorium 088",4461,159425,940,1392,0,2392275],
  ["Observatorium 089",4572,163997,950,1465,0,2460855],
  ["Observatorium 090",4687,168684,965,1532,0,2531160],
  ["Observatorium 091",4804,173488,975,1611,0,2603220],
  ["Observatorium 092",4924,178412,990,1693,0,2677080],
  ["Observatorium 093",5047,183459,1005,1749,0,2752785],
  ["Observatorium 094",5173,188632,1015,1847,0,2830380],
  ["Observatorium 095",5303,193935,1030,1939,0,2909925],
  ["Observatorium 096",5435,199370,1040,2033,0,2991450],
  ["Observatorium 097",5571,204941,1055,2121,0,3075015],
  ["Observatorium 098",5710,210651,1070,2213,0,3160665],
  ["Observatorium 099",5853,216504,1080,2328,0,3248460],
  ["Observatorium 100",5999,222503,1095,2416,0,3338445],
  ["Observatorium 101",6149,228652,1110,2528,0,3430680],
  ["Observatorium 102",6303,234955,1120,2654,0,3525225],
  ["Observatorium 103",6461,241416,1135,2746,0,3622140],
  ["Observatorium 104",6622,248038,1150,2869,0,3721470],
  ["Observatorium 105",6788,254826,1160,2997,0,3823290],
  ["Observatorium 106",6957,261783,1175,3118,0,3927645],
  ["Observatorium 107",7131,268914,1190,3245,0,4034610],
  ["Orakel von Delphi 000",0,0,0,0,0,900],
  ["Orakel von Delphi 001",20,20,5,0,0,1200],
  ["Orakel von Delphi 002",40,60,10,11,0,1800],
  ["Orakel von Delphi 003",60,120,15,2,0,2700],
  ["Orakel von Delphi 004",130,250,20,63,0,4650],
  ["Orakel von Delphi 005",210,460,30,114,0,7800],
  ["Orakel von Delphi 006",290,750,35,175,0,12150],
  ["Orakel von Delphi 007",370,1120,45,207,0,17700],
  ["Orakel von Delphi 008",460,1580,50,288,0,24600],
  ["Orakel von Delphi 009",550,2130,60,350,0,32850],
  ["Orakel von Delphi 010",650,2780,65,430,0,42600],
  ["Orakel von Delphi 011",667,3447,75,409,0,52605],
  ["Orakel von Delphi 012",683,4130,85,396,0,62850],
  ["Orakel von Delphi 013",700,4830,95,385,0,73350],
  ["Orakel von Delphi 014",718,5548,100,394,0,84120],
  ["Orakel von Delphi 015",736,6284,110,374,0,95160],
  ["Orakel von Delphi 016",754,7038,120,364,0,106470],
  ["Orakel von Delphi 017",773,7811,130,354,0,118065],
  ["Orakel von Delphi 018",792,8603,140,335,0,129945],
  ["Orakel von Delphi 019",812,9415,150,326,0,142125],
  ["Orakel von Delphi 020",833,10248,155,328,0,154620],
  ["Orakel von Delphi 021",853,11101,165,301,0,167415],
  ["Orakel von Delphi 022",875,11976,175,295,0,180540],
  ["Orakel von Delphi 023",897,12873,185,288,0,193995],
  ["Orakel von Delphi 024",919,13792,195,272,0,207780],
  ["Orakel von Delphi 025",942,14734,205,266,0,221910],
  ["Orakel von Delphi 026",965,15699,215,261,0,236385],
  ["Orakel von Delphi 027",990,16689,225,248,0,251235],
  ["Orakel von Delphi 028",1014,17703,235,244,0,266445],
  ["Orakel von Delphi 029",1040,18743,245,241,0,282045],
  ["Orakel von Delphi 030",1066,19809,255,229,0,298035],
  ["Orakel von Delphi 031",1092,20901,265,226,0,314415],
  ["Orakel von Delphi 032",1120,22021,275,226,0,331215],
  ["Orakel von Delphi 033",1147,23168,285,195,0,348420],
  ["Orakel von Delphi 034",1176,24344,300,187,0,366060],
  ["Orakel von Delphi 035",1206,25550,310,188,0,384150],
  ["Orakel von Delphi 036",1236,26786,320,180,0,402690],
  ["Orakel von Delphi 037",1267,28053,330,182,0,421695],
  ["Orakel von Delphi 038",1298,29351,340,185,0,441165],
  ["Orakel von Delphi 039",1331,30682,350,180,0,461130],
  ["Orakel von Delphi 040",1364,32046,365,165,0,481590],
  ["Orakel von Delphi 041",1398,33444,375,161,0,502560],
  ["Orakel von Delphi 042",1433,34877,385,167,0,524055],
  ["Orakel von Delphi 043",1469,36346,395,175,0,546090],
  ["Orakel von Delphi 044",1505,37851,405,164,0,568665],
  ["Orakel von Delphi 045",1543,39394,420,165,0,591810],
  ["Orakel von Delphi 046",1582,40976,430,175,0,615540],
  ["Orakel von Delphi 047",1621,42597,440,176,0,639855],
  ["Orakel von Delphi 048",1662,44259,450,188,0,664785],
  ["Orakel von Delphi 049",1703,45962,465,172,0,690330],
  ["Orakel von Delphi 050",1746,47708,475,187,0,716520],
  ["Orakel von Delphi 051",1789,49497,485,201,0,743355],
  ["Orakel von Delphi 052",1834,51331,500,199,0,770865],
  ["Orakel von Delphi 053",1880,53211,510,216,0,799065],
  ["Orakel von Delphi 054",1927,55138,520,235,0,827970],
  ["Orakel von Delphi 055",1975,57113,535,216,0,857595],
  ["Orakel von Delphi 056",2025,59138,545,237,0,887970],
  ["Orakel von Delphi 057",2075,61213,555,249,0,919095],
  ["Orakel von Delphi 058",2127,63340,570,263,0,951000],
  ["Orakel von Delphi 059",2180,65520,580,288,0,983700],
  ["Orakel von Delphi 060",2235,67755,590,305,0,1017225],
  ["Orakel von Delphi 061",2290,70045,605,312,0,1051575],
  ["Orakel von Delphi 062",2348,72393,615,332,0,1086795],
  ["Orakel von Delphi 063",2406,74799,630,352,0,1122885],
  ["Orakel von Delphi 064",2467,77266,640,385,0,1159890],
  ["Orakel von Delphi 065",2528,79794,650,399,0,1197810],
  ["Orakel von Delphi 066",2591,82385,665,414,0,1236675],
  ["Orakel von Delphi 067",2656,85041,675,441,0,1276515],
  ["Orakel von Delphi 068",2723,87764,690,470,0,1317360],
  ["Orakel von Delphi 069",2791,90555,700,510,0,1359225],
  ["Orakel von Delphi 070",2860,93415,715,522,0,1402125],
  ["Orakel von Delphi 071",2932,96347,725,565,0,1446105],
  ["Orakel von Delphi 072",3005,99352,735,600,0,1491180],
  ["Orakel von Delphi 073",3080,102432,750,637,0,1537380],
  ["Orakel von Delphi 074",3157,105589,760,686,0,1584735],
  ["Orakel von Delphi 075",3236,108825,775,698,0,1633275],
  ["Orakel von Delphi 076",3317,112142,785,750,0,1683030],
  ["Orakel von Delphi 077",3400,115542,800,786,0,1734030],
  ["Orakel von Delphi 078",3485,119027,810,842,0,1786305],
  ["Orakel von Delphi 079",3572,122599,825,872,0,1839885],
  ["Orakel von Delphi 080",3661,126260,835,933,0,1894800],
  ["Orakel von Delphi 081",3753,130013,850,987,0,1951095],
  ["Orakel von Delphi 082",3847,133860,860,1043,0,2008800],
  ["Orakel von Delphi 083",3943,137783,875,1091,0,2067645],
  ["Orakel von Delphi 084",4041,141824,890,1124,0,2128260],
  ["Orakel von Delphi 085",4142,145966,900,1197,0,2190390],
  ["Orakel von Delphi 086",4246,150212,915,1243,0,2254080],
  ["Orakel von Delphi 087",4352,154564,925,1320,0,2319360],
  ["Orakel von Delphi 088",4461,159025,940,1392,0,2386275],
  ["Orakel von Delphi 089",4572,163597,950,1465,0,2454855],
  ["Orakel von Delphi 090",4687,168284,965,1532,0,2525160],
  ["Orakel von Delphi 091",4804,173088,975,1611,0,2597220],
  ["Orakel von Delphi 092",4924,178012,990,1693,0,2671080],
  ["Orakel von Delphi 093",5047,183059,1005,1749,0,2746785],
  ["Orakel von Delphi 094",5173,188232,1015,1847,0,2824380],
  ["Orakel von Delphi 095",5303,193535,1030,1939,0,2903925],
  ["Orakel von Delphi 096",5435,198970,1040,2033,0,2985450],
  ["Orakel von Delphi 097",5571,204541,1055,2121,0,3069015],
  ["Orakel von Delphi 098",5710,210251,1070,2213,0,3154665],
  ["Orakel von Delphi 099",5853,216104,1080,2328,0,3242460],
  ["Orakel von Delphi 100",5999,222103,1095,2416,0,3332445],
  ["Orakel von Delphi 101",6149,228252,1110,2528,0,3424680],
  ["Orakel von Delphi 102",6303,234555,1120,2654,0,3519225],
  ["Orakel von Delphi 103",6461,241016,1135,2746,0,3616140],
  ["Orakel von Delphi 104",6622,247638,1150,2869,0,3715470],
  ["Orakel von Delphi 105",6788,254426,1160,2997,0,3817290],
  ["Orakel von Delphi 106",6957,261383,1175,3118,0,3921645],
  ["Orakel von Delphi 107",7131,268514,1190,3245,0,4028610],
  ["Regenwald-Projekt 000",0,0,0,0,0,96975],
  ["Regenwald-Projekt 001",70,70,10,41,0,98025],
  ["Regenwald-Projekt 002",110,180,15,52,0,99675],
  ["Regenwald-Projekt 003",200,380,25,113,0,102675],
  ["Regenwald-Projekt 004",290,670,35,175,0,107025],
  ["Regenwald-Projekt 005",400,1070,45,237,0,113025],
  ["Regenwald-Projekt 006",510,1580,60,310,0,120675],
  ["Regenwald-Projekt 007",620,2200,70,391,0,129975],
  ["Regenwald-Projekt 008",740,2940,85,453,0,141075],
  ["Regenwald-Projekt 009",860,3800,95,545,0,153975],
  ["Regenwald-Projekt 010",970,4770,110,608,0,168525],
  ["Regenwald-Projekt 011",995,5765,120,605,0,183450],
  ["Regenwald-Projekt 012",1020,6785,135,572,0,198750],
  ["Regenwald-Projekt 013",1045,7830,150,559,0,214425],
  ["Regenwald-Projekt 014",1071,8901,165,519,0,230490],
  ["Regenwald-Projekt 015",1098,9999,180,509,0,246960],
  ["Regenwald-Projekt 016",1125,11124,195,478,0,263835],
  ["Regenwald-Projekt 017",1154,12278,210,469,0,281145],
  ["Regenwald-Projekt 018",1182,13460,225,440,0,298875],
  ["Regenwald-Projekt 019",1212,14672,240,433,0,317055],
  ["Regenwald-Projekt 020",1242,15914,255,405,0,335685],
  ["Regenwald-Projekt 021",1273,17187,270,398,0,354780],
  ["Regenwald-Projekt 022",1305,18492,290,344,0,374355],
  ["Regenwald-Projekt 023",1338,19830,305,329,0,394425],
  ["Regenwald-Projekt 024",1371,21201,320,315,0,414990],
  ["Regenwald-Projekt 025",1405,22606,335,301,0,436065],
  ["Regenwald-Projekt 026",1440,24046,355,270,0,457665],
  ["Regenwald-Projekt 027",1476,25522,370,268,0,479805],
  ["Regenwald-Projekt 028",1513,27035,385,247,0,502500],
  ["Regenwald-Projekt 029",1551,28586,405,210,0,525765],
  ["Regenwald-Projekt 030",1590,30176,420,212,0,549615],
  ["Regenwald-Projekt 031",1630,31806,435,194,0,574065],
  ["Regenwald-Projekt 032",1670,33476,455,177,0,599115],
  ["Regenwald-Projekt 033",1712,35188,470,172,0,624795],
  ["Regenwald-Projekt 034",1755,36943,490,158,0,651120],
  ["Regenwald-Projekt 035",1799,38742,505,144,0,678105],
  ["Regenwald-Projekt 036",1844,40586,525,113,0,705765],
  ["Regenwald-Projekt 037",1890,42476,540,122,0,734115],
  ["Regenwald-Projekt 038",1937,44413,560,102,0,763170],
  ["Regenwald-Projekt 039",1986,46399,575,103,0,792960],
  ["Regenwald-Projekt 040",2035,48434,595,86,0,823485],
  ["Regenwald-Projekt 041",2086,50520,615,70,0,854775],
  ["Regenwald-Projekt 042",2138,52658,630,84,0,886845],
  ["Regenwald-Projekt 043",2192,54850,650,63,0,919725],
  ["Regenwald-Projekt 044",2246,57096,670,60,0,953415],
  ["Regenwald-Projekt 045",2303,59399,685,59,0,987960],
  ["Regenwald-Projekt 046",2360,61759,705,50,0,1023360],
  ["Regenwald-Projekt 047",2419,64178,725,52,0,1059645],
  ["Regenwald-Projekt 048",2480,66658,740,66,0,1096845],
  ["Regenwald-Projekt 049",2541,69199,760,70,0,1134960],
  ["Regenwald-Projekt 050",2605,71804,780,58,0,1174035],
  ["Regenwald-Projekt 051",2670,74474,800,56,0,1214085],
  ["Regenwald-Projekt 052",2737,77211,815,75,0,1255140],
  ["Regenwald-Projekt 053",2805,80016,835,77,0,1297215],
  ["Regenwald-Projekt 054",2875,82891,855,80,0,1340340],
  ["Regenwald-Projekt 055",2947,85838,875,95,0,1384545],
  ["Regenwald-Projekt 056",3021,88859,895,85,0,1429860],
  ["Regenwald-Projekt 057",3096,91955,915,93,0,1476300],
  ["Regenwald-Projekt 058",3174,95129,930,133,0,1523910],
  ["Regenwald-Projekt 059",3253,98382,950,146,0,1572705],
  ["Regenwald-Projekt 060",3334,101716,970,170,0,1622715],
  ["Regenwald-Projekt 061",3418,105134,990,187,0,1673985],
  ["Regenwald-Projekt 062",3503,108637,1010,196,0,1726530],
  ["Regenwald-Projekt 063",3591,112228,1030,227,0,1780395],
  ["Regenwald-Projekt 064",3681,115909,1050,250,0,1835610],
  ["Regenwald-Projekt 065",3773,119682,1070,276,0,1892205],
  ["Regenwald-Projekt 066",3867,123549,1090,313,0,1950210],
  ["Regenwald-Projekt 067",3964,127513,1110,343,0,2009670],
  ["Regenwald-Projekt 068",4063,131576,1130,367,0,2070615],
  ["Regenwald-Projekt 069",4164,135740,1150,411,0,2133075],
  ["Regenwald-Projekt 070",4268,140008,1170,448,0,2197095],
  ["Regenwald-Projekt 071",4375,144383,1190,489,0,2262720],
  ["Regenwald-Projekt 072",4484,148867,1210,541,0,2329980],
  ["Regenwald-Projekt 073",4596,153463,1230,586,0,2398920],
  ["Regenwald-Projekt 074",4711,158174,1250,625,0,2469585],
  ["Regenwald-Projekt 075",4829,163003,1270,686,0,2542020],
  ["Regenwald-Projekt 076",4950,167953,1290,740,0,2616270],
  ["Regenwald-Projekt 077",5074,173027,1310,798,0,2692380],
  ["Regenwald-Projekt 078",5200,178227,1335,838,0,2770380],
  ["Regenwald-Projekt 079",5330,183557,1355,911,0,2850330],
  ["Regenwald-Projekt 080",5464,189021,1375,970,0,2932290],
  ["Regenwald-Projekt 081",5600,194621,1395,1039,0,3016290],
  ["Regenwald-Projekt 082",5740,200361,1415,1122,0,3102390],
  ["Regenwald-Projekt 083",5884,206245,1435,1200,0,3190650],
  ["Regenwald-Projekt 084",6031,212276,1455,1280,0,3281115],
  ["Regenwald-Projekt 085",6182,218458,1480,1365,0,3373845],
  ["Regenwald-Projekt 086",6336,224794,1500,1433,0,3468885],
  ["Regenwald-Projekt 087",6494,231288,1520,1524,0,3566295],
  ["Regenwald-Projekt 088",6657,237945,1540,1630,0,3666150],
  ["Regenwald-Projekt 089",6823,244768,1560,1730,0,3768495],
  ["Regenwald-Projekt 090",6994,251762,1585,1814,0,3873405],
  ["Regenwald-Projekt 091",7169,258931,1605,1914,0,3980940],
  ["Regenwald-Projekt 092",7348,266279,1625,2036,0,4091160],
  ["Regenwald-Projekt 093",7531,273810,1645,2152,0,4204125],
  ["Regenwald-Projekt 094",7720,281530,1670,2266,0,4319925],
  ["Regenwald-Projekt 095",7913,289443,1690,2402,0,4438620],
  ["Regenwald-Projekt 096",8111,297554,1710,2533,0,4560285],
  ["Regenwald-Projekt 097",8313,305867,1735,2640,0,4684980],
  ["Regenwald-Projekt 098",8521,314388,1755,2781,0,4812795],
  ["Regenwald-Projekt 099",8734,323122,1775,2937,0,4943805],
  ["Regenwald-Projekt 100",8952,332074,1800,3080,0,5078085],
  ["Regenwald-Projekt 101",9176,341250,1820,3237,0,5215725],
  ["Regenwald-Projekt 102",9406,350656,1840,3410,0,5356815],
  ["Regenwald-Projekt 103",9641,360297,1865,3550,0,5501430],
  ["Regenwald-Projekt 104",9882,370179,1885,3724,0,5649660],
  ["Regenwald-Projekt 105",10129,380308,1905,3905,0,5801595],
  ["Regenwald-Projekt 106",10382,390690,1930,4092,0,5957325],
  ["Regenwald-Projekt 107",10642,401332,1950,4285,0,6116955],
  ["Regenwald-Projekt 108",10908,412240,1975,4456,0,6280575],
  ["Regenwald-Projekt 109",11180,423420,1995,4661,0,6448275],
  ["Regenwald-Projekt 110",11460,434880,2015,4884,0,6620175],
  ["Regenwald-Projekt 111",11746,446626,2040,5095,0,6796365],
  ["Regenwald-Projekt 112",12040,458666,2060,5322,0,6976965],
  ["Regenwald-Projekt 113",12341,471007,2085,5519,0,7162080],
  ["Regenwald-Projekt 114",12649,483656,2105,5770,0,7351815],
  ["Regenwald-Projekt 115",12966,496622,2130,6011,0,7546305],
  ["Regenwald-Projekt 116",13290,509912,2150,6269,0,7745655],
  ["Regenwald-Projekt 117",13622,523534,2170,6544,0,7949985],
  ["Regenwald-Projekt 118",13963,537497,2195,6799,0,8159430],
  ["Regenwald-Projekt 119",14312,551809,2215,7072,0,8374110],
  ["Regenwald-Projekt 120",14669,566478,2240,7353,0,8594145],
  ["Regenwald-Projekt 121",15036,581514,2260,7663,0,8819685],
  ["Regenwald-Projekt 122",15412,596926,2285,7953,0,9050865],
  ["Regenwald-Projekt 123",15797,612723,2305,8271,0,9287820],
  ["Regenwald-Projekt 124",16192,628915,2330,8582,0,9530700],
  ["Regenwald-Projekt 125",16597,645512,2350,8930,0,9779655],
  ["Regenwald-Projekt 126",17012,662524,2375,9259,0,10034835],
  ["Regenwald-Projekt 127",17437,679961,2395,9618,0,10296390],
  ["Regenwald-Projekt 128",17873,697834,2420,9978,0,10564485],
  ["Regenwald-Projekt 129",18320,716154,2445,10329,0,10839285],
  ["Regenwald-Projekt 130",18778,734932,2465,10730,0,11120955],
  ["Regenwald-Projekt 131",19247,754179,2490,11123,0,11409660],
  ["Regenwald-Projekt 132",19729,773908,2510,11539,0,11705595],
  ["Regenwald-Projekt 133",20222,794130,2535,11946,0,12008925],
  ["Regenwald-Projekt 134",20727,814857,2555,12394,0,12319830],
  ["Regenwald-Projekt 135",21245,836102,2580,12828,0,12638505],
  ["Regenwald-Projekt 136",21777,857879,2605,13273,0,12965160],
  ["Regenwald-Projekt 137",22321,880200,2625,13751,0,13299975],
  ["Regenwald-Projekt 138",22879,903079,2650,14243,0,13643160],
  ["Regenwald-Projekt 139",23451,926530,2675,14729,0,13994925],
  ["Regenwald-Projekt 140",24037,950567,2695,15229,0,14355480],
  ["Regenwald-Projekt 141",24638,975205,2720,15754,0,14725050],
  ["Regenwald-Projekt 142",25254,1000459,2740,16313,0,15103860],
  ["Regenwald-Projekt 143",25885,1026344,2765,16858,0,15492135],
  ["Regenwald-Projekt 144",26532,1052876,2790,17429,0,15890115],
  ["Regenwald-Projekt 145",27196,1080072,2810,18018,0,16298055],
  ["Regenwald-Projekt 146",27876,1107948,2835,18612,0,16716195],
  ["Regenwald-Projekt 147",28573,1136521,2860,19243,0,17144790],
  ["Regenwald-Projekt 148",29287,1165808,2880,19891,0,17584095],
  ["Regenwald-Projekt 149",30019,1195827,2905,20536,0,18034380],
  ["Regenwald-Projekt 150",30769,1226596,2930,21201,0,18495915],
  ["Regenwald-Projekt 151",31539,1258135,2950,21914,0,18969000],
  ["Regenwald-Projekt 152",32327,1290462,2975,22616,0,19453905],
  ["Regenwald-Projekt 153",33135,1323597,3000,23349,0,19950930],
  ["Regenwald-Projekt 154",33964,1357561,3025,24091,0,20460390],
  ["Regenwald-Projekt 155",34813,1392374,3050,24856,0,20982585],
  ["Regenwald-Projekt 156",35683,1428057,3070,25669,0,21517830],
  ["Regenwald-Projekt 157",36575,1464632,3095,26475,0,22066455],
  ["Regenwald-Projekt 158",37489,1502121,3120,27314,0,22628790],
  ["Regenwald-Projekt 159",38427,1540548,3140,28185,0,23205195],
  ["Regenwald-Projekt 160",39387,1579935,3165,29049,0,23796000],
  ["Regenwald-Projekt 161",40372,1620307,3190,29968,0,24401580],
  ["Regenwald-Projekt 162",41381,1661688,3215,30891,0,25022295],
  ["Regenwald-Projekt 163",42416,1704104,3235,31860,0,25658535],
  ["Regenwald-Projekt 164",43476,1747580,3260,32844,0,26310675],
  ["Regenwald-Projekt 165",44563,1792143,3285,33827,0,26979120],
  ["Regenwald-Projekt 166",45677,1837820,3310,34875,0,27664275],
  ["Regenwald-Projekt 167",46819,1884639,3335,35931,0,28366560],
  ["Regenwald-Projekt 168",47990,1932629,3355,37036,0,29086410],
  ["Regenwald-Projekt 169",49189,1981818,3380,38159,0,29824245],
  ["Regenwald-Projekt 170",50419,2032237,3405,39293,0,30580530],
  ["Regenwald-Projekt 171",51679,2083916,3430,40487,0,31355715],
  ["Regenwald-Projekt 172",52971,2136887,3455,41693,0,32150280],
  ["Regenwald-Projekt 173",54296,2191183,3480,42943,0,32964720],
  ["Regenwald-Projekt 174",55653,2246836,3500,44233,0,33799515],
  ["Regenwald-Projekt 175",57044,2303880,3525,45529,0,34655175],
  ["Regenwald-Projekt 176",58471,2362351,3550,46890,0,35532240],
  ["Regenwald-Projekt 177",59932,2422283,3575,48265,0,36431220],
  ["Regenwald-Projekt 178",61431,2483714,3600,49689,0,37352685],
  ["Regenwald-Projekt 179",62966,2546680,3625,51137,0,38297175],
  ["Regenwald-Projekt 180",64540,2611220,3650,52626,0,39265275],
  ["Regenwald-Projekt 181",66154,2677374,3670,54183,0,40257585],
  ["Regenwald-Projekt 182",67808,2745182,3695,55751,0,41274705],
  ["Regenwald-Projekt 183",69503,2814685,3720,57371,0,42317250],
  ["Relikttempel 000",0,0,0,0,0,3600],
  ["Relikttempel 001",50,50,5,30,0,4350],
  ["Relikttempel 002",70,120,10,41,0,5400],
  ["Relikttempel 003",130,250,15,72,0,7350],
  ["Relikttempel 004",200,450,20,133,0,10350],
  ["Relikttempel 005",270,720,30,174,0,14400],
  ["Relikttempel 006",330,1050,35,215,0,19350],
  ["Relikttempel 007",420,1470,45,257,0,25650],
  ["Relikttempel 008",490,1960,50,318,0,33000],
  ["Relikttempel 009",570,2530,60,370,0,41550],
  ["Relikttempel 010",650,3180,65,430,0,51300],
  ["Relikttempel 011",667,3847,75,409,0,61305],
  ["Relikttempel 012",683,4530,85,396,0,71550],
  ["Relikttempel 013",700,5230,95,385,0,82050],
  ["Relikttempel 014",718,5948,100,394,0,92820],
  ["Relikttempel 015",736,6684,110,374,0,103860],
  ["Relikttempel 016",754,7438,120,364,0,115170],
  ["Relikttempel 017",773,8211,130,354,0,126765],
  ["Relikttempel 018",792,9003,140,335,0,138645],
  ["Relikttempel 019",812,9815,150,326,0,150825],
  ["Relikttempel 020",833,10648,155,328,0,163320],
  ["Relikttempel 021",853,11501,165,301,0,176115],
  ["Relikttempel 022",875,12376,175,295,0,189240],
  ["Relikttempel 023",897,13273,185,288,0,202695],
  ["Relikttempel 024",919,14192,195,272,0,216480],
  ["Relikttempel 025",942,15134,205,266,0,230610],
  ["Relikttempel 026",965,16099,215,261,0,245085],
  ["Relikttempel 027",990,17089,225,248,0,259935],
  ["Relikttempel 028",1014,18103,235,244,0,275145],
  ["Relikttempel 029",1040,19143,245,241,0,290745],
  ["Relikttempel 030",1066,20209,255,229,0,306735],
  ["Relikttempel 031",1092,21301,265,226,0,323115],
  ["Relikttempel 032",1120,22421,275,226,0,339915],
  ["Relikttempel 033",1147,23568,285,195,0,357120],
  ["Relikttempel 034",1176,24744,300,187,0,374760],
  ["Relikttempel 035",1206,25950,310,188,0,392850],
  ["Relikttempel 036",1236,27186,320,180,0,411390],
  ["Relikttempel 037",1267,28453,330,182,0,430395],
  ["Relikttempel 038",1298,29751,340,185,0,449865],
  ["Relikttempel 039",1331,31082,350,180,0,469830],
  ["Relikttempel 040",1364,32446,365,165,0,490290],
  ["Relikttempel 041",1398,33844,375,161,0,511260],
  ["Relikttempel 042",1433,35277,385,167,0,532755],
  ["Relikttempel 043",1469,36746,395,175,0,554790],
  ["Relikttempel 044",1505,38251,405,164,0,577365],
  ["Relikttempel 045",1543,39794,420,165,0,600510],
  ["Relikttempel 046",1582,41376,430,175,0,624240],
  ["Relikttempel 047",1621,42997,440,176,0,648555],
  ["Relikttempel 048",1662,44659,450,188,0,673485],
  ["Relikttempel 049",1703,46362,465,172,0,699030],
  ["Relikttempel 050",1746,48108,475,187,0,725220],
  ["Relikttempel 051",1789,49897,485,201,0,752055],
  ["Relikttempel 052",1834,51731,500,199,0,779565],
  ["Relikttempel 053",1880,53611,510,216,0,807765],
  ["Relikttempel 054",1927,55538,520,235,0,836670],
  ["Relikttempel 055",1975,57513,535,216,0,866295],
  ["Relikttempel 056",2025,59538,545,237,0,896670],
  ["Relikttempel 057",2075,61613,555,249,0,927795],
  ["Relikttempel 058",2127,63740,570,263,0,959700],
  ["Relikttempel 059",2180,65920,580,288,0,992400],
  ["Relikttempel 060",2235,68155,590,305,0,1025925],
  ["Relikttempel 061",2290,70445,605,312,0,1060275],
  ["Relikttempel 062",2348,72793,615,332,0,1095495],
  ["Relikttempel 063",2406,75199,630,352,0,1131585],
  ["Relikttempel 064",2467,77666,640,385,0,1168590],
  ["Relikttempel 065",2528,80194,650,399,0,1206510],
  ["Relikttempel 066",2591,82785,665,414,0,1245375],
  ["Relikttempel 067",2656,85441,675,441,0,1285215],
  ["Relikttempel 068",2723,88164,690,470,0,1326060],
  ["Relikttempel 069",2791,90955,700,510,0,1367925],
  ["Relikttempel 070",2860,93815,715,522,0,1410825],
  ["Relikttempel 071",2932,96747,725,565,0,1454805],
  ["Relikttempel 072",3005,99752,735,600,0,1499880],
  ["Relikttempel 073",3080,102832,750,637,0,1546080],
  ["Relikttempel 074",3157,105989,760,686,0,1593435],
  ["Relikttempel 075",3236,109225,775,698,0,1641975],
  ["Relikttempel 076",3317,112542,785,750,0,1691730],
  ["Relikttempel 077",3400,115942,800,786,0,1742730],
  ["Relikttempel 078",3485,119427,810,842,0,1795005],
  ["Relikttempel 079",3572,122999,825,872,0,1848585],
  ["Relikttempel 080",3661,126660,835,933,0,1903500],
  ["Relikttempel 081",3753,130413,850,987,0,1959795],
  ["Relikttempel 082",3847,134260,860,1043,0,2017500],
  ["Relikttempel 083",3943,138203,875,1091,0,2076645],
  ["Relikttempel 084",4041,142244,890,1124,0,2137260],
  ["Relikttempel 085",4142,146386,900,1197,0,2199390],
  ["Relikttempel 086",4246,150632,915,1243,0,2263080],
  ["Relikttempel 087",4352,154984,925,1320,0,2328360],
  ["Relikttempel 088",4461,159445,940,1392,0,2395275],
  ["Relikttempel 089",4572,164017,950,1465,0,2463855],
  ["Relikttempel 090",4687,168704,965,1532,0,2534160],
  ["Relikttempel 091",4804,173508,975,1611,0,2606220],
  ["Relikttempel 092",4924,178432,990,1693,0,2680080],
  ["Relikttempel 093",5047,183479,1005,1749,0,2755785],
  ["Relikttempel 094",5173,188652,1015,1847,0,2833380],
  ["Relikttempel 095",5303,193955,1030,1939,0,2912925],
  ["Relikttempel 096",5435,199390,1040,2033,0,2994450],
  ["Relikttempel 097",5571,204961,1055,2121,0,3078015],
  ["Relikttempel 098",5710,210671,1070,2213,0,3163665],
  ["Relikttempel 099",5853,216524,1080,2328,0,3251460],
  ["Relikttempel 100",5999,222523,1095,2416,0,3341445],
  ["Relikttempel 101",6149,228672,1110,2528,0,3433680],
  ["Relikttempel 102",6303,234975,1120,2654,0,3528225],
  ["Relikttempel 103",6461,241436,1135,2746,0,3625140],
  ["Relikttempel 104",6622,248058,1150,2869,0,3724470],
  ["Relikttempel 105",6788,254846,1160,2997,0,3826290],
  ["Relikttempel 106",6957,261803,1175,3118,0,3930645],
  ["Relikttempel 107",7131,268934,1190,3245,0,4037610],
  ["Royal Albert Hall 000",0,0,0,0,0,12450],
  ["Royal Albert Hall 001",60,60,10,31,0,13350],
  ["Royal Albert Hall 002",90,150,10,61,0,14700],
  ["Royal Albert Hall 003",150,300,20,83,0,16950],
  ["Royal Albert Hall 004",240,540,25,153,0,20550],
  ["Royal Albert Hall 005",330,870,35,215,0,25500],
  ["Royal Albert Hall 006",410,1280,45,247,0,31650],
  ["Royal Albert Hall 007",500,1780,50,328,0,39150],
  ["Royal Albert Hall 008",600,2380,60,400,0,48150],
  ["Royal Albert Hall 009",690,3070,70,461,0,58500],
  ["Royal Albert Hall 010",790,3860,80,523,0,70350],
  ["Royal Albert Hall 011",810,4670,90,514,0,82500],
  ["Royal Albert Hall 012",830,5500,100,506,0,94950],
  ["Royal Albert Hall 013",851,6351,115,470,0,107715],
  ["Royal Albert Hall 014",873,7224,120,483,0,120810],
  ["Royal Albert Hall 015",894,8118,135,446,0,134220],
  ["Royal Albert Hall 016",917,9035,145,440,0,147975],
  ["Royal Albert Hall 017",940,9975,155,435,0,162075],
  ["Royal Albert Hall 018",963,10938,165,411,0,176520],
  ["Royal Albert Hall 019",987,11925,180,398,0,191325],
  ["Royal Albert Hall 020",1012,12937,190,394,0,206505],
  ["Royal Albert Hall 021",1037,13974,200,381,0,222060],
  ["Royal Albert Hall 022",1063,15037,215,359,0,238005],
  ["Royal Albert Hall 023",1090,16127,225,348,0,254355],
  ["Royal Albert Hall 024",1117,17244,235,347,0,271110],
  ["Royal Albert Hall 025",1145,18389,250,337,0,288285],
  ["Royal Albert Hall 026",1173,19562,260,327,0,305880],
  ["Royal Albert Hall 027",1203,20765,275,309,0,323925],
  ["Royal Albert Hall 028",1233,21998,285,281,0,342420],
  ["Royal Albert Hall 029",1263,23261,300,274,0,361365],
  ["Royal Albert Hall 030",1295,24556,310,277,0,380790],
  ["Royal Albert Hall 031",1327,25883,325,251,0,400695],
  ["Royal Albert Hall 032",1361,27244,335,257,0,421110],
  ["Royal Albert Hall 033",1395,28639,350,244,0,442035],
  ["Royal Albert Hall 034",1429,30068,360,250,0,463470],
  ["Royal Albert Hall 035",1465,31533,375,228,0,485445],
  ["Royal Albert Hall 036",1502,33035,390,227,0,507975],
  ["Royal Albert Hall 037",1539,34574,400,236,0,531060],
  ["Royal Albert Hall 038",1578,36152,415,209,0,554730],
  ["Royal Albert Hall 039",1617,37769,425,219,0,578985],
  ["Royal Albert Hall 040",1658,39427,440,213,0,603855],
  ["Royal Albert Hall 041",1699,41126,455,206,0,629340],
  ["Royal Albert Hall 042",1741,42867,465,210,0,655455],
  ["Royal Albert Hall 043",1785,44652,480,217,0,682230],
  ["Royal Albert Hall 044",1830,46482,495,204,0,709680],
  ["Royal Albert Hall 045",1875,48357,505,220,0,737805],
  ["Royal Albert Hall 046",1922,50279,520,230,0,766635],
  ["Royal Albert Hall 047",1970,52249,535,211,0,796185],
  ["Royal Albert Hall 048",2019,54268,550,222,0,826470],
  ["Royal Albert Hall 049",2070,56338,560,235,0,857520],
  ["Royal Albert Hall 050",2122,58460,575,239,0,889350],
  ["Royal Albert Hall 051",2175,60635,590,245,0,921975],
  ["Royal Albert Hall 052",2229,62864,605,251,0,955410],
  ["Royal Albert Hall 053",2285,65149,620,260,0,989685],
  ["Royal Albert Hall 054",2342,67491,635,269,0,1024815],
  ["Royal Albert Hall 055",2400,69891,645,280,0,1060815],
  ["Royal Albert Hall 056",2460,72351,660,303,0,1097715],
  ["Royal Albert Hall 057",2522,74873,675,307,0,1135545],
  ["Royal Albert Hall 058",2585,77458,690,332,0,1174320],
  ["Royal Albert Hall 059",2650,80108,705,340,0,1214070],
  ["Royal Albert Hall 060",2716,82824,720,369,0,1254810],
  ["Royal Albert Hall 061",2784,85608,735,379,0,1296570],
  ["Royal Albert Hall 062",2853,88461,745,419,0,1339365],
  ["Royal Albert Hall 063",2925,91386,760,454,0,1383240],
  ["Royal Albert Hall 064",2998,94384,775,460,0,1428210],
  ["Royal Albert Hall 065",3073,97457,790,497,0,1474305],
  ["Royal Albert Hall 066",3149,100606,805,515,0,1521540],
  ["Royal Albert Hall 067",3228,103834,820,557,0,1569960],
  ["Royal Albert Hall 068",3309,107143,835,581,0,1619595],
  ["Royal Albert Hall 069",3392,110535,850,626,0,1670475],
  ["Royal Albert Hall 070",3476,114011,865,652,0,1722615],
  ["Royal Albert Hall 071",3563,117574,880,702,0,1776060],
  ["Royal Albert Hall 072",3652,121226,895,716,0,1830840],
  ["Royal Albert Hall 073",3744,124970,910,770,0,1887000],
  ["Royal Albert Hall 074",3837,128807,925,805,0,1944555],
  ["Royal Albert Hall 075",3933,132740,940,864,0,2003550],
  ["Royal Albert Hall 076",4031,136771,955,905,0,2064015],
  ["Royal Albert Hall 077",4132,140903,970,968,0,2125995],
  ["Royal Albert Hall 078",4235,145138,985,1013,0,2189520],
  ["Royal Albert Hall 079",4341,149479,1000,1082,0,2254635],
  ["Royal Albert Hall 080",4450,153929,1015,1124,0,2321385],
  ["Royal Albert Hall 081",4561,158490,1030,1197,0,2389800],
  ["Royal Albert Hall 082",4675,163165,1045,1253,0,2459925],
  ["Royal Albert Hall 083",4792,167957,1065,1304,0,2531805],
  ["Royal Albert Hall 084",4912,172869,1075,1396,0,2605485],
  ["Royal Albert Hall 085",5035,177904,1095,1452,0,2681010],
  ["Royal Albert Hall 086",5160,183064,1110,1539,0,2758410],
  ["Royal Albert Hall 087",5289,188353,1125,1602,0,2837745],
  ["Royal Albert Hall 088",5422,193775,1140,1698,0,2919075],
  ["Royal Albert Hall 089",5557,199332,1155,1775,0,3002430],
  ["Royal Albert Hall 090",5696,205028,1170,1876,0,3087870],
  ["Royal Albert Hall 091",5838,210866,1185,1961,0,3175440],
  ["Royal Albert Hall 092",5984,216850,1200,2070,0,3265200],
  ["Royal Albert Hall 093",6134,222984,1220,2153,0,3357210],
  ["Royal Albert Hall 094",6287,229271,1235,2258,0,3451515],
  ["Royal Albert Hall 095",6445,235716,1250,2359,0,3548190],
  ["Royal Albert Hall 096",6606,242322,1265,2472,0,3647280],
  ["Royal Albert Hall 097",6771,249093,1280,2590,0,3748845],
  ["Royal Albert Hall 098",6940,256033,1300,2702,0,3852945],
  ["Royal Albert Hall 099",7113,263146,1315,2818,0,3959640],
  ["Royal Albert Hall 100",7291,270437,1330,2958,0,4069005],
  ["Royal Albert Hall 101",7474,277911,1345,3083,0,4181115],
  ["Royal Albert Hall 102",7660,285571,1360,3232,0,4296015],
  ["Royal Albert Hall 103",7852,293423,1375,3358,0,4413795],
  ["Saatgut-Tresor 000",0,0,0,0,0,117000],
  ["Saatgut-Tresor 001",80,80,10,51,0,118200],
  ["Saatgut-Tresor 002",120,200,15,62,0,120000],
  ["Saatgut-Tresor 003",230,430,25,143,0,123450],
  ["Saatgut-Tresor 004",340,770,35,225,0,128550],
  ["Saatgut-Tresor 005",450,1220,45,287,0,135300],
  ["Saatgut-Tresor 006",580,1800,60,380,0,144000],
  ["Saatgut-Tresor 007",710,2510,75,452,0,154650],
  ["Saatgut-Tresor 008",850,3360,85,563,0,167400],
  ["Saatgut-Tresor 009",980,4340,100,656,0,182100],
  ["Saatgut-Tresor 010",1110,5450,115,729,0,198750],
  ["Saatgut-Tresor 011",1138,6588,130,719,0,215820],
  ["Saatgut-Tresor 012",1167,7755,145,690,0,233325],
  ["Saatgut-Tresor 013",1196,8951,160,682,0,251265],
  ["Saatgut-Tresor 014",1226,10177,170,665,0,269655],
  ["Saatgut-Tresor 015",1256,11433,190,638,0,288495],
  ["Saatgut-Tresor 016",1288,12721,205,612,0,307815],
  ["Saatgut-Tresor 017",1320,14041,220,607,0,327615],
  ["Saatgut-Tresor 018",1353,15394,235,583,0,347910],
  ["Saatgut-Tresor 019",1387,16781,250,579,0,368715],
  ["Saatgut-Tresor 020",1421,18202,265,555,0,390030],
  ["Saatgut-Tresor 021",1457,19659,285,505,0,411885],
  ["Saatgut-Tresor 022",1493,21152,300,504,0,434280],
  ["Saatgut-Tresor 023",1531,22683,315,484,0,457245],
  ["Saatgut-Tresor 024",1569,24252,335,465,0,480780],
  ["Saatgut-Tresor 025",1608,25860,350,457,0,504900],
  ["Saatgut-Tresor 026",1648,27508,370,440,0,529620],
  ["Saatgut-Tresor 027",1689,29197,385,423,0,554955],
  ["Saatgut-Tresor 028",1732,30929,400,429,0,580935],
  ["Saatgut-Tresor 029",1775,32704,420,397,0,607560],
  ["Saatgut-Tresor 030",1819,34523,440,374,0,634845],
  ["Saatgut-Tresor 031",1865,36388,455,372,0,662820],
  ["Saatgut-Tresor 032",1911,38299,475,352,0,691485],
  ["Saatgut-Tresor 033",1959,40258,490,362,0,720870],
  ["Saatgut-Tresor 034",2008,42266,510,344,0,750990],
  ["Saatgut-Tresor 035",2058,44324,525,327,0,781860],
  ["Saatgut-Tresor 036",2110,46434,545,322,0,813510],
  ["Saatgut-Tresor 037",2163,48597,565,308,0,845955],
  ["Saatgut-Tresor 038",2217,50814,585,296,0,879210],
  ["Saatgut-Tresor 039",2272,53086,600,314,0,913290],
  ["Saatgut-Tresor 040",2329,55415,620,304,0,948225],
  ["Saatgut-Tresor 041",2387,57804,640,305,0,984060],
  ["Saatgut-Tresor 042",2447,60251,660,290,0,1020765],
  ["Saatgut-Tresor 043",2508,62759,675,293,0,1058385],
  ["Saatgut-Tresor 044",2571,65330,695,299,0,1096950],
  ["Saatgut-Tresor 045",2635,67965,715,297,0,1136475],
  ["Saatgut-Tresor 046",2701,70666,735,296,0,1176990],
  ["Saatgut-Tresor 047",2768,73434,755,306,0,1218510],
  ["Saatgut-Tresor 048",2837,76271,775,299,0,1261065],
  ["Saatgut-Tresor 049",2908,79179,795,303,0,1304685],
  ["Saatgut-Tresor 050",2981,82160,815,319,0,1349400],
  ["Saatgut-Tresor 051",3055,85215,830,346,0,1395225],
  ["Saatgut-Tresor 052",3132,88347,850,366,0,1442205],
  ["Saatgut-Tresor 053",3210,91557,870,377,0,1490355],
  ["Saatgut-Tresor 054",3290,94847,895,354,0,1539705],
  ["Saatgut-Tresor 055",3373,98220,910,399,0,1590300],
  ["Saatgut-Tresor 056",3457,101677,930,416,0,1642155],
  ["Saatgut-Tresor 057",3543,105220,950,436,0,1695300],
  ["Saatgut-Tresor 058",3632,108852,970,468,0,1749780],
  ["Saatgut-Tresor 059",3723,112575,995,473,0,1805625],
  ["Saatgut-Tresor 060",3816,116391,1015,490,0,1862865],
  ["Saatgut-Tresor 061",3911,120302,1035,518,0,1921530],
  ["Saatgut-Tresor 062",4009,124311,1055,559,0,1981665],
  ["Saatgut-Tresor 063",4109,128420,1075,593,0,2043300],
  ["Saatgut-Tresor 064",4212,132632,1095,629,0,2106480],
  ["Saatgut-Tresor 065",4317,136949,1115,677,0,2171235],
  ["Saatgut-Tresor 066",4425,141374,1135,710,0,2237610],
  ["Saatgut-Tresor 067",4536,145910,1155,754,0,2305650],
  ["Saatgut-Tresor 068",4649,150559,1180,801,0,2375385],
  ["Saatgut-Tresor 069",4765,155324,1200,851,0,2446860],
  ["Saatgut-Tresor 070",4884,160208,1220,903,0,2520120],
  ["Saatgut-Tresor 071",5006,165214,1240,968,0,2595210],
  ["Saatgut-Tresor 072",5132,170346,1260,1018,0,2672190],
  ["Saatgut-Tresor 073",5260,175606,1285,1059,0,2751090],
  ["Saatgut-Tresor 074",5391,180997,1305,1124,0,2831955],
  ["Saatgut-Tresor 075",5526,186523,1325,1202,0,2914845],
  ["Saatgut-Tresor 076",5664,192187,1350,1264,0,2999805],
  ["Saatgut-Tresor 077",5806,197993,1370,1331,0,3086895],
  ["Saatgut-Tresor 078",5951,203944,1390,1419,0,3176160],
  ["Saatgut-Tresor 079",6100,210044,1410,1501,0,3267660],
  ["Saatgut-Tresor 080",6252,216296,1435,1568,0,3361440],
  ["Saatgut-Tresor 081",6408,222704,1455,1657,0,3457560],
  ["Saatgut-Tresor 082",6569,229273,1475,1761,0,3556095],
  ["Saatgut-Tresor 083",6733,236006,1500,1830,0,3657090],
  ["Saatgut-Tresor 084",6901,242907,1520,1931,0,3760605],
  ["Saatgut-Tresor 085",7074,249981,1545,2018,0,3866715],
  ["Saatgut-Tresor 086",7250,257231,1565,2137,0,3975465],
  ["Saatgut-Tresor 087",7432,264663,1585,2252,0,4086945],
  ["Saatgut-Tresor 088",7618,272281,1610,2354,0,4201215],
  ["Saatgut-Tresor 089",7808,280089,1630,2487,0,4318335],
  ["Saatgut-Tresor 090",8003,288092,1650,2615,0,4438380],
  ["Saatgut-Tresor 091",8203,296295,1675,2730,0,4561425],
  ["Saatgut-Tresor 092",8408,304703,1695,2868,0,4687545],
  ["Saatgut-Tresor 093",8618,313321,1720,3012,0,4816815],
  ["Saatgut-Tresor 094",8834,322155,1740,3152,0,4949325],
  ["Saatgut-Tresor 095",9055,331210,1765,3286,0,5085150],
  ["Saatgut-Tresor 096",9281,340428,1785,3446,0,5223420],
  ["Saatgut-Tresor 097",9513,349941,1810,3612,0,5366115],
  ["Saatgut-Tresor 098",9751,359692,1830,3783,0,5512380],
  ["Saatgut-Tresor 099",9995,369687,1855,3933,0,5662305],
  ["Saatgut-Tresor 100",10245,379932,1875,4116,0,5815980],
  ["Saatgut-Tresor 101",10501,390433,1900,4306,0,5973495],
  ["Saatgut-Tresor 102",10763,401196,1920,4502,0,6134940],
  ["Saatgut-Tresor 103",11032,412228,1945,4684,0,6300420],
  ["Saatgut-Tresor 104",11308,423536,1965,4884,0,6470040],
  ["Saatgut-Tresor 105",11591,435127,1990,5101,0,6643905],
  ["Saatgut-Tresor 106",11880,447007,2015,5304,0,6822105],
  ["Saatgut-Tresor 107",12177,459184,2035,5535,0,7004760],
  ["Saatgut-Tresor 108",12482,471666,2060,5764,0,7191990],
  ["Saatgut-Tresor 109",12794,484460,2080,6019,0,7383900],
  ["Saatgut-Tresor 110",13114,497574,2105,6235,0,7580610],
  ["Saatgut-Tresor 111",13442,511016,2125,6496,0,7782240],
  ["Saatgut-Tresor 112",13778,524794,2150,6757,0,7988910],
  ["Saatgut-Tresor 113",14122,538916,2175,7015,0,8200740],
  ["Saatgut-Tresor 114",14475,553391,2195,7311,0,8417865],
  ["Saatgut-Tresor 115",14837,568228,2220,7588,0,8640420],
  ["Saatgut-Tresor 116",15208,583436,2245,7872,0,8868540],
  ["Saatgut-Tresor 117",15588,599024,2265,8186,0,9102360],
  ["Saatgut-Tresor 118",15978,615002,2290,8510,0,9342030],
  ["Saatgut-Tresor 119",16377,631379,2315,8823,0,9587685],
  ["Saatgut-Tresor 120",16787,648166,2335,9158,0,9839490],
  ["Saatgut-Tresor 121",17206,665372,2360,9501,0,10097580],
  ["Saatgut-Tresor 122",17636,683008,2385,9845,0,10362120],
  ["Saatgut-Tresor 123",18077,701085,2405,10229,0,10633275],
  ["Saatgut-Tresor 124",18529,719614,2430,10605,0,10911210],
  ["Saatgut-Tresor 125",18992,738606,2455,10973,0,11196090],
  ["Saatgut-Tresor 126",19467,758073,2480,11372,0,11488095],
  ["Saatgut-Tresor 127",19954,778027,2500,11802,0,11787405],
  ["Saatgut-Tresor 128",20453,798480,2525,12215,0,12094200],
  ["Saatgut-Tresor 129",20964,819426,2550,12650,0,12408390],
  ["Saatgut-Tresor 130",21488,840914,2575,13080,0,12730710],
  ["Saatgut-Tresor 131",22025,862939,2595,13550,0,13061085],
  ["Saatgut-Tresor 132",22576,885515,2620,14035,0,13399725],
  ["Saatgut-Tresor 133",23140,908655,2645,14513,0,13746825],
  ["Saatgut-Tresor 134",23719,932374,2670,15016,0,14102610],
  ["Saatgut-Tresor 135",24312,956686,2690,15523,0,14467290],
  ["Saatgut-Tresor 136",24919,981605,2715,16044,0,14841075],
  ["Saatgut-Tresor 137",25542,1007147,2740,16601,0,15224205],
  ["Space Needle 000",0,0,0,0,0,27625],
  ["Space Needle 001",60,60,10,31,0,28525],
  ["Space Needle 002",100,160,10,71,0,30025],
  ["Space Needle 003",180,340,20,113,0,32725],
  ["Space Needle 004",270,610,30,174,0,36775],
  ["Space Needle 005",360,970,40,236,0,42175],
  ["Space Needle 006",460,1430,50,288,0,49075],
  ["Space Needle 007",560,1990,60,360,0,57475],
  ["Space Needle 008",670,2660,70,441,0,67525],
  ["Space Needle 009",770,3430,80,503,0,79075],
  ["Space Needle 010",880,4310,90,584,0,92275],
  ["Space Needle 011",902,5212,100,578,0,105805],
  ["Space Needle 012",925,6137,115,544,0,119680],
  ["Space Needle 013",948,7085,125,538,0,133900],
  ["Space Needle 014",972,8057,135,524,0,148480],
  ["Space Needle 015",996,9053,150,510,0,163420],
  ["Space Needle 016",1021,10074,160,507,0,178735],
  ["Space Needle 017",1047,11121,175,467,0,194440],
  ["Space Needle 018",1073,12194,185,464,0,210535],
  ["Space Needle 019",1099,13293,200,443,0,227020],
  ["Space Needle 020",1127,14420,210,442,0,243925],
  ["Space Needle 021",1155,15575,225,413,0,261250],
  ["Space Needle 022",1184,16759,240,405,0,279010],
  ["Space Needle 023",1214,17973,250,406,0,297220],
  ["Space Needle 024",1244,19217,265,378,0,315880],
  ["Space Needle 025",1275,20492,280,372,0,335005],
  ["Space Needle 026",1307,21799,290,346,0,354610],
  ["Space Needle 027",1340,23139,305,331,0,374710],
  ["Space Needle 028",1373,24512,320,317,0,395305],
  ["Space Needle 029",1407,25919,335,303,0,416410],
  ["Space Needle 030",1442,27361,345,300,0,438040],
  ["Space Needle 031",1479,28840,360,300,0,460225],
  ["Space Needle 032",1515,30355,375,278,0,482950],
  ["Space Needle 033",1553,31908,390,278,0,506245],
  ["Space Needle 034",1592,33500,405,251,0,530125],
  ["Space Needle 035",1632,35132,420,254,0,554605],
  ["Space Needle 036",1673,36805,430,266,0,579700],
  ["Space Needle 037",1715,38520,450,241,0,605425],
  ["Space Needle 038",1757,40277,460,255,0,631780],
  ["Space Needle 039",1801,42078,475,242,0,658795],
  ["Space Needle 040",1846,43924,490,249,0,686485],
  ["Space Needle 041",1893,45817,505,238,0,714880],
  ["Space Needle 042",1940,47757,520,248,0,743980],
  ["Space Needle 043",1988,49745,535,229,0,773800],
  ["Space Needle 044",2038,51783,550,241,0,804370],
  ["Space Needle 045",2089,53872,565,234,0,835705],
  ["Space Needle 046",2141,56013,580,249,0,867820],
  ["Space Needle 047",2195,58208,600,237,0,900745],
  ["Space Needle 048",2250,60458,615,234,0,934495],
  ["Space Needle 049",2306,62764,630,252,0,969085],
  ["Space Needle 050",2363,65127,645,243,0,1004530],
  ["Space Needle 051",2422,67549,660,265,0,1040860],
  ["Space Needle 052",2483,70032,675,268,0,1078105],
  ["Space Needle 053",2545,72577,690,292,0,1116280],
  ["Space Needle 054",2609,75186,705,299,0,1155415],
  ["Space Needle 055",2674,77860,725,307,0,1195525],
  ["Space Needle 056",2741,80601,740,327,0,1236640],
  ["Space Needle 057",2809,83410,755,347,0,1278775],
  ["Space Needle 058",2879,86289,770,360,0,1321960],
  ["Space Needle 059",2951,89240,785,384,0,1366225],
  ["Space Needle 060",3025,92265,800,411,0,1411600],
  ["Space Needle 061",3101,95366,820,430,0,1458115],
  ["Space Needle 062",3178,98544,835,450,0,1505785],
  ["Space Needle 063",3258,101802,850,492,0,1554655],
  ["Space Needle 064",3339,105141,870,506,0,1604740],
  ["Space Needle 065",3423,108564,885,515,0,1656085],
  ["Space Needle 066",3508,112072,900,563,0,1708705],
  ["Space Needle 067",3596,115668,915,593,0,1762645],
  ["Space Needle 068",3686,119354,935,626,0,1817935],
  ["Space Needle 069",3778,123132,950,671,0,1874605],
  ["Space Needle 070",3872,127004,965,717,0,1932685],
  ["Space Needle 071",3969,130973,985,747,0,1992220],
  ["Space Needle 072",4068,135041,1000,809,0,2053240],
  ["Space Needle 073",4170,139211,1015,844,0,2115790],
  ["Space Needle 074",4274,143485,1035,881,0,2179900],
  ["Space Needle 075",4381,147866,1050,950,0,2245615],
  ["Space Needle 076",4491,152357,1065,1003,0,2312980],
  ["Space Needle 077",4603,156960,1085,1058,0,2382025],
  ["Space Needle 078",4718,161678,1100,1126,0,2452795],
  ["Space Needle 079",4836,166514,1120,1187,0,2525335],
  ["Space Needle 080",4957,171471,1135,1242,0,2599690],
  ["Space Needle 081",5081,176552,1150,1328,0,2675905],
  ["Space Needle 082",5208,181760,1170,1388,0,2754025],
  ["Space Needle 083",5338,187098,1185,1461,0,2834095],
  ["Space Needle 084",5471,192569,1205,1537,0,2916160],
  ["Space Needle 085",5608,198177,1220,1627,0,3000280],
  ["Space Needle 086",5748,203925,1240,1710,0,3086500],
  ["Space Needle 087",5892,209817,1255,1787,0,3174880],
  ["Space Needle 088",6039,215856,1275,1867,0,3265465],
  ["Space Needle 089",6190,222046,1290,1980,0,3358315],
  ["Space Needle 090",6345,228391,1310,2069,0,3453490],
  ["Space Needle 091",6504,234895,1325,2180,0,3551050],
  ["Space Needle 092",6666,241561,1345,2275,0,3651040],
  ["Space Needle 093",6833,248394,1360,2405,0,3753535],
  ["Space Needle 094",7004,255398,1380,2501,0,3858595],
  ["Space Needle 095",7179,262577,1395,2618,0,3966280],
  ["Space Needle 096",7358,269935,1415,2740,0,4076650],
  ["Space Needle 097",7542,277477,1430,2877,0,4189780],
  ["Space Needle 098",7731,285208,1450,3009,0,4305745],
  ["Space Needle 099",7924,293132,1470,3135,0,4424605],
  ["Space Needle 100",8122,301254,1485,3256,0,4546435],
  ["Space Needle 101",8325,309579,1505,3402,0,4671310],
  ["Star Gazer 000",0,0,0,0,0,275000],
  ["Star Gazer 001",90,90,10,61,0,276350],
  ["Star Gazer 002",140,230,15,82,0,278450],
  ["Star Gazer 003",250,480,25,163,0,282200],
  ["Star Gazer 004",380,860,40,256,0,287900],
  ["Star Gazer 005",520,1380,55,329,0,295700],
  ["Star Gazer 006",650,2030,70,421,0,305450],
  ["Star Gazer 007",800,2830,80,533,0,317450],
  ["Star Gazer 008",940,3770,95,625,0,331550],
  ["Star Gazer 009",1110,4880,115,729,0,348200],
  ["Star Gazer 010",1250,6130,125,840,0,366950],
  ["Star Gazer 011",1282,7412,145,805,0,386180],
  ["Star Gazer 012",1314,8726,160,800,0,405890],
  ["Star Gazer 013",1347,10073,175,767,0,426095],
  ["Star Gazer 014",1380,11453,195,733,0,446795],
  ["Star Gazer 015",1415,12868,210,730,0,468020],
  ["Star Gazer 016",1450,14318,230,699,0,489770],
  ["Star Gazer 017",1486,15804,245,687,0,512060],
  ["Star Gazer 018",1524,17328,265,658,0,534920],
  ["Star Gazer 019",1562,18890,280,659,0,558350],
  ["Star Gazer 020",1601,20491,300,612,0,582365],
  ["Star Gazer 021",1641,22132,320,585,0,606980],
  ["Star Gazer 022",1682,23814,335,578,0,632210],
  ["Star Gazer 023",1724,25538,355,554,0,658070],
  ["Star Gazer 024",1767,27305,375,530,0,684575],
  ["Star Gazer 025",1811,29116,395,517,0,711740],
  ["Star Gazer 026",1856,30972,415,487,0,739580],
  ["Star Gazer 027",1903,32875,435,467,0,768125],
  ["Star Gazer 028",1950,34825,455,457,0,797375],
  ["Star Gazer 029",1999,36824,470,459,0,827360],
  ["Star Gazer 030",2049,38873,490,452,0,858095],
  ["Star Gazer 031",2100,40973,510,436,0,889595],
  ["Star Gazer 032",2152,43125,535,393,0,921875],
  ["Star Gazer 033",2206,45331,550,409,0,954965],
  ["Star Gazer 034",2261,47592,575,378,0,988880],
  ["Star Gazer 035",2318,49910,595,369,0,1023650],
  ["Star Gazer 036",2376,52286,615,360,0,1059290],
  ["Star Gazer 037",2435,54721,635,362,0,1095815],
  ["Star Gazer 038",2496,57217,655,348,0,1133255],
  ["Star Gazer 039",2559,59776,675,344,0,1171640],
  ["Star Gazer 040",2622,62398,700,341,0,1210970],
  ["Star Gazer 041",2688,65086,720,341,0,1251290],
  ["Star Gazer 042",2755,67841,740,341,0,1292615],
  ["Star Gazer 043",2824,70665,760,353,0,1334975],
  ["Star Gazer 044",2895,73560,785,328,0,1378400],
  ["Star Gazer 045",2967,76527,805,333,0,1422905],
  ["Star Gazer 046",3041,79568,825,341,0,1468520],
  ["Star Gazer 047",3117,82685,850,351,0,1515275],
  ["Star Gazer 048",3195,85880,870,362,0,1563200],
  ["Star Gazer 049",3275,89155,890,358,0,1612325],
  ["Star Gazer 050",3357,92512,915,354,0,1662680],
  ["Star Gazer 051",3441,95953,935,381,0,1714295],
  ["Star Gazer 052",3527,99480,960,392,0,1767200],
  ["Star Gazer 053",3615,103095,980,413,0,1821425],
  ["Star Gazer 054",3705,106800,1005,407,0,1877000],
  ["Star Gazer 055",3798,110598,1025,443,0,1933970],
  ["Star Gazer 056",3893,114491,1050,462,0,1992365],
  ["Star Gazer 057",3990,118481,1070,493,0,2052215],
  ["Star Gazer 058",4090,122571,1095,507,0,2113565],
  ["Star Gazer 059",4192,126763,1115,552,0,2176445],
  ["Star Gazer 060",4297,131060,1140,573,0,2240900],
  ["Star Gazer 061",4404,135464,1160,613,0,2306960],
  ["Star Gazer 062",4514,139978,1185,637,0,2374670],
  ["Star Gazer 063",4627,144605,1210,684,0,2444075],
  ["Star Gazer 064",4743,149348,1230,733,0,2515220],
  ["Star Gazer 065",4861,154209,1255,756,0,2588135],
  ["Star Gazer 066",4983,159192,1280,802,0,2662880],
  ["Star Gazer 067",5108,164300,1300,870,0,2739500],
  ["Star Gazer 068",5235,169535,1325,911,0,2818025],
  ["Star Gazer 069",5366,174901,1350,966,0,2898515],
  ["Star Gazer 070",5500,180401,1370,1025,0,2981015],
  ["Star Gazer 071",5638,186039,1395,1077,0,3065585],
  ["Star Gazer 072",5779,191818,1420,1152,0,3152270],
  ["Star Gazer 073",5923,197741,1445,1210,0,3241115],
  ["Star Gazer 074",6071,203812,1470,1282,0,3332180],
  ["Star Gazer 075",6223,210035,1490,1348,0,3425525],
  ["Star Gazer 076",6379,216414,1515,1418,0,3521210],
  ["Star Gazer 077",6538,222952,1540,1511,0,3619280],
  ["Star Gazer 078",6701,229653,1565,1588,0,3719795],
  ["Star Gazer 079",6869,236522,1590,1680,0,3822830],
  ["Star Gazer 080",7041,243563,1615,1758,0,3928445],
  ["Star Gazer 081",7217,250780,1635,1867,0,4036700],
  ["Star Gazer 082",7397,258177,1660,1981,0,4147655],
  ["Star Gazer 083",7582,265759,1685,2080,0,4261385],
  ["Star Gazer 084",7771,273530,1710,2193,0,4377950],
  ["Star Gazer 085",7966,281496,1735,2293,0,4497440],
  ["Star Gazer 086",8165,289661,1760,2416,0,4619915],
  ["Star Gazer 087",8369,298030,1785,2534,0,4745450],
  ["Star Gazer 088",8578,306608,1810,2677,0,4874120],
  ["Star Gazer 089",8793,315401,1835,2806,0,5006015],
  ["Star Gazer 090",9012,324413,1860,2941,0,5141195],
  ["Star Gazer 091",9238,333651,1885,3080,0,5279765],
  ["Star Gazer 092",9469,343120,1910,3236,0,5421800],
  ["Star Gazer 093",9705,352825,1935,3386,0,5567375],
  ["Star Gazer 094",9948,362773,1960,3563,0,5716595],
  ["Star Gazer 095",10197,372970,1985,3716,0,5869550],
  ["Star Gazer 096",10452,383422,2010,3895,0,6026330],
  ["Star Gazer 097",10713,394135,2035,4071,0,6187025],
  ["Star Gazer 098",10981,405116,2060,4263,0,6351740],
  ["Star Gazer 099",11255,416371,2085,4433,0,6520565],
  ["Star Gazer 100",11537,427908,2110,4649,0,6693620],
  ["Terrakotta-Armee 000",0,0,0,0,0,170400],
  ["Terrakotta-Armee 001",90,90,10,61,0,171750],
  ["Terrakotta-Armee 002",130,220,15,72,0,173700],
  ["Terrakotta-Armee 003",240,460,25,153,0,177300],
  ["Terrakotta-Armee 004",370,830,40,246,0,182850],
  ["Terrakotta-Armee 005",490,1320,50,318,0,190200],
  ["Terrakotta-Armee 006",630,1950,65,410,0,199650],
  ["Terrakotta-Armee 007",770,2720,80,503,0,211200],
  ["Terrakotta-Armee 008",910,3630,95,595,0,224850],
  ["Terrakotta-Armee 009",1060,4690,110,698,0,240750],
  ["Terrakotta-Armee 010",1210,5900,125,800,0,258900],
  ["Terrakotta-Armee 011",1241,7141,140,784,0,277515],
  ["Terrakotta-Armee 012",1272,8413,155,767,0,296595],
  ["Terrakotta-Armee 013",1304,9717,170,743,0,316155],
  ["Terrakotta-Armee 014",1336,11053,185,727,0,336195],
  ["Terrakotta-Armee 015",1370,12423,205,694,0,356745],
  ["Terrakotta-Armee 016",1404,13827,220,691,0,377805],
  ["Terrakotta-Armee 017",1439,15266,235,669,0,399390],
  ["Terrakotta-Armee 018",1475,16741,255,638,0,421515],
  ["Terrakotta-Armee 019",1512,18253,270,637,0,444195],
  ["Terrakotta-Armee 020",1549,19802,290,588,0,467430],
  ["Terrakotta-Armee 021",1588,21390,305,579,0,491250],
  ["Terrakotta-Armee 022",1628,23018,325,552,0,515670],
  ["Terrakotta-Armee 023",1668,24686,345,526,0,540690],
  ["Terrakotta-Armee 024",1710,26396,360,531,0,566340],
  ["Terrakotta-Armee 025",1753,28149,380,507,0,592635],
  ["Terrakotta-Armee 026",1797,29940,400,494,0,619500],
  ["Terrakotta-Armee 027",1842,31782,415,473,0,647130],
  ["Terrakotta-Armee 028",1888,33670,435,452,0,675450],
  ["Terrakotta-Armee 029",1935,35605,455,442,0,704475],
  ["Terrakotta-Armee 030",1983,37588,475,424,0,734220],
  ["Terrakotta-Armee 031",2033,39621,495,407,0,764715],
  ["Terrakotta-Armee 032",2084,41705,510,420,0,795975],
  ["Terrakotta-Armee 033",2136,43841,530,396,0,828015],
  ["Terrakotta-Armee 034",2189,46030,550,392,0,860850],
  ["Terrakotta-Armee 035",2244,48274,570,380,0,894510],
  ["Terrakotta-Armee 036",2300,50574,590,370,0,929010],
  ["Terrakotta-Armee 037",2357,52931,610,370,0,964365],
  ["Terrakotta-Armee 038",2416,55347,630,362,0,1000605],
  ["Terrakotta-Armee 039",2477,57824,650,348,0,1037760],
  ["Terrakotta-Armee 040",2539,60363,670,353,0,1075845],
  ["Terrakotta-Armee 041",2602,62965,690,349,0,1114875],
  ["Terrakotta-Armee 042",2667,65632,715,329,0,1154880],
  ["Terrakotta-Armee 043",2734,68366,735,329,0,1195890],
  ["Terrakotta-Armee 044",2802,71168,755,340,0,1237920],
  ["Terrakotta-Armee 045",2872,74040,775,334,0,1281000],
  ["Terrakotta-Armee 046",2944,76984,795,339,0,1325160],
  ["Terrakotta-Armee 047",3017,80001,815,355,0,1370415],
  ["Terrakotta-Armee 048",3093,83094,840,356,0,1416810],
  ["Terrakotta-Armee 049",3170,86264,860,366,0,1464360],
  ["Terrakotta-Armee 050",3249,89513,880,388,0,1513095],
  ["Terrakotta-Armee 051",3331,92844,900,386,0,1563060],
  ["Terrakotta-Armee 052",3414,96258,925,382,0,1614270],
  ["Terrakotta-Armee 053",3499,99757,945,401,0,1666755],
  ["Terrakotta-Armee 054",3587,103344,965,432,0,1720560],
  ["Terrakotta-Armee 055",3676,107020,990,445,0,1775700],
  ["Terrakotta-Armee 056",3768,110788,1010,461,0,1832220],
  ["Terrakotta-Armee 057",3862,114650,1030,498,0,1890150],
  ["Terrakotta-Armee 058",3959,118609,1055,509,0,1949535],
  ["Terrakotta-Armee 059",4058,122667,1075,542,0,2010405],
  ["Terrakotta-Armee 060",4159,126826,1095,576,0,2072790],
  ["Terrakotta-Armee 061",4263,131089,1120,614,0,2136735],
  ["Terrakotta-Armee 062",4370,135459,1140,646,0,2202285],
  ["Terrakotta-Armee 063",4479,139938,1165,668,0,2269470],
  ["Terrakotta-Armee 064",4591,144529,1185,714,0,2338335],
  ["Terrakotta-Armee 065",4706,149235,1210,763,0,2408925],
  ["Terrakotta-Armee 066",4824,154059,1230,814,0,2481285],
  ["Terrakotta-Armee 067",4944,159003,1255,839,0,2555445],
  ["Terrakotta-Armee 068",5068,164071,1275,896,0,2631465],
  ["Terrakotta-Armee 069",5194,169265,1300,956,0,2709375],
  ["Terrakotta-Armee 070",5324,174589,1320,1020,0,2789235],
  ["Terrakotta-Armee 071",5457,180046,1345,1066,0,2871090],
  ["Terrakotta-Armee 072",5594,185640,1365,1128,0,2955000],
  ["Terrakotta-Armee 073",5734,191374,1390,1202,0,3041010],
  ["Terrakotta-Armee 074",5877,197251,1415,1259,0,3129165],
  ["Terrakotta-Armee 075",6024,203275,1435,1340,0,3219525],
  ["Terrakotta-Armee 076",6174,209449,1460,1414,0,3312135],
  ["Terrakotta-Armee 077",6329,215778,1485,1463,0,3407070],
  ["Terrakotta-Armee 078",6487,222265,1505,1564,0,3504375],
  ["Terrakotta-Armee 079",6649,228914,1530,1650,0,3604110],
  ["Terrakotta-Armee 080",6815,235729,1555,1731,0,3706335],
  ["Terrakotta-Armee 081",6986,242715,1575,1835,0,3811125],
  ["Terrakotta-Armee 082",7160,249875,1600,1943,0,3918525],
  ["Terrakotta-Armee 083",7339,257214,1625,2027,0,4028610],
  ["Terrakotta-Armee 084",7523,264737,1645,2144,0,4141455],
  ["Terrakotta-Armee 085",7711,272448,1670,2257,0,4257120],
  ["Terrakotta-Armee 086",7904,280352,1695,2364,0,4375680],
  ["Terrakotta-Armee 087",8101,288453,1720,2495,0,4497195],
  ["Terrakotta-Armee 088",8304,296757,1745,2602,0,4621755],
  ["Terrakotta-Armee 089",8511,305268,1765,2742,0,4749420],
  ["Terrakotta-Armee 090",8724,313992,1790,2880,0,4880280],
  ["Terrakotta-Armee 091",8942,322934,1815,3012,0,5014410],
  ["Terrakotta-Armee 092",9166,332100,1840,3170,0,5151900],
  ["Terrakotta-Armee 093",9395,341495,1860,3324,0,5292825],
  ["Terrakotta-Armee 094",9630,351125,1885,3472,0,5437275],
  ["Terrakotta-Armee 095",9870,360995,1910,3637,0,5585325],
  ["Terrakotta-Armee 096",10117,371112,1935,3798,0,5737080],
  ["Terrakotta-Armee 097",10370,381482,1960,3985,0,5892630],
  ["Terrakotta-Armee 098",10629,392111,1985,4148,0,6052065],
  ["Terrakotta-Armee 099",10895,403006,2010,4338,0,6215490],
  ["Terrakotta-Armee 100",11167,414173,2030,4544,0,6382995],
  ["Terrakotta-Armee 101",11447,425620,2055,4738,0,6554700],
  ["Terrakotta-Armee 102",11733,437353,2080,4958,0,6730695],
  ["Terrakotta-Armee 103",12026,449379,2105,5147,0,6911085],
  ["Terrakotta-Armee 104",12327,461706,2130,5372,0,7095990],
  ["Terrakotta-Armee 105",12635,474341,2155,5595,0,7285515],
  ["Terrakotta-Armee 106",12951,487292,2180,5835,0,7479780],
  ["Terrakotta-Armee 107",13274,500566,2205,6062,0,7678890],
  ["Terrakotta-Armee 108",13606,514172,2230,6328,0,7882980],
  ["Terrakotta-Armee 109",13946,528118,2255,6582,0,8092170],
  ["Terrakotta-Armee 110",14295,542413,2280,6856,0,8306595],
  ["Terrakotta-Armee 111",14652,557065,2305,7126,0,8526375],
  ["Terrakotta-Armee 112",15019,572084,2330,7409,0,8751660],
  ["Turm zu Babel 000",0,0,0,0,0,415],
  ["Turm zu Babel 001",40,40,5,20,0,1015],
  ["Turm zu Babel 002",60,100,10,31,0,1915],
  ["Turm zu Babel 003",100,200,10,71,0,3415],
  ["Turm zu Babel 004",150,350,15,92,0,5665],
  ["Turm zu Babel 005",210,560,25,123,0,8815],
  ["Turm zu Babel 006",270,830,30,174,0,12865],
  ["Turm zu Babel 007",330,1160,35,215,0,17815],
  ["Turm zu Babel 008",380,1540,40,256,0,23515],
  ["Turm zu Babel 009",450,1990,45,287,0,30265],
  ["Turm zu Babel 010",510,2500,55,319,0,37915],
  ["Turm zu Babel 011",523,3023,60,323,0,45760],
  ["Turm zu Babel 012",536,3559,65,316,0,53800],
  ["Turm zu Babel 013",550,4109,75,292,0,62050],
  ["Turm zu Babel 014",563,4672,80,296,0,70495],
  ["Turm zu Babel 015",578,5250,85,291,0,79165],
  ["Turm zu Babel 016",592,5842,95,277,0,88045],
  ["Turm zu Babel 017",607,6449,100,283,0,97150],
  ["Turm zu Babel 018",622,7071,110,260,0,106480],
  ["Turm zu Babel 019",637,7708,115,256,0,116035],
  ["Turm zu Babel 020",653,8361,125,243,0,125830],
  ["Turm zu Babel 021",670,9031,130,251,0,135880],
  ["Turm zu Babel 022",686,9717,140,229,0,146170],
  ["Turm zu Babel 023",704,10421,145,227,0,156730],
  ["Turm zu Babel 024",721,11142,155,216,0,167545],
  ["Turm zu Babel 025",739,11881,160,225,0,178630],
  ["Turm zu Babel 026",758,12639,170,197,0,190000],
  ["Turm zu Babel 027",777,13416,180,188,0,201655],
  ["Turm zu Babel 028",796,14212,185,187,0,213595],
  ["Turm zu Babel 029",816,15028,195,169,0,225835],
  ["Turm zu Babel 030",836,15864,200,180,0,238375],
  ["Turm zu Babel 031",857,16721,210,172,0,251230],
  ["Turm zu Babel 032",879,17600,220,166,0,264415],
  ["Turm zu Babel 033",900,18500,225,158,0,277915],
  ["Turm zu Babel 034",923,19423,235,153,0,291760],
  ["Turm zu Babel 035",946,20369,245,147,0,305950],
  ["Turm zu Babel 036",970,21339,250,162,0,320500],
  ["Turm zu Babel 037",994,22333,260,148,0,335410],
  ["Turm zu Babel 038",1019,23352,270,144,0,350695],
  ["Turm zu Babel 039",1044,24396,275,150,0,366355],
  ["Turm zu Babel 040",1070,25466,285,118,0,382405],
  ["Turm zu Babel 041",1097,26563,295,117,0,398860],
  ["Turm zu Babel 042",1124,27687,300,135,0,415720],
  ["Turm zu Babel 043",1153,28840,310,135,0,433015],
  ["Turm zu Babel 044",1181,30021,320,125,0,450730],
  ["Turm zu Babel 045",1211,31232,330,126,0,468895],
  ["Turm zu Babel 046",1241,32473,340,128,0,487510],
  ["Turm zu Babel 047",1272,33745,345,130,0,506590],
  ["Turm zu Babel 048",1304,35049,355,134,0,526150],
  ["Turm zu Babel 049",1336,36385,365,137,0,546190],
  ["Turm zu Babel 050",1370,37755,375,133,0,566740],
  ["Turm zu Babel 051",1404,39159,380,158,0,587800],
  ["Turm zu Babel 052",1439,40598,390,164,0,609385],
  ["Turm zu Babel 053",1475,42073,400,172,0,631510],
  ["Turm zu Babel 054",1512,43585,410,162,0,654190],
  ["Turm zu Babel 055",1550,45135,420,172,0,677440],
  ["Turm zu Babel 056",1589,46724,430,182,0,701275],
  ["Turm zu Babel 057",1628,48352,440,183,0,725695],
  ["Turm zu Babel 058",1669,50021,445,204,0,750730],
  ["Turm zu Babel 059",1711,51732,455,218,0,776395],
  ["Turm zu Babel 060",1753,53485,465,222,0,802690],
  ["Turm zu Babel 061",1797,55282,475,238,0,829645],
  ["Turm zu Babel 062",1842,57124,485,254,0,857275],
  ["Turm zu Babel 063",1888,59012,495,262,0,885595],
  ["Turm zu Babel 064",1935,60947,505,280,0,914620],
  ["Turm zu Babel 065",1984,62931,510,320,0,944380],
  ["Turm zu Babel 066",2033,64964,520,341,0,974875],
  ["Turm zu Babel 067",2084,67048,530,344,0,1006135],
  ["Turm zu Babel 068",2136,69184,540,368,0,1038175],
  ["Turm zu Babel 069",2190,71374,550,393,0,1071025],
  ["Turm zu Babel 070",2244,73618,560,409,0,1104685],
  ["Turm zu Babel 071",2300,75918,570,436,0,1139185],
  ["Turm zu Babel 072",2358,78276,580,466,0,1174555],
  ["Turm zu Babel 073",2417,80693,590,487,0,1210810],
  ["Turm zu Babel 074",2477,83170,600,519,0,1247965],
  ["Turm zu Babel 075",2539,85709,610,552,0,1286050],
  ["Turm zu Babel 076",2603,88312,620,578,0,1325095],
  ["Turm zu Babel 077",2668,90980,630,614,0,1365115],
  ["Turm zu Babel 078",2734,93714,640,652,0,1406125],
  ["Turm zu Babel 079",2803,96517,650,674,0,1448170],
  ["Turm zu Babel 080",2873,99390,660,716,0,1491265],
  ["Turm zu Babel 081",2945,102335,670,759,0,1535440],
  ["Turm zu Babel 082",3018,105353,680,794,0,1580710],
  ["Turm zu Babel 083",3094,108447,690,841,0,1627120],
  ["Turm zu Babel 084",3171,111618,700,890,0,1674685],
  ["Turm zu Babel 085",3250,114868,710,931,0,1723435],
  ["Turm zu Babel 086",3332,118200,720,985,0,1773415],
  ["Turm zu Babel 087",3415,121615,730,1039,0,1824640],
  ["Turm zu Babel 088",3500,125115,740,1086,0,1877140],
  ["Turm zu Babel 089",3588,128703,750,1145,0,1930960],
  ["Turm zu Babel 090",3677,132380,760,1206,0,1986115],
  ["Turm zu Babel 091",3769,136149,770,1250,0,2042650],
  ["Turm zu Babel 092",3864,140013,780,1317,0,2100610],
  ["Turm zu Babel 093",3960,143973,790,1384,0,2160010],
  ["Turm zu Babel 094",4059,148032,800,1445,0,2220895],
  ["Turm zu Babel 095",4161,152193,810,1518,0,2283310],
  ["Turm zu Babel 096",4265,156458,820,1594,0,2347285],
  ["Turm zu Babel 097",4371,160829,830,1662,0,2412850],
  ["Turm zu Babel 098",4480,165309,840,1743,0,2480050],
  ["Turm zu Babel 099",4592,169901,850,1826,0,2548930],
  ["Turm zu Babel 100",4707,174608,860,1903,0,2619535],
  ["Turm zu Babel 101",4825,179433,870,1992,0,2691910],
  ["Turm zu Babel 102",4945,184378,880,2084,0,2766085],
  ["Turm zu Babel 103",5069,189447,890,2152,0,2842120],
  ["Turm zu Babel 104",5196,194643,905,2231,0,2920060],
  ["Turm zu Babel 105",5326,199969,915,2323,0,2999950],
  ["Turm zu Babel 106",5459,205428,925,2427,0,3081835],
  ["Turm zu Babel 107",5595,211023,935,2535,0,3165760],
  ["Turm zu Babel 108",5735,216758,945,2637,0,3251785],
  ["Turm zu Babel 109",5879,222637,955,2753,0,3339970],
  ["Turm zu Babel 110",6025,228662,965,2870,0,3430345],
  ["Turm zu Babel 111",6176,234838,975,2983,0,3522985],
  ["Turm zu Babel 112",6331,241169,985,3109,0,3617950],
  ["Turm zu Babel 113",6489,247658,995,3239,0,3715285],
  ["Turm zu Babel 114",6651,254309,1010,3344,114,3815050],
  ["Turm zu Babel 115",6817,261126,1020,3482,115,3917305],
  ["Turm zu Babel 116",6988,268114,1030,3624,116,4022125],
  ["Turm zu Babel 117",7162,275276,1040,3760,117,4129555],
  ["Turm zu Babel 118",7341,282617,1050,3910,118,4239670],
  ["Turm zu Babel 119",7525,290142,1060,4066,119,4352545],
  ["Turm zu Babel 120",7713,297855,1070,4216,120,4468240],
  ["Turm zu Babel 121",7906,305761,1085,4361,121,4586830],
  ["Turm zu Babel 122",8103,313864,1095,4520,122,4708375],
  ["Turm zu Babel 123",8306,322170,1105,4694,123,4832965],
  ["Turm zu Babel 124",8514,330684,1115,4874,124,4960675],
  ["Turm zu Babel 125",8726,339410,1125,5039,125,5091565],
  ["Turm zu Babel 126",8945,348355,1135,5230,126,5225740],
  ["Turm zu Babel 127",9168,357523,1150,5415,127,5363260],
  ["Turm zu Babel 128",9397,366920,1160,5606,128,5504215],
  ["Turm zu Babel 129",9632,376552,1170,5812,129,5648695],
  ["Turm zu Babel 130",9873,386425,1180,6025,130,5796790],
  ["Turm zu Babel 131",10120,396545,1190,6234,131,5948590],
  ["Turm zu Babel 132",10373,406918,1200,6459,132,6104185],
  ["Turm zu Babel 133",10632,417550,1215,6660,133,6263665],
  ["Turm zu Babel 134",10898,428448,1225,6897,134,6427135],
  ["Turm zu Babel 135",11171,439619,1235,7142,135,6594700],
  ["Turm zu Babel 136",11450,451069,1245,7373,136,6766450],
  ["Turm zu Babel 137",11736,462805,1255,7631,137,6942490],
  ["Turm zu Babel 138",12029,474834,1270,7886,138,7122925],
  ["Turm zu Babel 139",12330,487164,1280,8149,139,7307875],
  ["Turm zu Babel 140",12638,499802,1290,8428,140,7497445],
  ["Turm zu Babel 141",12954,512756,1300,8716,141,7691755],
  ["Turm zu Babel 142",13278,526034,1310,9002,142,7890925],
  ["Turm zu Babel 143",13610,539644,1325,9286,143,8095075],
  ["Turm zu Babel 144",13950,553594,1335,9588,144,8304325],
  ["Turm zu Babel 145",14299,567893,1345,9908,145,8518810],
  ["Turm zu Babel 146",14657,582550,1355,10238,146,8738665],
  ["Turm zu Babel 147",15023,597573,1370,10548,147,8964010],
  ["Turm zu Babel 148",15398,612971,1380,10895,148,9194980],
  ["Turm zu Babel 149",15783,628754,1390,11251,149,9431725],
  ["Turm zu Babel 150",16178,644932,1400,11608,150,9674395],
  ["Turm zu Babel 151",16582,661514,1415,11964,151,9923125],
  ["Turm zu Babel 152",16997,678511,1425,12341,152,10178080],
  ["Turm zu Babel 153",17422,695933,1435,12738,153,10439410],
  ["Turm zu Babel 154",17857,713790,1445,13144,154,10707265],
  ["Turm zu Babel 155",18304,732094,1460,13544,155,10981825],
  ["Voyager V1 000",0,0,0,0,0,64562.5],
  ["Voyager V1 001",70,70,10,41,0,65612.5],
  ["Voyager V1 002",100,170,15,42,0,67112.5],
  ["Voyager V1 003",190,360,20,123,0,69962.5],
  ["Voyager V1 004",280,640,35,165,0,74162.5],
  ["Voyager V1 005",380,1020,45,217,0,79862.5],
  ["Voyager V1 006",480,1500,55,289,0,87062.5],
  ["Voyager V1 007",590,2090,65,370,0,95912.5],
  ["Voyager V1 008",710,2800,80,443,0,106562.5],
  ["Voyager V1 009",810,3610,90,514,0,118712.5],
  ["Voyager V1 010",930,4540,105,577,0,132662.5],
  ["Voyager V1 011",954,5494,120,564,0,146972.5],
  ["Voyager V1 012",978,6472,130,559,0,161642.5],
  ["Voyager V1 013",1002,7474,145,525,0,176672.5],
  ["Voyager V1 014",1027,8501,160,513,0,192077.5],
  ["Voyager V1 015",1053,9554,175,473,0,207872.5],
  ["Voyager V1 016",1079,10633,185,470,0,224057.5],
  ["Voyager V1 017",1106,11739,200,450,0,240647.5],
  ["Voyager V1 018",1134,12873,215,430,0,257657.5],
  ["Voyager V1 019",1162,14035,230,411,0,275087.5],
  ["Voyager V1 020",1191,15226,245,392,0,292952.5],
  ["Voyager V1 021",1221,16447,260,375,0,311267.5],
  ["Voyager V1 022",1251,17698,275,357,0,330032.5],
  ["Voyager V1 023",1283,18981,290,322,0,349277.5],
  ["Voyager V1 024",1315,20296,305,306,0,369002.5],
  ["Voyager V1 025",1347,21643,320,291,0,389207.5],
  ["Voyager V1 026",1381,23024,335,277,0,409922.5],
  ["Voyager V1 027",1416,24440,355,246,0,431162.5],
  ["Voyager V1 028",1451,25891,370,243,0,452927.5],
  ["Voyager V1 029",1487,27378,385,221,0,475232.5],
  ["Voyager V1 030",1524,28902,400,221,0,498092.5],
  ["Voyager V1 031",1563,30465,420,185,0,521537.5],
  ["Voyager V1 032",1602,32067,435,166,0,545567.5],
  ["Voyager V1 033",1642,33709,450,168,0,570197.5],
  ["Voyager V1 034",1683,35392,465,152,0,595442.5],
  ["Voyager V1 035",1725,37117,485,137,0,621317.5],
  ["Voyager V1 036",1768,38885,500,133,0,647837.5],
  ["Voyager V1 037",1812,40697,515,129,0,675017.5],
  ["Voyager V1 038",1857,42554,535,98,0,702872.5],
  ["Voyager V1 039",1904,44458,550,107,0,731432.5],
  ["Voyager V1 040",1951,46409,570,87,0,760697.5],
  ["Voyager V1 041",2000,48409,585,79,0,790697.5],
  ["Voyager V1 042",2050,50459,605,72,0,821447.5],
  ["Voyager V1 043",2101,52560,620,76,0,852962.5],
  ["Voyager V1 044",2154,54714,640,72,0,885272.5],
  ["Voyager V1 045",2208,56922,655,60,0,918392.5],
  ["Voyager V1 046",2263,59185,675,48,0,952337.5],
  ["Voyager V1 047",2319,61504,690,66,0,987122.5],
  ["Voyager V1 048",2377,63874,710,58,0,1022672.5],
  ["Voyager V1 049",2437,66311,730,61,0,1059227.5],
  ["Voyager V1 050",2498,68809,745,64,0,1096697.5],
  ["Voyager V1 051",2560,71369,765,50,0,1135097.5],
  ["Voyager V1 052",2624,73993,780,77,0,1174457.5],
  ["Voyager V1 053",2690,76683,800,76,0,1214807.5],
  ["Voyager V1 054",2757,79440,820,86,0,1256162.5],
  ["Voyager V1 055",2826,82266,835,98,0,1298552.5],
  ["Voyager V1 056",2896,85162,855,101,0,1341992.5],
  ["Voyager V1 057",2969,88131,875,117,0,1386527.5],
  ["Voyager V1 058",3043,91174,890,126,0,1432172.5],
  ["Voyager V1 059",3119,94293,910,145,0,1478957.5],
  ["Voyager V1 060",3197,97490,930,156,0,1526912.5],
  ["Voyager V1 061",3277,100767,945,179,0,1576067.5],
  ["Voyager V1 062",3359,104126,965,204,0,1626452.5],
  ["Voyager V1 063",3443,107569,985,221,0,1678097.5],
  ["Voyager V1 064",3529,111098,1005,231,0,1731032.5],
  ["Voyager V1 065",3617,114715,1025,262,0,1785287.5],
  ["Voyager V1 066",3707,118422,1040,305,0,1840892.5],
  ["Voyager V1 067",3800,122222,1060,341,0,1897892.5],
  ["Voyager V1 068",3895,126117,1080,370,0,1956317.5],
  ["Voyager V1 069",3993,130110,1100,401,0,2016212.5],
  ["Voyager V1 070",4092,134202,1120,443,0,2077592.5],
  ["Voyager V1 071",4195,138397,1140,471,0,2140517.5],
  ["Voyager V1 072",4299,142696,1155,517,0,2205002.5],
  ["Voyager V1 073",4407,147103,1175,568,0,2271107.5],
  ["Voyager V1 074",4517,151620,1195,612,0,2338862.5],
  ["Voyager V1 075",4630,156250,1215,658,0,2408312.5],
  ["Voyager V1 076",4746,160996,1235,717,0,2479502.5],
  ["Voyager V1 077",4864,165860,1255,759,0,2552462.5],
  ["Voyager V1 078",4986,170828,1275,814,0,2626982.5],
  ["Voyager V1 079",5111,175939,1295,882,0,2703647.5],
  ["Voyager V1 080",5238,181177,1315,943,0,2782217.5],
  ["Voyager V1 081",5369,186546,1335,1007,0,2862752.5],
  ["Voyager V1 082",5504,192050,1355,1085,0,2945312.5],
  ["Voyager V1 083",5641,197691,1375,1147,0,3029927.5],
  ["Voyager V1 084",5782,203473,1395,1221,0,3116657.5],
  ["Voyager V1 085",5927,209400,1415,1309,0,3205562.5],
  ["Voyager V1 086",6075,215475,1435,1391,0,3296687.5],
  ["Voyager V1 087",6227,221702,1455,1476,0,3390092.5],
  ["Voyager V1 088",6382,228084,1475,1574,0,3485822.5],
  ["Voyager V1 089",6542,234626,1495,1648,0,3583952.5],
  ["Voyager V1 090",6705,241331,1515,1744,0,3684527.5],
  ["Voyager V1 091",6873,248204,1535,1855,0,3787622.5],
  ["Voyager V1 092",7045,255249,1555,1961,0,3893297.5],
  ["Voyager V1 093",7221,262470,1575,2070,0,4001612.5],
  ["Voyager V1 094",7401,269871,1595,2193,0,4112627.5],
  ["Voyager V1 095",7586,277457,1615,2303,0,4226417.5],
  ["Voyager V1 096",7776,285233,1640,2417,0,4343057.5],
  ["Voyager V1 097",7971,293204,1660,2555,0,4462622.5],
  ["Voyager V1 098",8170,301374,1680,2688,0,4585172.5],
  ["Voyager V1 099",8374,309748,1700,2825,0,4710782.5],
  ["Voyager V1 100",8583,318331,1720,2977,0,4839527.5],
  ["Voyager V1 101",8798,327129,1740,3116,0,4971497.5],
  ["Voyager V1 102",9018,336147,1760,3269,0,5106767.5],
  ["Weltraumfrachter 000",0,0,0,0,0,417000],
  ["Weltraumfrachter 001",90,90,10,61,0,418350],
  ["Weltraumfrachter 002",150,240,15,92,0,420600],
  ["Weltraumfrachter 003",260,500,30,164,0,424500],
  ["Weltraumfrachter 004",390,890,40,266,0,430350],
  ["Weltraumfrachter 005",540,1430,55,349,0,438450],
  ["Weltraumfrachter 006",670,2100,70,441,0,448500],
  ["Weltraumfrachter 007",830,2930,85,543,0,460950],
  ["Weltraumfrachter 008",990,3920,100,666,0,475800],
  ["Weltraumfrachter 009",1140,5060,115,759,0,492900],
  ["Weltraumfrachter 010",1300,6360,130,881,0,512400],
  ["Weltraumfrachter 011",1333,7693,150,847,0,532395],
  ["Weltraumfrachter 012",1366,9059,165,814,0,552885],
  ["Weltraumfrachter 013",1400,10459,185,791,0,573885],
  ["Weltraumfrachter 014",1435,11894,200,779,0,595410],
  ["Weltraumfrachter 015",1471,13365,220,758,0,617475],
  ["Weltraumfrachter 016",1508,14873,235,738,0,640095],
  ["Weltraumfrachter 017",1546,16419,255,709,0,663285],
  ["Weltraumfrachter 018",1584,18003,275,690,0,687045],
  ["Weltraumfrachter 019",1624,19627,295,644,0,711405],
  ["Weltraumfrachter 020",1665,21292,310,647,0,736380],
  ["Weltraumfrachter 021",1706,22998,330,621,0,761970],
  ["Weltraumfrachter 022",1749,24747,350,598,0,788205],
  ["Weltraumfrachter 023",1793,26540,370,585,0,815100],
  ["Weltraumfrachter 024",1837,28377,390,562,0,842655],
  ["Weltraumfrachter 025",1883,30260,410,533,0,870900],
  ["Weltraumfrachter 026",1930,32190,430,523,0,899850],
  ["Weltraumfrachter 027",1979,34169,450,505,0,929535],
  ["Weltraumfrachter 028",2028,36197,470,488,0,959955],
  ["Weltraumfrachter 029",2079,38276,490,482,0,991140],
  ["Weltraumfrachter 030",2131,40407,510,467,0,1023105],
  ["Weltraumfrachter 031",2184,42591,530,444,0,1055865],
  ["Weltraumfrachter 032",2239,44830,550,442,0,1089450],
  ["Weltraumfrachter 033",2294,47124,575,411,0,1123860],
  ["Weltraumfrachter 034",2352,49476,595,403,0,1159140],
  ["Weltraumfrachter 035",2411,51887,615,395,0,1195305],
  ["Weltraumfrachter 036",2471,54358,635,398,0,1232370],
  ["Weltraumfrachter 037",2533,56891,660,376,0,1270365],
  ["Weltraumfrachter 038",2596,59487,680,372,0,1309305],
  ["Weltraumfrachter 039",2661,62148,700,380,0,1349220],
  ["Weltraumfrachter 040",2727,64875,725,360,0,1390125],
  ["Weltraumfrachter 041",2796,67671,745,362,0,1432065],
  ["Weltraumfrachter 042",2865,70536,770,346,0,1475040],
  ["Weltraumfrachter 043",2937,73473,790,361,0,1519095],
  ["Weltraumfrachter 044",3010,76483,810,367,0,1564245],
  ["Weltraumfrachter 045",3086,79569,835,358,0,1610535],
  ["Weltraumfrachter 046",3163,82732,855,368,0,1657980],
  ["Weltraumfrachter 047",3242,85974,880,381,0,1706610],
  ["Weltraumfrachter 048",3323,89297,905,358,0,1756455],
  ["Weltraumfrachter 049",3406,92703,925,374,0,1807545],
  ["Weltraumfrachter 050",3491,96194,950,384,0,1859910],
  ["Weltraumfrachter 051",3578,99772,970,414,0,1913580],
  ["Weltraumfrachter 052",3668,103440,995,418,0,1968600],
  ["Weltraumfrachter 053",3759,107199,1015,433,0,2024985],
  ["Weltraumfrachter 054",3853,111052,1040,451,0,2082780],
  ["Weltraumfrachter 055",3950,115002,1065,462,0,2142030],
  ["Weltraumfrachter 056",4049,119051,1085,504,0,2202765],
  ["Weltraumfrachter 057",4150,123201,1110,529,0,2265015],
  ["Weltraumfrachter 058",4253,127454,1135,538,0,2328810],
  ["Weltraumfrachter 059",4360,131814,1160,569,0,2394210],
  ["Weltraumfrachter 060",4469,136283,1180,621,0,2461245],
  ["Weltraumfrachter 061",4580,140863,1205,646,0,2529945],
  ["Weltraumfrachter 062",4695,145558,1230,685,0,2600370],
  ["Weltraumfrachter 063",4812,150370,1255,707,0,2672550],
  ["Weltraumfrachter 064",4933,155303,1275,761,0,2746545],
  ["Weltraumfrachter 065",5056,160359,1300,818,0,2822385],
  ["Weltraumfrachter 066",5182,165541,1325,858,0,2900115],
  ["Weltraumfrachter 067",5312,170853,1350,912,0,2979795],
  ["Weltraumfrachter 068",5445,176298,1375,951,0,3061470],
  ["Weltraumfrachter 069",5581,181879,1400,1011,0,3145185],
  ["Weltraumfrachter 070",5720,187599,1425,1064,0,3230985],
  ["Weltraumfrachter 071",5863,193462,1450,1141,0,3318930],
  ["Weltraumfrachter 072",6010,199472,1470,1221,0,3409080],
  ["Weltraumfrachter 073",6160,205632,1500,1257,0,3501480],
  ["Weltraumfrachter 074",6314,211946,1520,1344,0,3596190],
  ["Weltraumfrachter 075",6472,218418,1545,1416,0,3693270],
  ["Weltraumfrachter 076",6634,225052,1570,1512,0,3792780],
  ["Weltraumfrachter 077",6799,231851,1595,1591,0,3894765],
  ["Weltraumfrachter 078",6969,238820,1620,1677,0,3999300],
  ["Weltraumfrachter 079",7144,245964,1650,1756,0,4106460],
  ["Weltraumfrachter 080",7322,253286,1670,1868,0,4216290],
  ["Weltraumfrachter 081",7505,260791,1695,1965,0,4328865],
  ["Weltraumfrachter 082",7693,268484,1725,2048,0,4444260],
  ["Weltraumfrachter 083",7885,276369,1750,2174,0,4562535],
  ["Weltraumfrachter 084",8082,284451,1775,2285,0,4683765],
  ["Weltraumfrachter 085",8284,292735,1800,2412,0,4808025],
  ["Weltraumfrachter 086",8491,301226,1825,2532,0,4935390],
  ["Weltraumfrachter 087",8704,309930,1850,2661,0,5065950],
  ["Weltraumfrachter 088",8921,318851,1875,2792,0,5199765],
  ["Weltraumfrachter 089",9144,327995,1900,2949,0,5336925],
  ["Weltraumfrachter 090",9373,337368,1930,3083,0,5477520],
  ["Weltraumfrachter 091",9607,346975,1955,3231,0,5621625],
  ["Weltraumfrachter 092",9847,356822,1980,3386,0,5769330],
  ["Weltraumfrachter 093",10094,366916,2005,3546,0,5920740],
  ["Weltraumfrachter 094",10346,377262,2030,3723,0,6075930],
  ["Weltraumfrachter 095",10605,387867,2060,3887,0,6235005],
  ["Weltraumfrachter 096",10870,398737,2085,4048,0,6398055],
  ["Weltraumfrachter 097",11141,409878,2110,4253,0,6565170],
  ["Weltraumfrachter 098",11420,421298,2135,4446,0,6736470],
  ["Weltraumfrachter 099",11705,433003,2160,4656,0,6912045],
  ["Weltraumfrachter 100",11998,445001,2190,4853,0,7092015],
  ["Zeusstatue 000",0,0,0,0,0,390],
  ["Zeusstatue 001",40,40,5,20,0,990],
  ["Zeusstatue 002",60,100,10,31,0,1890],
  ["Zeusstatue 003",100,200,10,71,0,3390],
  ["Zeusstatue 004",150,350,15,92,0,5640],
  ["Zeusstatue 005",210,560,25,123,0,8790],
  ["Zeusstatue 006",270,830,30,174,0,12840],
  ["Zeusstatue 007",330,1160,35,215,0,17790],
  ["Zeusstatue 008",380,1540,40,256,0,23490],
  ["Zeusstatue 009",450,1990,45,287,0,30240],
  ["Zeusstatue 010",510,2500,55,319,0,37890],
  ["Zeusstatue 011",523,3023,60,323,0,45735],
  ["Zeusstatue 012",536,3559,65,316,0,53775],
  ["Zeusstatue 013",550,4109,75,292,0,62025],
  ["Zeusstatue 014",563,4672,80,296,0,70470],
  ["Zeusstatue 015",578,5250,85,291,0,79140],
  ["Zeusstatue 016",592,5842,95,277,0,88020],
  ["Zeusstatue 017",607,6449,100,283,0,97125],
  ["Zeusstatue 018",622,7071,110,260,0,106455],
  ["Zeusstatue 019",637,7708,115,256,0,116010],
  ["Zeusstatue 020",653,8361,125,243,0,125805],
  ["Zeusstatue 021",670,9031,130,251,0,135855],
  ["Zeusstatue 022",686,9717,140,229,0,146145],
  ["Zeusstatue 023",704,10421,145,227,0,156705],
  ["Zeusstatue 024",721,11142,155,216,0,167520],
  ["Zeusstatue 025",739,11881,160,225,0,178605],
  ["Zeusstatue 026",758,12639,170,197,0,189975],
  ["Zeusstatue 027",777,13416,180,188,0,201630],
  ["Zeusstatue 028",796,14212,185,187,0,213570],
  ["Zeusstatue 029",816,15028,195,169,0,225810],
  ["Zeusstatue 030",836,15864,200,180,0,238350],
  ["Zeusstatue 031",857,16721,210,172,0,251205],
  ["Zeusstatue 032",879,17600,220,166,0,264390],
  ["Zeusstatue 033",900,18500,225,158,0,277890],
  ["Zeusstatue 034",923,19423,235,153,0,291735],
  ["Zeusstatue 035",946,20369,245,147,0,305925],
  ["Zeusstatue 036",970,21339,250,162,0,320475],
  ["Zeusstatue 037",994,22333,260,148,0,335385],
  ["Zeusstatue 038",1019,23352,270,144,0,350670],
  ["Zeusstatue 039",1044,24396,275,150,0,366330],
  ["Zeusstatue 040",1070,25466,285,118,0,382380],
  ["Zeusstatue 041",1097,26563,295,117,0,398835],
  ["Zeusstatue 042",1124,27687,300,135,0,415695],
  ["Zeusstatue 043",1153,28840,310,135,0,432990],
  ["Zeusstatue 044",1181,30021,320,125,0,450705],
  ["Zeusstatue 045",1211,31232,330,126,0,468870],
  ["Zeusstatue 046",1241,32473,340,128,0,487485],
  ["Zeusstatue 047",1272,33745,345,130,0,506565],
  ["Zeusstatue 048",1304,35049,355,134,0,526125],
  ["Zeusstatue 049",1336,36385,365,137,0,546165],
  ["Zeusstatue 050",1370,37755,375,133,0,566715],
  ["Zeusstatue 051",1404,39159,380,158,0,587775],
  ["Zeusstatue 052",1439,40598,390,164,0,609360],
  ["Zeusstatue 053",1475,42073,400,172,0,631485],
  ["Zeusstatue 054",1512,43585,410,162,0,654165],
  ["Zeusstatue 055",1550,45135,420,172,0,677415],
  ["Zeusstatue 056",1589,46724,430,182,0,701250],
  ["Zeusstatue 057",1628,48352,440,183,0,725670],
  ["Zeusstatue 058",1669,50021,445,204,0,750705],
  ["Zeusstatue 059",1711,51732,455,218,0,776370],
  ["Zeusstatue 060",1753,53485,465,222,0,802665],
  ["Zeusstatue 061",1797,55282,475,238,0,829620],
  ["Zeusstatue 062",1842,57124,485,254,0,857250],
  ["Zeusstatue 063",1888,59012,495,262,0,885570],
  ["Zeusstatue 064",1935,60947,505,280,0,914595],
  ["Zeusstatue 065",1984,62931,510,320,0,944355],
  ["Zeusstatue 066",2033,64964,520,341,0,974850],
  ["Zeusstatue 067",2084,67048,530,344,0,1006110],
  ["Zeusstatue 068",2136,69184,540,368,0,1038150],
  ["Zeusstatue 069",2190,71374,550,393,0,1071000],
  ["Zeusstatue 070",2244,73618,560,409,0,1104660],
  ["Zeusstatue 071",2300,75918,570,436,0,1139160],
  ["Zeusstatue 072",2358,78276,580,466,0,1174530],
  ["Zeusstatue 073",2417,80693,590,487,0,1210785],
  ["Zeusstatue 074",2477,83170,600,519,0,1247940],
  ["Zeusstatue 075",2539,85709,610,552,0,1286025],
  ["Zeusstatue 076",2603,88312,620,578,0,1325070],
  ["Zeusstatue 077",2668,90980,630,614,0,1365090],
  ["Zeusstatue 078",2734,93714,640,652,0,1406100],
  ["Zeusstatue 079",2803,96517,650,674,0,1448145],
  ["Zeusstatue 080",2873,99390,660,716,0,1491240],
  ["Zeusstatue 081",2945,102335,670,759,0,1535415],
  ["Zeusstatue 082",3018,105353,680,794,0,1580685],
  ["Zeusstatue 083",3094,108447,690,841,0,1627095],
  ["Zeusstatue 084",3171,111618,700,890,0,1674660],
  ["Zeusstatue 085",3250,114868,710,931,0,1723410],
  ["Zeusstatue 086",3332,118200,720,985,0,1773390],
  ["Zeusstatue 087",3415,121615,730,1039,0,1824615],
  ["Zeusstatue 088",3500,125115,740,1086,0,1877115],
  ["Zeusstatue 089",3588,128703,750,1145,0,1930935],
  ["Zeusstatue 090",3677,132380,760,1206,0,1986090],
  ["Zeusstatue 091",3769,136149,770,1250,0,2042625],
  ["Zeusstatue 092",3864,140013,780,1317,0,2100585],
  ["Zeusstatue 093",3960,143973,790,1384,0,2159985],
  ["Zeusstatue 094",4059,148032,800,1445,0,2220870],
  ["Zeusstatue 095",4161,152193,810,1518,0,2283285],
  ["Zeusstatue 096",4265,156458,820,1594,0,2347260],
  ["Zeusstatue 097",4371,160829,830,1662,0,2412825],
  ["Zeusstatue 098",4480,165309,840,1743,0,2480025],
  ["Zeusstatue 099",4592,169901,850,1826,0,2548905],
  ["Zeusstatue 100",4707,174608,860,1903,0,2619510],
  ["Zeusstatue 101",4825,179433,870,1992,0,2691885],
  ["Zeusstatue 102",4945,184378,880,2084,0,2766060],
  ["Zeusstatue 103",5069,189447,890,2152,0,2842095],
  ["Zeusstatue 104",5196,194643,905,2231,0,2920035],
  ["Zeusstatue 105",5326,199969,915,2323,0,2999925],
  ["Zeusstatue 106",5459,205428,925,2427,0,3081810],
  ["Zeusstatue 107",5595,211023,935,2535,0,3165735],
  ["Zeusstatue 108",5735,216758,945,2637,0,3251760],
  ["Zeusstatue 109",5879,222637,955,2753,0,3339945],
  ["Zeusstatue 110",6025,228662,965,2870,0,3430320],
  ["Zeusstatue 111",6176,234838,975,2983,0,3522960],
  ["Zeusstatue 112",6331,241169,985,3109,0,3617925],
  ["Zeusstatue 113",6489,247658,995,3239,0,3715260],
  ["Zeusstatue 114",6651,254309,1010,3344,0,3815025],
  ["Zeusstatue 115",6817,261126,1020,3482,0,3917280],
  ["Zeusstatue 116",6988,268114,1030,3624,0,4022100],
  ["Zeusstatue 117",7162,275276,1040,3760,0,4129530],
  ["Zeusstatue 118",7341,282617,1050,3910,0,4239645],
  ["Zeusstatue 119",7525,290142,1060,4066,0,4352520],
  ["Zeusstatue 120",7713,297855,1070,4216,0,4468215],
  ["Zeusstatue 121",7906,305761,1085,4361,0,4586805],
  ["Zeusstatue 122",8103,313864,1095,4520,0,4708350],
  ["Zeusstatue 123",8306,322170,1105,4694,0,4832940],
  ["Zeusstatue 124",8514,330684,1115,4874,0,4960650],
  ["Zeusstatue 125",8726,339410,1125,5039,0,5091540],
  ["Zeusstatue 126",8945,348355,1135,5230,0,5225715],
  ["Zeusstatue 127",9168,357523,1150,5415,0,5363235],
  ["Zeusstatue 128",9397,366920,1160,5606,0,5504190],
  ["Zeusstatue 129",9632,376552,1170,5812,0,5648670],
  ["Zeusstatue 130",9873,386425,1180,6025,0,5796765],
  ["Zeusstatue 131",10120,396545,1190,6234,0,5948565],
  ["Zeusstatue 132",10373,406918,1200,6459,0,6104160],
  ["Zeusstatue 133",10632,417550,1215,6660,0,6263640],
  ["Zeusstatue 134",10898,428448,1225,6897,0,6427110],
  ["Zeusstatue 135",11171,439619,1235,7142,0,6594675],
  ["Zeusstatue 136",11450,451069,1245,7373,0,6766425],
  ["Zeusstatue 137",11736,462805,1255,7631,0,6942465],
  ["Zeusstatue 138",12029,474834,1270,7886,0,7122900],
  ["Zeusstatue 139",12330,487164,1280,8149,0,7307850],
  ["Zeusstatue 140",12638,499802,1290,8428,0,7497420],
  ["Zeusstatue 141",12954,512756,1300,8716,0,7691730],
  ["Zeusstatue 142",13278,526034,1310,9002,0,7890900],
  ["Zeusstatue 143",13610,539644,1325,9286,0,8095050],
  ["Zeusstatue 144",13950,553594,1335,9588,0,8304300],
  ["Zeusstatue 145",14299,567893,1345,9908,0,8518785],
  ["Zeusstatue 146",14657,582550,1355,10238,0,8738640],
  ["Zeusstatue 147",15023,597573,1370,10548,0,8963985],
  ["Zeusstatue 148",15398,612971,1380,10895,0,9194955],
  ["Zeusstatue 149",15783,628754,1390,11251,0,9431700],
  ["Zeusstatue 150",16178,644932,1400,11608,0,9674370],
  ["Zeusstatue 151",16582,661514,1415,11964,0,9923100],
  ["Zeusstatue 152",16997,678511,1425,12341,0,10178055],
  ["Zeusstatue 153",17422,695933,1435,12738,0,10439385],
  ["Zeusstatue 154",17857,713790,1445,13144,0,10707240],
  ["Zeusstatue 155",18304,732094,1460,13544,0,10981800]
];
